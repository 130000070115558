// core
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";

// store
import { getReserves } from "../../store/modules/blocks/services";
import { selectReserves } from "../../store/modules/blocks/selectors";

// utils

// components
import ReservesMain from "./ReservesMain";
import ReservesEmission from "./ReservesEmission";
import ReservesFunds from "./ReservesFunds";
import ReservesOwnership from "./ReservesOwnership";
import PageLoader from "../../components/atoms/PageLoader";

// styles
import "./index.scss";

import AOS from "aos";
import ReservesAudit from "./ReservesAudit";

const Reserves = () => {
  // store
  const dispatch = useDispatch();
  const reserves = useSelector(selectReserves);
  useEffect(() => {
    dispatch(getReserves());
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (reserves.isSuccess) {
      AOS.init({
        offset: 200,
        duration: 1000,
        easing: "ease-out-cubic",
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reserves.isSuccess]);

  // @ts-ignore
  return (
    <div className="reserves">
      {reserves.isSuccess ? (
        <>
          <ReservesMain data={reserves.data.confirm} />
          <ReservesEmission data={reserves.data.emission} />
          <ReservesFunds data={reserves.data.reserve} />
          <ReservesAudit data={reserves.data.audit} />
          <ReservesOwnership data={reserves.data.ownership} />
        </>
      ) : (
        <PageLoader active={true} />
      )}
    </div>
  );
};

export default Reserves;
