// core
import React from "react";
// store

// components

// styles
import "./index.scss";

const WeldReferral = ({ data, referrals }) => {
  // store

  console.log("referrals", referrals);

  return (
    <div className="weld-referral">
      <div className="app__wrapper">
        <div className="weld-referral__head">
          <div className="weld__title">{data.title}</div>
          <div
            className="weld-referral__text"
            dangerouslySetInnerHTML={{
              __html: data.description,
            }}
          />
        </div>

        <div className="weld-referral__grid">
          {referrals.map((item, index) => (
            <div className="weld-referral__card">
              <div className="weld-referral__card-imgs">
                {item.icons.map((imgItem) => (
                  <img src={imgItem.image} />
                ))}
              </div>
              <div className="weld-referral__card-title">{item.title}</div>
              <div className="weld-referral__card-num">0{index + 1}</div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default WeldReferral;
