// core
import React from "react";
import { useWindowSize } from "react-use";

// store

// components
import RateImg from "./../../../assets/images/weld-rate.png";
import WeldRateArrow from "../../../components/icons/WeldRateArrow";
import WeldRateArrowMob from "../../../components/icons/WeldRateArrowMob";

// styles
import "./index.scss";

const WeldRate = ({ data }) => {
  // store
  const { width } = useWindowSize();

  return (
    <div className="weld-rate">
      <div className="app__wrapper">
        <div className="weld__title">{data.title}</div>
        <div className="weld-rate__grid">
          <div className="weld-rate__info">
            <div className="weld-rate__info-num">2%</div>
            <img src={RateImg} alt="rate" />
            <div className="weld-rate__info-text">вигоди</div>
          </div>
          <div className="weld-rate__list">
            {[1, 2, 3].map((item, index) => (
              <div
                className="weld-rate__card"
                style={{ marginLeft: (width > 1024 ? 94 : 63) * index + "px" }}
              >
                <div className="weld-rate__card-head">
                  <div className="weld-rate__card-img">
                    <img src={data[`step_${index + 1}_image`]} />
                  </div>
                  <span>{data[`step_${index + 1}_title`]}</span>
                  <div className="weld-rate__card-num">0{index + 1}</div>
                </div>
                <div className="weld-rate__card-text">
                  {data[`step_${index + 1}_description`]}
                </div>

                {index + 1 !== 3 && (
                  <>
                    {width > 1024 ? (
                      <WeldRateArrow className="weld-rate__card-arrow" />
                    ) : (
                      <WeldRateArrowMob className="weld-rate__card-arrow" />
                    )}
                  </>
                )}
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default WeldRate;
