import { createSelector } from "@reduxjs/toolkit";

import { initialState } from "./state";

const selectDomain = (state) => state.ambassador || initialState;

export const selectAmbassador = createSelector(
  [selectDomain],
  (ambassadorState) => ambassadorState.ambassador
);

export const selectAmbassadorForm = createSelector(
  [selectDomain],
  (ambassadorState) => ambassadorState.ambassadorForm
);
