import { $apiClient } from "../../../utils/api-client";
import { createAsyncThunk } from "@reduxjs/toolkit";

export const getNews = createAsyncThunk(`news/getNews`, async (params) => {
  let { per_page, page } = params;
  const url = `news?per_page=${per_page}&page=${page}`;
  return await $apiClient.get(url);
});

export const getArticle = createAsyncThunk(`news/getArticle`, async (slug) => {
  const url = `news/${slug}?include=related`;
  return await $apiClient.get(url);
});
