// core

// utils

// components

// styles
import "./index.scss";

const HomeUsages = ({ generalData }) => {
  return (
    <div className="home-usages">
      <div className="app__wrapper">
        <div className="home-usages__list">
          {generalData.map((item) => (
            <div key={item.id} className="home-usages__grid">
              <div className="home-usages__img">
                <img src={item.image} alt={item.title} />
              </div>
              <div className="home-usages__content">
                <div className="home-usages__title">{item.title}</div>
                <div
                  className="home-usages__text"
                  dangerouslySetInnerHTML={{
                    __html: item.description,
                  }}
                />
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default HomeUsages;
