const WeldRateArrow = (props) => (
  <svg
    width="59"
    height="69"
    viewBox="0 0 59 69"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M58.3536 65.3536C58.5488 65.1583 58.5488 64.8417 58.3536 64.6464L55.1716 61.4645C54.9763 61.2692 54.6597 61.2692 54.4645 61.4645C54.2692 61.6597 54.2692 61.9763 54.4645 62.1716L57.2929 65L54.4645 67.8284C54.2692 68.0237 54.2692 68.3403 54.4645 68.5355C54.6597 68.7308 54.9763 68.7308 55.1716 68.5355L58.3536 65.3536ZM0.500004 -1.18645e-06L0.500001 57L1.5 57L1.5 -1.14274e-06L0.500004 -1.18645e-06ZM9 65.5L58 65.5L58 64.5L9 64.5L9 65.5ZM0.500001 57C0.500001 61.6944 4.30558 65.5 9 65.5L9 64.5C4.85787 64.5 1.5 61.1421 1.5 57L0.500001 57Z"
      fill="#E0E1E3"
    />
  </svg>
);

export default WeldRateArrow;
