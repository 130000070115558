import React from "react";

const BannerBg = () => (
  <svg
    width="1421"
    height="1124"
    viewBox="0 0 1421 1124"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g opacity="0.2">
      <path
        d="M708.093 358.286L669.105 373.252C656.72 378.007 650.533 391.902 655.288 404.288L670.254 443.275C675.008 455.661 688.903 461.848 701.289 457.093L740.277 442.127C752.663 437.373 758.849 423.478 754.094 411.092L739.128 372.104C734.374 359.718 720.479 353.532 708.093 358.286Z"
        stroke="url(#paint0_linear_1219_6678)"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M708.093 358.286L669.105 373.252C656.72 378.007 650.533 391.902 655.288 404.288L670.254 443.275C675.008 455.661 688.903 461.848 701.289 457.093L740.277 442.127C752.663 437.373 758.849 423.478 754.094 411.092L739.128 372.104C734.374 359.718 720.479 353.532 708.093 358.286Z"
        stroke="url(#paint1_radial_1219_6678)"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M708.093 358.286L669.105 373.252C656.72 378.007 650.533 391.902 655.288 404.288L670.254 443.275C675.008 455.661 688.903 461.848 701.289 457.093L740.277 442.127C752.663 437.373 758.849 423.478 754.094 411.092L739.128 372.104C734.374 359.718 720.479 353.532 708.093 358.286Z"
        stroke="url(#paint2_radial_1219_6678)"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M708.093 358.286L669.105 373.252C656.72 378.007 650.533 391.902 655.288 404.288L670.254 443.275C675.008 455.661 688.903 461.848 701.289 457.093L740.277 442.127C752.663 437.373 758.849 423.478 754.094 411.092L739.128 372.104C734.374 359.718 720.479 353.532 708.093 358.286Z"
        stroke="url(#paint3_linear_1219_6678)"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M711.195 354.261L668.023 368.288C654.309 372.744 646.804 387.475 651.26 401.189L665.287 444.36C669.743 458.075 684.473 465.58 698.188 461.124L741.359 447.097C755.073 442.641 762.579 427.911 758.123 414.196L744.095 371.025C739.639 357.31 724.909 349.805 711.195 354.261Z"
        stroke="url(#paint4_linear_1219_6678)"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M711.195 354.261L668.023 368.288C654.309 372.744 646.804 387.475 651.26 401.189L665.287 444.36C669.743 458.075 684.473 465.58 698.188 461.124L741.359 447.097C755.073 442.641 762.579 427.911 758.123 414.196L744.095 371.025C739.639 357.31 724.909 349.805 711.195 354.261Z"
        stroke="url(#paint5_radial_1219_6678)"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M711.195 354.261L668.023 368.288C654.309 372.744 646.804 387.475 651.26 401.189L665.287 444.36C669.743 458.075 684.473 465.58 698.188 461.124L741.359 447.097C755.073 442.641 762.579 427.911 758.123 414.196L744.095 371.025C739.639 357.31 724.909 349.805 711.195 354.261Z"
        stroke="url(#paint6_radial_1219_6678)"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M711.195 354.261L668.023 368.288C654.309 372.744 646.804 387.475 651.26 401.189L665.287 444.36C669.743 458.075 684.473 465.58 698.188 461.124L741.359 447.097C755.073 442.641 762.579 427.911 758.123 414.196L744.095 371.025C739.639 357.31 724.909 349.805 711.195 354.261Z"
        stroke="url(#paint7_linear_1219_6678)"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M714.785 350.063L667.132 362.832C651.99 366.889 643.004 382.453 647.062 397.595L659.83 445.247C663.887 460.389 679.452 469.375 694.594 465.318L742.246 452.55C757.388 448.492 766.374 432.928 762.317 417.786L749.548 370.134C745.491 354.992 729.927 346.006 714.785 350.063Z"
        stroke="url(#paint8_linear_1219_6678)"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M714.785 350.063L667.132 362.832C651.99 366.889 643.004 382.453 647.062 397.595L659.83 445.247C663.887 460.389 679.452 469.375 694.594 465.318L742.246 452.55C757.388 448.492 766.374 432.928 762.317 417.786L749.548 370.134C745.491 354.992 729.927 346.006 714.785 350.063Z"
        stroke="url(#paint9_radial_1219_6678)"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M714.785 350.063L667.132 362.832C651.99 366.889 643.004 382.453 647.062 397.595L659.83 445.247C663.887 460.389 679.452 469.375 694.594 465.318L742.246 452.55C757.388 448.492 766.374 432.928 762.317 417.786L749.548 370.134C745.491 354.992 729.927 346.006 714.785 350.063Z"
        stroke="url(#paint10_radial_1219_6678)"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M714.785 350.063L667.132 362.832C651.99 366.889 643.004 382.453 647.062 397.595L659.83 445.247C663.887 460.389 679.452 469.375 694.594 465.318L742.246 452.55C757.388 448.492 766.374 432.928 762.317 417.786L749.548 370.134C745.491 354.992 729.927 346.006 714.785 350.063Z"
        stroke="url(#paint11_linear_1219_6678)"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M642.713 393.449L653.863 445.909C657.406 462.574 673.787 473.212 690.452 469.67L742.912 458.519C759.577 454.977 770.216 438.596 766.673 421.93L755.523 369.47C751.98 352.805 735.599 342.167 718.934 345.709L666.474 356.86C649.809 360.402 639.17 376.784 642.713 393.449Z"
        stroke="url(#paint12_linear_1219_6678)"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M642.713 393.449L653.863 445.909C657.406 462.574 673.787 473.212 690.452 469.67L742.912 458.519C759.577 454.977 770.216 438.596 766.673 421.93L755.523 369.47C751.98 352.805 735.599 342.167 718.934 345.709L666.474 356.86C649.809 360.402 639.17 376.784 642.713 393.449Z"
        stroke="url(#paint13_radial_1219_6678)"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M642.713 393.449L653.863 445.909C657.406 462.574 673.787 473.212 690.452 469.67L742.912 458.519C759.577 454.977 770.216 438.596 766.673 421.93L755.523 369.47C751.98 352.805 735.599 342.167 718.934 345.709L666.474 356.86C649.809 360.402 639.17 376.784 642.713 393.449Z"
        stroke="url(#paint14_radial_1219_6678)"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M642.713 393.449L653.863 445.909C657.406 462.574 673.787 473.212 690.452 469.67L742.912 458.519C759.577 454.977 770.216 438.596 766.673 421.93L755.523 369.47C751.98 352.805 735.599 342.167 718.934 345.709L666.474 356.86C649.809 360.402 639.17 376.784 642.713 393.449Z"
        stroke="url(#paint15_linear_1219_6678)"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M638.226 388.712L647.345 446.288C650.242 464.579 667.418 477.058 685.709 474.161L743.286 465.042C761.577 462.145 774.056 444.969 771.159 426.678L762.04 369.102C759.143 350.81 741.966 338.331 723.675 341.228L666.099 350.347C647.808 353.244 635.329 370.421 638.226 388.712Z"
        stroke="url(#paint16_linear_1219_6678)"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M638.226 388.712L647.345 446.288C650.242 464.579 667.418 477.058 685.709 474.161L743.286 465.042C761.577 462.145 774.056 444.969 771.159 426.678L762.04 369.102C759.143 350.81 741.966 338.331 723.675 341.228L666.099 350.347C647.808 353.244 635.329 370.421 638.226 388.712Z"
        stroke="url(#paint17_radial_1219_6678)"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M638.226 388.712L647.345 446.288C650.242 464.579 667.418 477.058 685.709 474.161L743.286 465.042C761.577 462.145 774.056 444.969 771.159 426.678L762.04 369.102C759.143 350.81 741.966 338.331 723.675 341.228L666.099 350.347C647.808 353.244 635.329 370.421 638.226 388.712Z"
        stroke="url(#paint18_radial_1219_6678)"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M638.226 388.712L647.345 446.288C650.242 464.579 667.418 477.058 685.709 474.161L743.286 465.042C761.577 462.145 774.056 444.969 771.159 426.678L762.04 369.102C759.143 350.81 741.966 338.331 723.675 341.228L666.099 350.347C647.808 353.244 635.329 370.421 638.226 388.712Z"
        stroke="url(#paint19_linear_1219_6678)"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M633.624 383.309L640.246 446.317C642.351 466.338 660.287 480.862 680.307 478.758L743.315 472.136C763.336 470.031 777.86 452.095 775.756 432.074L769.134 369.066C767.03 349.046 749.094 334.521 729.073 336.626L666.065 343.248C646.044 345.352 631.52 363.288 633.624 383.309Z"
        stroke="url(#paint20_linear_1219_6678)"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M633.624 383.309L640.246 446.317C642.351 466.338 660.287 480.862 680.307 478.758L743.315 472.136C763.336 470.031 777.86 452.095 775.756 432.074L769.134 369.066C767.03 349.046 749.094 334.521 729.073 336.626L666.065 343.248C646.044 345.352 631.52 363.288 633.624 383.309Z"
        stroke="url(#paint21_radial_1219_6678)"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M633.624 383.309L640.246 446.317C642.351 466.338 660.287 480.862 680.307 478.758L743.315 472.136C763.336 470.031 777.86 452.095 775.756 432.074L769.134 369.066C767.03 349.046 749.094 334.521 729.073 336.626L666.065 343.248C646.044 345.352 631.52 363.288 633.624 383.309Z"
        stroke="url(#paint22_radial_1219_6678)"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M633.624 383.309L640.246 446.317C642.351 466.338 660.287 480.862 680.307 478.758L743.315 472.136C763.336 470.031 777.86 452.095 775.756 432.074L769.134 369.066C767.03 349.046 749.094 334.521 729.073 336.626L666.065 343.248C646.044 345.352 631.52 363.288 633.624 383.309Z"
        stroke="url(#paint23_linear_1219_6678)"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M628.939 377.18L632.543 445.951C633.688 467.802 652.33 484.587 674.181 483.442L742.953 479.838C764.804 478.693 781.589 460.051 780.444 438.2L776.84 369.428C775.695 347.577 757.053 330.792 735.202 331.937L666.43 335.541C644.579 336.686 627.794 355.329 628.939 377.18Z"
        stroke="url(#paint24_linear_1219_6678)"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M628.939 377.18L632.543 445.951C633.688 467.802 652.33 484.587 674.181 483.442L742.953 479.838C764.804 478.693 781.589 460.051 780.444 438.2L776.84 369.428C775.695 347.577 757.053 330.792 735.202 331.937L666.43 335.541C644.579 336.686 627.794 355.329 628.939 377.18Z"
        stroke="url(#paint25_radial_1219_6678)"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M628.939 377.18L632.543 445.951C633.688 467.802 652.33 484.587 674.181 483.442L742.953 479.838C764.804 478.693 781.589 460.051 780.444 438.2L776.84 369.428C775.695 347.577 757.053 330.792 735.202 331.937L666.43 335.541C644.579 336.686 627.794 355.329 628.939 377.18Z"
        stroke="url(#paint26_radial_1219_6678)"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M628.939 377.18L632.543 445.951C633.688 467.802 652.33 484.587 674.181 483.442L742.953 479.838C764.804 478.693 781.589 460.051 780.444 438.2L776.84 369.428C775.695 347.577 757.053 330.792 735.202 331.937L666.43 335.541C644.579 336.686 627.794 355.329 628.939 377.18Z"
        stroke="url(#paint27_linear_1219_6678)"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M785.181 370.262L785.182 445.119C785.182 450.775 784.068 456.375 781.904 461.6C779.74 466.825 776.568 471.572 772.569 475.571C768.57 479.57 763.823 482.742 758.598 484.906C753.373 487.069 747.773 488.183 742.118 488.182L667.261 488.183C655.839 488.183 644.886 483.645 636.81 475.569C628.735 467.493 624.198 456.54 624.198 445.119L624.197 370.262C624.197 358.841 628.735 347.888 636.811 339.812C644.887 331.736 655.84 327.199 667.261 327.199L742.118 327.198C747.773 327.198 753.374 328.312 758.598 330.476C763.823 332.64 768.571 335.812 772.57 339.811C776.568 343.81 779.74 348.557 781.904 353.782C784.068 359.007 785.182 364.607 785.181 370.262Z"
        stroke="url(#paint28_linear_1219_6678)"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M785.181 370.262L785.182 445.119C785.182 450.775 784.068 456.375 781.904 461.6C779.74 466.825 776.568 471.572 772.569 475.571C768.57 479.57 763.823 482.742 758.598 484.906C753.373 487.069 747.773 488.183 742.118 488.182L667.261 488.183C655.839 488.183 644.886 483.645 636.81 475.569C628.735 467.493 624.198 456.54 624.198 445.119L624.197 370.262C624.197 358.841 628.735 347.888 636.811 339.812C644.887 331.736 655.84 327.199 667.261 327.199L742.118 327.198C747.773 327.198 753.374 328.312 758.598 330.476C763.823 332.64 768.571 335.812 772.57 339.811C776.568 343.81 779.74 348.557 781.904 353.782C784.068 359.007 785.182 364.607 785.181 370.262Z"
        stroke="url(#paint29_radial_1219_6678)"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M785.181 370.262L785.182 445.119C785.182 450.775 784.068 456.375 781.904 461.6C779.74 466.825 776.568 471.572 772.569 475.571C768.57 479.57 763.823 482.742 758.598 484.906C753.373 487.069 747.773 488.183 742.118 488.182L667.261 488.183C655.839 488.183 644.886 483.645 636.81 475.569C628.735 467.493 624.198 456.54 624.198 445.119L624.197 370.262C624.197 358.841 628.735 347.888 636.811 339.812C644.887 331.736 655.84 327.199 667.261 327.199L742.118 327.198C747.773 327.198 753.374 328.312 758.598 330.476C763.823 332.64 768.571 335.812 772.57 339.811C776.568 343.81 779.74 348.557 781.904 353.782C784.068 359.007 785.182 364.607 785.181 370.262Z"
        stroke="url(#paint30_radial_1219_6678)"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M785.181 370.262L785.182 445.119C785.182 450.775 784.068 456.375 781.904 461.6C779.74 466.825 776.568 471.572 772.569 475.571C768.57 479.57 763.823 482.742 758.598 484.906C753.373 487.069 747.773 488.183 742.118 488.182L667.261 488.183C655.839 488.183 644.886 483.645 636.81 475.569C628.735 467.493 624.198 456.54 624.198 445.119L624.197 370.262C624.197 358.841 628.735 347.888 636.811 339.812C644.887 331.736 655.84 327.199 667.261 327.199L742.118 327.198C747.773 327.198 753.374 328.312 758.598 330.476C763.823 332.64 768.571 335.812 772.57 339.811C776.568 343.81 779.74 348.557 781.904 353.782C784.068 359.007 785.182 364.607 785.181 370.262Z"
        stroke="url(#paint31_linear_1219_6678)"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M619.448 362.486L615.19 443.737C613.837 469.554 633.669 491.579 659.485 492.932L740.736 497.191C766.553 498.544 788.578 478.712 789.931 452.895L794.19 371.644C795.543 345.828 775.711 323.802 749.894 322.449L668.643 318.191C642.826 316.838 620.801 336.67 619.448 362.486Z"
        stroke="url(#paint32_linear_1219_6678)"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M619.448 362.486L615.19 443.737C613.837 469.554 633.669 491.579 659.485 492.932L740.736 497.191C766.553 498.544 788.578 478.712 789.931 452.895L794.19 371.644C795.543 345.828 775.711 323.802 749.894 322.449L668.643 318.191C642.826 316.838 620.801 336.67 619.448 362.486Z"
        stroke="url(#paint33_radial_1219_6678)"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M619.448 362.486L615.19 443.737C613.837 469.554 633.669 491.579 659.485 492.932L740.736 497.191C766.553 498.544 788.578 478.712 789.931 452.895L794.19 371.644C795.543 345.828 775.711 323.802 749.894 322.449L668.643 318.191C642.826 316.838 620.801 336.67 619.448 362.486Z"
        stroke="url(#paint34_radial_1219_6678)"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M619.448 362.486L615.19 443.737C613.837 469.554 633.669 491.579 659.485 492.932L740.736 497.191C766.553 498.544 788.578 478.712 789.931 452.895L794.19 371.644C795.543 345.828 775.711 323.802 749.894 322.449L668.643 318.191C642.826 316.838 620.801 336.67 619.448 362.486Z"
        stroke="url(#paint35_linear_1219_6678)"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M614.893 356.294L605.611 442.274C602.662 469.595 622.858 494.181 650.719 497.189L738.401 506.654C766.263 509.662 791.24 489.952 794.189 462.631L803.471 376.651C806.42 349.33 786.225 324.744 758.363 321.736L670.681 312.271C642.82 309.263 617.842 328.973 614.893 356.294Z"
        stroke="url(#paint36_linear_1219_6678)"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M614.893 356.294L605.611 442.274C602.662 469.595 622.858 494.181 650.719 497.189L738.401 506.654C766.263 509.662 791.24 489.952 794.189 462.631L803.471 376.651C806.42 349.33 786.225 324.744 758.363 321.736L670.681 312.271C642.82 309.263 617.842 328.973 614.893 356.294Z"
        stroke="url(#paint37_radial_1219_6678)"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M614.893 356.294L605.611 442.274C602.662 469.595 622.858 494.181 650.719 497.189L738.401 506.654C766.263 509.662 791.24 489.952 794.189 462.631L803.471 376.651C806.42 349.33 786.225 324.744 758.363 321.736L670.681 312.271C642.82 309.263 617.842 328.973 614.893 356.294Z"
        stroke="url(#paint38_radial_1219_6678)"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M614.893 356.294L605.611 442.274C602.662 469.595 622.858 494.181 650.719 497.189L738.401 506.654C766.263 509.662 791.24 489.952 794.189 462.631L803.471 376.651C806.42 349.33 786.225 324.744 758.363 321.736L670.681 312.271C642.82 309.263 617.842 328.973 614.893 356.294Z"
        stroke="url(#paint39_linear_1219_6678)"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M609.949 345.075L595.237 437.967C590.562 467.48 611.228 495.278 641.394 500.056L736.344 515.094C766.511 519.872 794.755 499.821 799.43 470.308L814.142 377.416C818.817 347.904 798.151 320.106 767.984 315.328L673.034 300.289C642.868 295.511 614.624 315.563 609.949 345.075Z"
        stroke="url(#paint40_linear_1219_6678)"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M609.949 345.075L595.237 437.967C590.562 467.48 611.228 495.278 641.394 500.056L736.344 515.094C766.511 519.872 794.755 499.821 799.43 470.308L814.142 377.416C818.817 347.904 798.151 320.106 767.984 315.328L673.034 300.289C642.868 295.511 614.624 315.563 609.949 345.075Z"
        stroke="url(#paint41_radial_1219_6678)"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M609.949 345.075L595.237 437.967C590.562 467.48 611.228 495.278 641.394 500.056L736.344 515.094C766.511 519.872 794.755 499.821 799.43 470.308L814.142 377.416C818.817 347.904 798.151 320.106 767.984 315.328L673.034 300.289C642.868 295.511 614.624 315.563 609.949 345.075Z"
        stroke="url(#paint42_radial_1219_6678)"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M609.949 345.075L595.237 437.967C590.562 467.48 611.228 495.278 641.394 500.056L736.344 515.094C766.511 519.872 794.755 499.821 799.43 470.308L814.142 377.416C818.817 347.904 798.151 320.106 767.984 315.328L673.034 300.289C642.868 295.511 614.624 315.563 609.949 345.075Z"
        stroke="url(#paint43_linear_1219_6678)"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M605.424 334.299L584.154 434.365C577.396 466.161 598.243 497.532 630.717 504.435L732.92 526.158C765.395 533.061 797.199 512.881 803.957 481.086L825.227 381.019C831.986 349.224 811.139 317.852 778.664 310.95L676.461 289.226C643.987 282.323 612.182 302.503 605.424 334.299Z"
        stroke="url(#paint44_linear_1219_6678)"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M605.424 334.299L584.154 434.365C577.396 466.161 598.243 497.532 630.717 504.435L732.92 526.158C765.395 533.061 797.199 512.881 803.957 481.086L825.227 381.019C831.986 349.224 811.139 317.852 778.664 310.95L676.461 289.226C643.987 282.323 612.182 302.503 605.424 334.299Z"
        stroke="url(#paint45_radial_1219_6678)"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M605.424 334.299L584.154 434.365C577.396 466.161 598.243 497.532 630.717 504.435L732.92 526.158C765.395 533.061 797.199 512.881 803.957 481.086L825.227 381.019C831.986 349.224 811.139 317.852 778.664 310.95L676.461 289.226C643.987 282.323 612.182 302.503 605.424 334.299Z"
        stroke="url(#paint46_radial_1219_6678)"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M605.424 334.299L584.154 434.365C577.396 466.161 598.243 497.532 630.717 504.435L732.92 526.158C765.395 533.061 797.199 512.881 803.957 481.086L825.227 381.019C831.986 349.224 811.139 317.852 778.664 310.95L676.461 289.226C643.987 282.323 612.182 302.503 605.424 334.299Z"
        stroke="url(#paint47_linear_1219_6678)"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M601.424 321.226L572.028 430.934C562.688 465.79 583.373 501.618 618.229 510.957L727.937 540.353C762.793 549.693 798.621 529.008 807.96 494.152L837.357 384.444C846.696 349.588 826.011 313.76 791.155 304.421L681.447 275.024C646.591 265.685 610.763 286.37 601.424 321.226Z"
        stroke="url(#paint48_linear_1219_6678)"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M601.424 321.226L572.028 430.934C562.688 465.79 583.373 501.618 618.229 510.957L727.937 540.353C762.793 549.693 798.621 529.008 807.96 494.152L837.357 384.444C846.696 349.588 826.011 313.76 791.155 304.421L681.447 275.024C646.591 265.685 610.763 286.37 601.424 321.226Z"
        stroke="url(#paint49_radial_1219_6678)"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M601.424 321.226L572.028 430.934C562.688 465.79 583.373 501.618 618.229 510.957L727.937 540.353C762.793 549.693 798.621 529.008 807.96 494.152L837.357 384.444C846.696 349.588 826.011 313.76 791.155 304.421L681.447 275.024C646.591 265.685 610.763 286.37 601.424 321.226Z"
        stroke="url(#paint50_radial_1219_6678)"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M601.424 321.226L572.028 430.934C562.688 465.79 583.373 501.618 618.229 510.957L727.937 540.353C762.793 549.693 798.621 529.008 807.96 494.152L837.357 384.444C846.696 349.588 826.011 313.76 791.155 304.421L681.447 275.024C646.591 265.685 610.763 286.37 601.424 321.226Z"
        stroke="url(#paint51_linear_1219_6678)"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M597.514 307.965L559.365 425.374C547.244 462.679 567.66 502.746 604.964 514.867L722.373 553.016C759.678 565.136 799.745 544.721 811.866 507.417L850.014 390.008C862.135 352.703 841.72 312.636 804.416 300.515L687.007 262.366C649.702 250.245 609.635 270.661 597.514 307.965Z"
        stroke="url(#paint52_linear_1219_6678)"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M597.514 307.965L559.365 425.374C547.244 462.679 567.66 502.746 604.964 514.867L722.373 553.016C759.678 565.136 799.745 544.721 811.866 507.417L850.014 390.008C862.135 352.703 841.72 312.636 804.416 300.515L687.007 262.366C649.702 250.245 609.635 270.661 597.514 307.965Z"
        stroke="url(#paint53_radial_1219_6678)"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M597.514 307.965L559.365 425.374C547.244 462.679 567.66 502.746 604.964 514.867L722.373 553.016C759.678 565.136 799.745 544.721 811.866 507.417L850.014 390.008C862.135 352.703 841.72 312.636 804.416 300.515L687.007 262.366C649.702 250.245 609.635 270.661 597.514 307.965Z"
        stroke="url(#paint54_radial_1219_6678)"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M597.514 307.965L559.365 425.374C547.244 462.679 567.66 502.746 604.964 514.867L722.373 553.016C759.678 565.136 799.745 544.721 811.866 507.417L850.014 390.008C862.135 352.703 841.72 312.636 804.416 300.515L687.007 262.366C649.702 250.245 609.635 270.661 597.514 307.965Z"
        stroke="url(#paint55_linear_1219_6678)"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M594.026 293.345L545.936 418.623C530.658 458.425 550.538 503.077 590.34 518.356L715.618 566.445C755.42 581.724 800.072 561.844 815.351 522.042L863.44 396.763C878.719 356.961 858.839 312.309 819.037 297.031L693.759 248.941C653.956 233.663 609.305 253.543 594.026 293.345Z"
        stroke="url(#paint56_linear_1219_6678)"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M594.026 293.345L545.936 418.623C530.658 458.425 550.538 503.077 590.34 518.356L715.618 566.445C755.42 581.724 800.072 561.844 815.351 522.042L863.44 396.763C878.719 356.961 858.839 312.309 819.037 297.031L693.759 248.941C653.956 233.663 609.305 253.543 594.026 293.345Z"
        stroke="url(#paint57_radial_1219_6678)"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M594.026 293.345L545.936 418.623C530.658 458.425 550.538 503.077 590.34 518.356L715.618 566.445C755.42 581.724 800.072 561.844 815.351 522.042L863.44 396.763C878.719 356.961 858.839 312.309 819.037 297.031L693.759 248.941C653.956 233.663 609.305 253.543 594.026 293.345Z"
        stroke="url(#paint58_radial_1219_6678)"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M594.026 293.345L545.936 418.623C530.658 458.425 550.538 503.077 590.34 518.356L715.618 566.445C755.42 581.724 800.072 561.844 815.351 522.042L863.44 396.763C878.719 356.961 858.839 312.309 819.037 297.031L693.759 248.941C653.956 233.663 609.305 253.543 594.026 293.345Z"
        stroke="url(#paint59_linear_1219_6678)"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M877.631 404.858L818.308 538.108C799.458 580.444 749.858 599.482 707.517 580.633L574.272 521.309C531.935 502.459 512.898 452.859 531.742 410.52L591.071 277.273C609.916 234.938 659.521 215.899 701.86 234.743L835.111 294.071C877.443 312.922 896.482 362.527 877.631 404.858Z"
        stroke="url(#paint60_linear_1219_6678)"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M877.631 404.858L818.308 538.108C799.458 580.444 749.858 599.482 707.517 580.633L574.272 521.309C531.935 502.459 512.898 452.859 531.742 410.52L591.071 277.273C609.916 234.938 659.521 215.899 701.86 234.743L835.111 294.071C877.443 312.922 896.482 362.527 877.631 404.858Z"
        stroke="url(#paint61_radial_1219_6678)"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M877.631 404.858L818.308 538.108C799.458 580.444 749.858 599.482 707.517 580.633L574.272 521.309C531.935 502.459 512.898 452.859 531.742 410.52L591.071 277.273C609.916 234.938 659.521 215.899 701.86 234.743L835.111 294.071C877.443 312.922 896.482 362.527 877.631 404.858Z"
        stroke="url(#paint62_radial_1219_6678)"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M877.631 404.858L818.308 538.108C799.458 580.444 749.858 599.482 707.517 580.633L574.272 521.309C531.935 502.459 512.898 452.859 531.742 410.52L591.071 277.273C609.916 234.938 659.521 215.899 701.86 234.743L835.111 294.071C877.443 312.922 896.482 362.527 877.631 404.858Z"
        stroke="url(#paint63_linear_1219_6678)"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M892.576 414.454L820.6 555.714C797.729 600.598 742.809 618.444 697.926 595.577L556.666 523.602C511.786 500.729 493.94 445.809 516.807 400.926L588.783 259.666C611.651 214.787 666.575 196.941 711.454 219.809L852.714 291.784C897.597 314.651 915.431 369.579 892.576 414.454Z"
        stroke="url(#paint64_linear_1219_6678)"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M892.576 414.454L820.6 555.714C797.729 600.598 742.809 618.444 697.926 595.577L556.666 523.602C511.786 500.729 493.94 445.809 516.807 400.926L588.783 259.666C611.651 214.787 666.575 196.941 711.454 219.809L852.714 291.784C897.597 314.651 915.431 369.579 892.576 414.454Z"
        stroke="url(#paint65_radial_1219_6678)"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M892.576 414.454L820.6 555.714C797.729 600.598 742.809 618.444 697.926 595.577L556.666 523.602C511.786 500.729 493.94 445.809 516.807 400.926L588.783 259.666C611.651 214.787 666.575 196.941 711.454 219.809L852.714 291.784C897.597 314.651 915.431 369.579 892.576 414.454Z"
        stroke="url(#paint66_radial_1219_6678)"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M892.576 414.454L820.6 555.714C797.729 600.598 742.809 618.444 697.926 595.577L556.666 523.602C511.786 500.729 493.94 445.809 516.807 400.926L588.783 259.666C611.651 214.787 666.575 196.941 711.454 219.809L852.714 291.784C897.597 314.651 915.431 369.579 892.576 414.454Z"
        stroke="url(#paint67_linear_1219_6678)"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M587.296 240.424L501.135 389.658C473.759 437.076 490.005 497.708 537.422 525.084L686.657 611.245C734.074 638.621 794.706 622.375 822.082 574.958L908.243 425.723C935.619 378.306 919.373 317.674 871.956 290.297L722.721 204.137C675.304 176.76 614.672 193.007 587.296 240.424Z"
        stroke="url(#paint68_linear_1219_6678)"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M587.296 240.424L501.135 389.658C473.759 437.076 490.005 497.708 537.422 525.084L686.657 611.245C734.074 638.621 794.706 622.375 822.082 574.958L908.243 425.723C935.619 378.306 919.373 317.674 871.956 290.297L722.721 204.137C675.304 176.76 614.672 193.007 587.296 240.424Z"
        stroke="url(#paint69_radial_1219_6678)"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M587.296 240.424L501.135 389.658C473.759 437.076 490.005 497.708 537.422 525.084L686.657 611.245C734.074 638.621 794.706 622.375 822.082 574.958L908.243 425.723C935.619 378.306 919.373 317.674 871.956 290.297L722.721 204.137C675.304 176.76 614.672 193.007 587.296 240.424Z"
        stroke="url(#paint70_radial_1219_6678)"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M587.296 240.424L501.135 389.658C473.759 437.076 490.005 497.708 537.422 525.084L686.657 611.245C734.074 638.621 794.706 622.375 822.082 574.958L908.243 425.723C935.619 378.306 919.373 317.674 871.956 290.297L722.721 204.137C675.304 176.76 614.672 193.007 587.296 240.424Z"
        stroke="url(#paint71_linear_1219_6678)"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M924.62 438.844L822.604 595.935C790.191 645.848 723.453 660.032 673.54 627.62L516.432 525.609C466.519 493.196 452.335 426.458 484.747 376.545L586.776 219.432C619.188 169.519 685.926 155.335 735.839 187.747L892.93 289.763C942.848 322.193 957.032 388.931 924.62 438.844Z"
        stroke="url(#paint72_linear_1219_6678)"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M924.62 438.844L822.604 595.935C790.191 645.848 723.453 660.032 673.54 627.62L516.432 525.609C466.519 493.196 452.335 426.458 484.747 376.545L586.776 219.432C619.188 169.519 685.926 155.335 735.839 187.747L892.93 289.763C942.848 322.193 957.032 388.931 924.62 438.844Z"
        stroke="url(#paint73_radial_1219_6678)"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M924.62 438.844L822.604 595.935C790.191 645.848 723.453 660.032 673.54 627.62L516.432 525.609C466.519 493.196 452.335 426.458 484.747 376.545L586.776 219.432C619.188 169.519 685.926 155.335 735.839 187.747L892.93 289.763C942.848 322.193 957.032 388.931 924.62 438.844Z"
        stroke="url(#paint74_radial_1219_6678)"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M924.62 438.844L822.604 595.935C790.191 645.848 723.453 660.032 673.54 627.62L516.432 525.609C466.519 493.196 452.335 426.458 484.747 376.545L586.776 219.432C619.188 169.519 685.926 155.335 735.839 187.747L892.93 289.763C942.848 322.193 957.032 388.931 924.62 438.844Z"
        stroke="url(#paint75_linear_1219_6678)"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M941.641 454.016L821.973 618.732C783.949 671.065 710.701 682.665 658.364 644.643L493.652 524.973C441.319 486.95 429.715 413.703 467.737 361.365L587.411 196.653C625.435 144.32 698.682 132.715 751.015 170.739L915.732 290.411C968.065 328.435 979.665 401.683 941.641 454.016Z"
        stroke="url(#paint76_linear_1219_6678)"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M941.641 454.016L821.973 618.732C783.949 671.065 710.701 682.665 658.364 644.643L493.652 524.973C441.319 486.95 429.715 413.703 467.737 361.365L587.411 196.653C625.435 144.32 698.682 132.715 751.015 170.739L915.732 290.411C968.065 328.435 979.665 401.683 941.641 454.016Z"
        stroke="url(#paint77_radial_1219_6678)"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M941.641 454.016L821.973 618.732C783.949 671.065 710.701 682.665 658.364 644.643L493.652 524.973C441.319 486.95 429.715 413.703 467.737 361.365L587.411 196.653C625.435 144.32 698.682 132.715 751.015 170.739L915.732 290.411C968.065 328.435 979.665 401.683 941.641 454.016Z"
        stroke="url(#paint78_radial_1219_6678)"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M941.641 454.016L821.973 618.732C783.949 671.065 710.701 682.665 658.364 644.643L493.652 524.973C441.319 486.95 429.715 413.703 467.737 361.365L587.411 196.653C625.435 144.32 698.682 132.715 751.015 170.739L915.732 290.411C968.065 328.435 979.665 401.683 941.641 454.016Z"
        stroke="url(#paint79_linear_1219_6678)"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M959.262 471.457L819.989 643.442C775.737 698.064 695.571 706.508 640.933 662.284L468.942 522.99C414.297 478.739 405.871 398.573 450.101 343.933L589.373 171.948C633.619 117.304 713.79 108.877 768.429 153.107L940.414 292.379C995.082 336.643 1003.51 416.813 959.262 471.457Z"
        stroke="url(#paint80_linear_1219_6678)"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M959.262 471.457L819.989 643.442C775.737 698.064 695.571 706.508 640.933 662.284L468.942 522.99C414.297 478.739 405.871 398.573 450.101 343.933L589.373 171.948C633.619 117.304 713.79 108.877 768.429 153.107L940.414 292.379C995.082 336.643 1003.51 416.813 959.262 471.457Z"
        stroke="url(#paint81_radial_1219_6678)"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M959.262 471.457L819.989 643.442C775.737 698.064 695.571 706.508 640.933 662.284L468.942 522.99C414.297 478.739 405.871 398.573 450.101 343.933L589.373 171.948C633.619 117.304 713.79 108.877 768.429 153.107L940.414 292.379C995.082 336.643 1003.51 416.813 959.262 471.457Z"
        stroke="url(#paint82_radial_1219_6678)"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M959.262 471.457L819.989 643.442C775.737 698.064 695.571 706.508 640.933 662.284L468.942 522.99C414.297 478.739 405.871 398.573 450.101 343.933L589.373 171.948C633.619 117.304 713.79 108.877 768.429 153.107L940.414 292.379C995.082 336.643 1003.51 416.813 959.262 471.457Z"
        stroke="url(#paint83_linear_1219_6678)"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M977.388 491.385L816.445 670.131C765.307 726.927 677.811 731.513 621.009 680.372L442.241 519.449C385.444 468.311 380.859 380.814 431.999 324.013L592.949 145.237C644.091 88.4399 731.583 83.8552 788.385 134.996L967.134 295.953C1023.94 347.091 1028.53 434.588 977.388 491.385Z"
        stroke="url(#paint84_linear_1219_6678)"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M977.388 491.385L816.445 670.131C765.307 726.927 677.811 731.513 621.009 680.372L442.241 519.449C385.444 468.311 380.859 380.814 431.999 324.013L592.949 145.237C644.091 88.4399 731.583 83.8552 788.385 134.996L967.134 295.953C1023.94 347.091 1028.53 434.588 977.388 491.385Z"
        stroke="url(#paint85_radial_1219_6678)"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M977.388 491.385L816.445 670.131C765.307 726.927 677.811 731.513 621.009 680.372L442.241 519.449C385.444 468.311 380.859 380.814 431.999 324.013L592.949 145.237C644.091 88.4399 731.583 83.8552 788.385 134.996L967.134 295.953C1023.94 347.091 1028.53 434.588 977.388 491.385Z"
        stroke="url(#paint86_radial_1219_6678)"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M977.388 491.385L816.445 670.131C765.307 726.927 677.811 731.513 621.009 680.372L442.241 519.449C385.444 468.311 380.859 380.814 431.999 324.013L592.949 145.237C644.091 88.4399 731.583 83.8552 788.385 134.996L967.134 295.953C1023.94 347.091 1028.53 434.588 977.388 491.385Z"
        stroke="url(#paint87_linear_1219_6678)"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M995.935 514.053L811.052 698.936C752.31 757.676 657.071 757.677 598.331 698.935L413.448 514.052C354.704 455.311 354.708 360.071 413.45 301.332L598.332 116.449C657.073 57.7044 752.313 57.7076 811.053 116.45L995.936 301.333C1054.68 360.075 1054.68 455.313 995.935 514.053Z"
        stroke="url(#paint88_linear_1219_6678)"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M995.935 514.053L811.052 698.936C752.31 757.676 657.071 757.677 598.331 698.935L413.448 514.052C354.704 455.311 354.708 360.071 413.45 301.332L598.332 116.449C657.073 57.7044 752.313 57.7076 811.053 116.45L995.936 301.333C1054.68 360.075 1054.68 455.313 995.935 514.053Z"
        stroke="url(#paint89_radial_1219_6678)"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M995.935 514.053L811.052 698.936C752.31 757.676 657.071 757.677 598.331 698.935L413.448 514.052C354.704 455.311 354.708 360.071 413.45 301.332L598.332 116.449C657.073 57.7044 752.313 57.7076 811.053 116.45L995.936 301.333C1054.68 360.075 1054.68 455.313 995.935 514.053Z"
        stroke="url(#paint90_radial_1219_6678)"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M995.935 514.053L811.052 698.936C752.31 757.676 657.071 757.677 598.331 698.935L413.448 514.052C354.704 455.311 354.708 360.071 413.45 301.332L598.332 116.449C657.073 57.7044 752.313 57.7076 811.053 116.45L995.936 301.333C1054.68 360.075 1054.68 455.313 995.935 514.053Z"
        stroke="url(#paint91_linear_1219_6678)"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M1014.77 539.711L803.576 729.878C736.473 790.299 633.09 784.879 572.673 717.774L382.506 506.576C322.085 439.473 327.501 336.091 394.605 275.675L605.808 85.5063C672.911 25.0855 776.289 30.5025 836.71 97.6056L1026.88 308.808C1087.3 375.911 1081.88 479.29 1014.77 539.711Z"
        stroke="url(#paint92_linear_1219_6678)"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M1014.77 539.711L803.576 729.878C736.473 790.299 633.09 784.879 572.673 717.774L382.506 506.576C322.085 439.473 327.501 336.091 394.605 275.675L605.808 85.5063C672.911 25.0855 776.289 30.5025 836.71 97.6056L1026.88 308.808C1087.3 375.911 1081.88 479.29 1014.77 539.711Z"
        stroke="url(#paint93_radial_1219_6678)"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M1014.77 539.711L803.576 729.878C736.473 790.299 633.09 784.879 572.673 717.774L382.506 506.576C322.085 439.473 327.501 336.091 394.605 275.675L605.808 85.5063C672.911 25.0855 776.289 30.5025 836.71 97.6056L1026.88 308.808C1087.3 375.911 1081.88 479.29 1014.77 539.711Z"
        stroke="url(#paint94_radial_1219_6678)"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M1014.77 539.711L803.576 729.878C736.473 790.299 633.09 784.879 572.673 717.774L382.506 506.576C322.085 439.473 327.501 336.091 394.605 275.675L605.808 85.5063C672.911 25.0855 776.289 30.5025 836.71 97.6056L1026.88 308.808C1087.3 375.911 1081.88 479.29 1014.77 539.711Z"
        stroke="url(#paint95_linear_1219_6678)"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M1033.77 568.635L793.697 763.039C717.424 824.807 605.518 813.043 543.749 736.771L349.346 496.697C287.555 420.43 299.337 308.519 375.614 246.749L615.666 52.3516C691.937 -9.44018 803.87 2.33596 865.618 78.6186L1060.03 318.666C1121.81 394.96 1110.04 506.871 1033.77 568.635Z"
        stroke="url(#paint96_linear_1219_6678)"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M1033.77 568.635L793.697 763.039C717.424 824.807 605.518 813.043 543.749 736.771L349.346 496.697C287.555 420.43 299.337 308.519 375.614 246.749L615.666 52.3516C691.937 -9.44018 803.87 2.33596 865.618 78.6186L1060.03 318.666C1121.81 394.96 1110.04 506.871 1033.77 568.635Z"
        stroke="url(#paint97_radial_1219_6678)"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M1033.77 568.635L793.697 763.039C717.424 824.807 605.518 813.043 543.749 736.771L349.346 496.697C287.555 420.43 299.337 308.519 375.614 246.749L615.666 52.3516C691.937 -9.44018 803.87 2.33596 865.618 78.6186L1060.03 318.666C1121.81 394.96 1110.04 506.871 1033.77 568.635Z"
        stroke="url(#paint98_radial_1219_6678)"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M1033.77 568.635L793.697 763.039C717.424 824.807 605.518 813.043 543.749 736.771L349.346 496.697C287.555 420.43 299.337 308.519 375.614 246.749L615.666 52.3516C691.937 -9.44018 803.87 2.33596 865.618 78.6186L1060.03 318.666C1121.81 394.96 1110.04 506.871 1033.77 568.635Z"
        stroke="url(#paint99_linear_1219_6678)"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M1052.74 601.112L781.089 798.472C694.782 861.181 573.979 842.049 511.273 755.736L313.908 484.091C251.203 397.782 270.332 276.966 356.644 214.274L628.294 16.9084C714.602 -45.7966 835.401 -26.6634 898.11 59.644L1095.47 331.295C1158.18 417.602 1139.05 538.401 1052.74 601.112Z"
        stroke="url(#paint100_linear_1219_6678)"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M1052.74 601.112L781.089 798.472C694.782 861.181 573.979 842.049 511.273 755.736L313.908 484.091C251.203 397.782 270.332 276.966 356.644 214.274L628.294 16.9084C714.602 -45.7966 835.401 -26.6634 898.11 59.644L1095.47 331.295C1158.18 417.602 1139.05 538.401 1052.74 601.112Z"
        stroke="url(#paint101_radial_1219_6678)"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M1052.74 601.112L781.089 798.472C694.782 861.181 573.979 842.049 511.273 755.736L313.908 484.091C251.203 397.782 270.332 276.966 356.644 214.274L628.294 16.9084C714.602 -45.7966 835.401 -26.6634 898.11 59.644L1095.47 331.295C1158.18 417.602 1139.05 538.401 1052.74 601.112Z"
        stroke="url(#paint102_radial_1219_6678)"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M1052.74 601.112L781.089 798.472C694.782 861.181 573.979 842.049 511.273 755.736L313.908 484.091C251.203 397.782 270.332 276.966 356.644 214.274L628.294 16.9084C714.602 -45.7966 835.401 -26.6634 898.11 59.644L1095.47 331.295C1158.18 417.602 1139.05 538.401 1052.74 601.112Z"
        stroke="url(#paint103_linear_1219_6678)"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M1071.48 637.44L765.389 836.218C668.117 899.38 538.104 871.742 474.942 774.47L276.166 468.39C213.005 371.117 240.642 241.104 337.914 177.942L643.99 -20.8327C741.262 -83.9941 871.276 -56.3568 934.438 40.9154L1133.22 346.99C1196.38 444.263 1168.73 574.283 1071.48 637.44Z"
        stroke="url(#paint104_linear_1219_6678)"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M1071.48 637.44L765.389 836.218C668.117 899.38 538.104 871.742 474.942 774.47L276.166 468.39C213.005 371.117 240.642 241.104 337.914 177.942L643.99 -20.8327C741.262 -83.9941 871.276 -56.3568 934.438 40.9154L1133.22 346.99C1196.38 444.263 1168.73 574.283 1071.48 637.44Z"
        stroke="url(#paint105_radial_1219_6678)"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M1071.48 637.44L765.389 836.218C668.117 899.38 538.104 871.742 474.942 774.47L276.166 468.39C213.005 371.117 240.642 241.104 337.914 177.942L643.99 -20.8327C741.262 -83.9941 871.276 -56.3568 934.438 40.9154L1133.22 346.99C1196.38 444.263 1168.73 574.283 1071.48 637.44Z"
        stroke="url(#paint106_radial_1219_6678)"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M1071.48 637.44L765.389 836.218C668.117 899.38 538.104 871.742 474.942 774.47L276.166 468.39C213.005 371.117 240.642 241.104 337.914 177.942L643.99 -20.8327C741.262 -83.9941 871.276 -56.3568 934.438 40.9154L1133.22 346.99C1196.38 444.263 1168.73 574.283 1071.48 637.44Z"
        stroke="url(#paint107_linear_1219_6678)"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M1089.76 677.942L746.211 876.293C637.054 939.316 497.478 901.914 434.455 792.758L236.083 449.213C173.059 340.057 210.461 200.481 319.618 137.458L663.18 -60.9195C772.336 -123.943 911.917 -86.5423 974.94 22.6143L1173.29 366.174C1236.32 475.339 1198.92 614.919 1089.76 677.942Z"
        stroke="url(#paint108_linear_1219_6678)"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M1089.76 677.942L746.211 876.293C637.054 939.316 497.478 901.914 434.455 792.758L236.083 449.213C173.059 340.057 210.461 200.481 319.618 137.458L663.18 -60.9195C772.336 -123.943 911.917 -86.5423 974.94 22.6143L1173.29 366.174C1236.32 475.339 1198.92 614.919 1089.76 677.942Z"
        stroke="url(#paint109_radial_1219_6678)"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M1089.76 677.942L746.211 876.293C637.054 939.316 497.478 901.914 434.455 792.758L236.083 449.213C173.059 340.057 210.461 200.481 319.618 137.458L663.18 -60.9195C772.336 -123.943 911.917 -86.5423 974.94 22.6143L1173.29 366.174C1236.32 475.339 1198.92 614.919 1089.76 677.942Z"
        stroke="url(#paint110_radial_1219_6678)"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M1089.76 677.942L746.211 876.293C637.054 939.316 497.478 901.914 434.455 792.758L236.083 449.213C173.059 340.057 210.461 200.481 319.618 137.458L663.18 -60.9195C772.336 -123.943 911.917 -86.5423 974.94 22.6143L1173.29 366.174C1236.32 475.339 1198.92 614.919 1089.76 677.942Z"
        stroke="url(#paint111_linear_1219_6678)"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M1107.3 722.944L723.091 918.706C601.02 980.904 451.635 932.361 389.44 810.299L193.7 426.085C131.476 304.021 180.014 154.638 302.085 92.4402L686.299 -103.3C808.369 -165.502 957.752 -116.964 1019.95 5.1072L1215.71 389.315C1277.9 511.364 1229.36 660.749 1107.3 722.944Z"
        stroke="url(#paint112_linear_1219_6678)"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M1107.3 722.944L723.091 918.706C601.02 980.904 451.635 932.361 389.44 810.299L193.7 426.085C131.476 304.021 180.014 154.638 302.085 92.4402L686.299 -103.3C808.369 -165.502 957.752 -116.964 1019.95 5.1072L1215.71 389.315C1277.9 511.364 1229.36 660.749 1107.3 722.944Z"
        stroke="url(#paint113_radial_1219_6678)"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M1107.3 722.944L723.091 918.706C601.02 980.904 451.635 932.361 389.44 810.299L193.7 426.085C131.476 304.021 180.014 154.638 302.085 92.4402L686.299 -103.3C808.369 -165.502 957.752 -116.964 1019.95 5.1072L1215.71 389.315C1277.9 511.364 1229.36 660.749 1107.3 722.944Z"
        stroke="url(#paint114_radial_1219_6678)"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M1107.3 722.944L723.091 918.706C601.02 980.904 451.635 932.361 389.44 810.299L193.7 426.085C131.476 304.021 180.014 154.638 302.085 92.4402L686.299 -103.3C808.369 -165.502 957.752 -116.964 1019.95 5.1072L1215.71 389.315C1277.9 511.364 1229.36 660.749 1107.3 722.944Z"
        stroke="url(#paint115_linear_1219_6678)"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M1123.77 772.79L695.595 963.43C559.562 1024 400.165 962.817 339.594 826.771L148.954 398.595C88.3871 262.562 149.567 103.165 285.609 42.5953L713.789 -148.045C849.822 -208.613 1009.22 -147.433 1069.79 -11.391L1260.43 416.79C1321 552.831 1259.82 712.219 1123.77 772.79Z"
        stroke="url(#paint116_linear_1219_6678)"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M1123.77 772.79L695.595 963.43C559.562 1024 400.165 962.817 339.594 826.771L148.954 398.595C88.3871 262.562 149.567 103.165 285.609 42.5953L713.789 -148.045C849.822 -208.613 1009.22 -147.433 1069.79 -11.391L1260.43 416.79C1321 552.831 1259.82 712.219 1123.77 772.79Z"
        stroke="url(#paint117_radial_1219_6678)"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M1123.77 772.79L695.595 963.43C559.562 1024 400.165 962.817 339.594 826.771L148.954 398.595C88.3871 262.562 149.567 103.165 285.609 42.5953L713.789 -148.045C849.822 -208.613 1009.22 -147.433 1069.79 -11.391L1260.43 416.79C1321 552.831 1259.82 712.219 1123.77 772.79Z"
        stroke="url(#paint118_radial_1219_6678)"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M1123.77 772.79L695.595 963.43C559.562 1024 400.165 962.817 339.594 826.771L148.954 398.595C88.3871 262.562 149.567 103.165 285.609 42.5953L713.789 -148.045C849.822 -208.613 1009.22 -147.433 1069.79 -11.391L1260.43 416.79C1321 552.831 1259.82 712.219 1123.77 772.79Z"
        stroke="url(#paint119_linear_1219_6678)"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M1138.82 827.834L663.199 1010.41C512.087 1068.42 342.555 992.935 284.55 841.821L101.991 366.196C43.9684 215.087 119.463 45.5521 270.563 -12.4499L746.184 -195.007C897.302 -253.014 1066.83 -177.537 1124.84 -26.4189L1307.41 449.197C1365.42 600.295 1289.94 769.828 1138.82 827.834Z"
        stroke="url(#paint120_linear_1219_6678)"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M1138.82 827.834L663.199 1010.41C512.087 1068.42 342.555 992.935 284.55 841.821L101.991 366.196C43.9684 215.087 119.463 45.5521 270.563 -12.4499L746.184 -195.007C897.302 -253.014 1066.83 -177.537 1124.84 -26.4189L1307.41 449.197C1365.42 600.295 1289.94 769.828 1138.82 827.834Z"
        stroke="url(#paint121_radial_1219_6678)"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M1138.82 827.834L663.199 1010.41C512.087 1068.42 342.555 992.935 284.55 841.821L101.991 366.196C43.9684 215.087 119.463 45.5521 270.563 -12.4499L746.184 -195.007C897.302 -253.014 1066.83 -177.537 1124.84 -26.4189L1307.41 449.197C1365.42 600.295 1289.94 769.828 1138.82 827.834Z"
        stroke="url(#paint122_radial_1219_6678)"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M1138.82 827.834L663.199 1010.41C512.087 1068.42 342.555 992.935 284.55 841.821L101.991 366.196C43.9684 215.087 119.463 45.5521 270.563 -12.4499L746.184 -195.007C897.302 -253.014 1066.83 -177.537 1124.84 -26.4189L1307.41 449.197C1365.42 600.295 1289.94 769.828 1138.82 827.834Z"
        stroke="url(#paint123_linear_1219_6678)"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M1152.02 888.439L625.367 1059.56C458.037 1113.92 278.312 1022.36 223.945 855.024L52.822 328.367C-1.54418 161.038 90.0311 -18.6886 257.36 -73.0548L784.012 -244.177C951.346 -298.544 1131.07 -206.968 1185.44 -39.6397L1356.56 487.013C1410.92 654.347 1319.36 834.067 1152.02 888.439Z"
        stroke="url(#paint124_linear_1219_6678)"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M1152.02 888.439L625.367 1059.56C458.037 1113.92 278.312 1022.36 223.945 855.024L52.822 328.367C-1.54418 161.038 90.0311 -18.6886 257.36 -73.0548L784.012 -244.177C951.346 -298.544 1131.07 -206.968 1185.44 -39.6397L1356.56 487.013C1410.92 654.347 1319.36 834.067 1152.02 888.439Z"
        stroke="url(#paint125_radial_1219_6678)"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M1152.02 888.439L625.367 1059.56C458.037 1113.92 278.312 1022.36 223.945 855.024L52.822 328.367C-1.54418 161.038 90.0311 -18.6886 257.36 -73.0548L784.012 -244.177C951.346 -298.544 1131.07 -206.968 1185.44 -39.6397L1356.56 487.013C1410.92 654.347 1319.36 834.067 1152.02 888.439Z"
        stroke="url(#paint126_radial_1219_6678)"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M1152.02 888.439L625.367 1059.56C458.037 1113.92 278.312 1022.36 223.945 855.024L52.822 328.367C-1.54418 161.038 90.0311 -18.6886 257.36 -73.0548L784.012 -244.177C951.346 -298.544 1131.07 -206.968 1185.44 -39.6397L1356.56 487.013C1410.92 654.347 1319.36 834.067 1152.02 888.439Z"
        stroke="url(#paint127_linear_1219_6678)"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M827.88 -295.378L246.473 -139.591C61.7487 -90.0938 -47.875 99.78 1.62179 284.504L157.409 865.911C206.906 1050.64 396.78 1160.26 581.504 1110.76L1162.91 954.975C1347.64 905.478 1457.26 715.605 1407.76 530.88L1251.97 -50.5268C1202.48 -235.251 1012.6 -344.875 827.88 -295.378Z"
        stroke="url(#paint128_linear_1219_6678)"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M827.88 -295.378L246.473 -139.591C61.7487 -90.0938 -47.875 99.78 1.62179 284.504L157.409 865.911C206.906 1050.64 396.78 1160.26 581.504 1110.76L1162.91 954.975C1347.64 905.478 1457.26 715.605 1407.76 530.88L1251.97 -50.5268C1202.48 -235.251 1012.6 -344.875 827.88 -295.378Z"
        stroke="url(#paint129_radial_1219_6678)"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M827.88 -295.378L246.473 -139.591C61.7487 -90.0938 -47.875 99.78 1.62179 284.504L157.409 865.911C206.906 1050.64 396.78 1160.26 581.504 1110.76L1162.91 954.975C1347.64 905.478 1457.26 715.605 1407.76 530.88L1251.97 -50.5268C1202.48 -235.251 1012.6 -344.875 827.88 -295.378Z"
        stroke="url(#paint130_radial_1219_6678)"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
      <path
        d="M827.88 -295.378L246.473 -139.591C61.7487 -90.0938 -47.875 99.78 1.62179 284.504L157.409 865.911C206.906 1050.64 396.78 1160.26 581.504 1110.76L1162.91 954.975C1347.64 905.478 1457.26 715.605 1407.76 530.88L1251.97 -50.5268C1202.48 -235.251 1012.6 -344.875 827.88 -295.378Z"
        stroke="url(#paint131_linear_1219_6678)"
        strokeWidth="2"
        strokeMiterlimit="10"
      />
    </g>
    <defs>
      <linearGradient
        id="paint0_linear_1219_6678"
        x1="1307"
        y1="889"
        x2="292.5"
        y2="23.5001"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.777821" stopColor="#48A0F9" />
        <stop offset="0.896175" stopColor="#FFAA8F" />
      </linearGradient>
      <radialGradient
        id="paint1_radial_1219_6678"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(704.692 407.692) rotate(75) scale(647.23)"
      >
        <stop offset="0.0572917" stopColor="white" />
        <stop offset="1" stopColor="white" stopOpacity="0" />
      </radialGradient>
      <radialGradient
        id="paint2_radial_1219_6678"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(810 1025.5) rotate(-102.781) scale(592.172 1639.45)"
      >
        <stop offset="0.525786" stopColor="white" />
        <stop offset="1" stopColor="white" stopOpacity="0" />
      </radialGradient>
      <linearGradient
        id="paint3_linear_1219_6678"
        x1="887.5"
        y1="-2.49998"
        x2="886"
        y2="320"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="white" />
        <stop offset="1" stopColor="white" stopOpacity="0" />
      </linearGradient>
      <linearGradient
        id="paint4_linear_1219_6678"
        x1="1307"
        y1="889"
        x2="292.5"
        y2="23.5001"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.777821" stopColor="#48A0F9" />
        <stop offset="0.896175" stopColor="#FFAA8F" />
      </linearGradient>
      <radialGradient
        id="paint5_radial_1219_6678"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(704.692 407.692) rotate(75) scale(647.23)"
      >
        <stop offset="0.0572917" stopColor="white" />
        <stop offset="1" stopColor="white" stopOpacity="0" />
      </radialGradient>
      <radialGradient
        id="paint6_radial_1219_6678"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(810 1025.5) rotate(-102.781) scale(592.172 1639.45)"
      >
        <stop offset="0.525786" stopColor="white" />
        <stop offset="1" stopColor="white" stopOpacity="0" />
      </radialGradient>
      <linearGradient
        id="paint7_linear_1219_6678"
        x1="887.5"
        y1="-2.49998"
        x2="886"
        y2="320"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="white" />
        <stop offset="1" stopColor="white" stopOpacity="0" />
      </linearGradient>
      <linearGradient
        id="paint8_linear_1219_6678"
        x1="1307"
        y1="889"
        x2="292.5"
        y2="23.5001"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.777821" stopColor="#48A0F9" />
        <stop offset="0.896175" stopColor="#FFAA8F" />
      </linearGradient>
      <radialGradient
        id="paint9_radial_1219_6678"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(704.692 407.692) rotate(75) scale(647.23)"
      >
        <stop offset="0.0572917" stopColor="white" />
        <stop offset="1" stopColor="white" stopOpacity="0" />
      </radialGradient>
      <radialGradient
        id="paint10_radial_1219_6678"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(810 1025.5) rotate(-102.781) scale(592.172 1639.45)"
      >
        <stop offset="0.525786" stopColor="white" />
        <stop offset="1" stopColor="white" stopOpacity="0" />
      </radialGradient>
      <linearGradient
        id="paint11_linear_1219_6678"
        x1="887.5"
        y1="-2.49998"
        x2="886"
        y2="320"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="white" />
        <stop offset="1" stopColor="white" stopOpacity="0" />
      </linearGradient>
      <linearGradient
        id="paint12_linear_1219_6678"
        x1="1307"
        y1="889"
        x2="292.5"
        y2="23.5001"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.777821" stopColor="#48A0F9" />
        <stop offset="0.896175" stopColor="#FFAA8F" />
      </linearGradient>
      <radialGradient
        id="paint13_radial_1219_6678"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(704.692 407.692) rotate(75) scale(647.23)"
      >
        <stop offset="0.0572917" stopColor="white" />
        <stop offset="1" stopColor="white" stopOpacity="0" />
      </radialGradient>
      <radialGradient
        id="paint14_radial_1219_6678"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(810 1025.5) rotate(-102.781) scale(592.172 1639.45)"
      >
        <stop offset="0.525786" stopColor="white" />
        <stop offset="1" stopColor="white" stopOpacity="0" />
      </radialGradient>
      <linearGradient
        id="paint15_linear_1219_6678"
        x1="887.5"
        y1="-2.49998"
        x2="886"
        y2="320"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="white" />
        <stop offset="1" stopColor="white" stopOpacity="0" />
      </linearGradient>
      <linearGradient
        id="paint16_linear_1219_6678"
        x1="1307"
        y1="889"
        x2="292.5"
        y2="23.5001"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.777821" stopColor="#48A0F9" />
        <stop offset="0.896175" stopColor="#FFAA8F" />
      </linearGradient>
      <radialGradient
        id="paint17_radial_1219_6678"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(704.692 407.692) rotate(75) scale(647.23)"
      >
        <stop offset="0.0572917" stopColor="white" />
        <stop offset="1" stopColor="white" stopOpacity="0" />
      </radialGradient>
      <radialGradient
        id="paint18_radial_1219_6678"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(810 1025.5) rotate(-102.781) scale(592.172 1639.45)"
      >
        <stop offset="0.525786" stopColor="white" />
        <stop offset="1" stopColor="white" stopOpacity="0" />
      </radialGradient>
      <linearGradient
        id="paint19_linear_1219_6678"
        x1="887.5"
        y1="-2.49998"
        x2="886"
        y2="320"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="white" />
        <stop offset="1" stopColor="white" stopOpacity="0" />
      </linearGradient>
      <linearGradient
        id="paint20_linear_1219_6678"
        x1="1307"
        y1="889"
        x2="292.5"
        y2="23.5001"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.777821" stopColor="#48A0F9" />
        <stop offset="0.896175" stopColor="#FFAA8F" />
      </linearGradient>
      <radialGradient
        id="paint21_radial_1219_6678"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(704.692 407.692) rotate(75) scale(647.23)"
      >
        <stop offset="0.0572917" stopColor="white" />
        <stop offset="1" stopColor="white" stopOpacity="0" />
      </radialGradient>
      <radialGradient
        id="paint22_radial_1219_6678"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(810 1025.5) rotate(-102.781) scale(592.172 1639.45)"
      >
        <stop offset="0.525786" stopColor="white" />
        <stop offset="1" stopColor="white" stopOpacity="0" />
      </radialGradient>
      <linearGradient
        id="paint23_linear_1219_6678"
        x1="887.5"
        y1="-2.49998"
        x2="886"
        y2="320"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="white" />
        <stop offset="1" stopColor="white" stopOpacity="0" />
      </linearGradient>
      <linearGradient
        id="paint24_linear_1219_6678"
        x1="1307"
        y1="889"
        x2="292.5"
        y2="23.5001"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.777821" stopColor="#48A0F9" />
        <stop offset="0.896175" stopColor="#FFAA8F" />
      </linearGradient>
      <radialGradient
        id="paint25_radial_1219_6678"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(704.692 407.692) rotate(75) scale(647.23)"
      >
        <stop offset="0.0572917" stopColor="white" />
        <stop offset="1" stopColor="white" stopOpacity="0" />
      </radialGradient>
      <radialGradient
        id="paint26_radial_1219_6678"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(810 1025.5) rotate(-102.781) scale(592.172 1639.45)"
      >
        <stop offset="0.525786" stopColor="white" />
        <stop offset="1" stopColor="white" stopOpacity="0" />
      </radialGradient>
      <linearGradient
        id="paint27_linear_1219_6678"
        x1="887.5"
        y1="-2.49998"
        x2="886"
        y2="320"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="white" />
        <stop offset="1" stopColor="white" stopOpacity="0" />
      </linearGradient>
      <linearGradient
        id="paint28_linear_1219_6678"
        x1="1307"
        y1="889"
        x2="292.5"
        y2="23.5001"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.777821" stopColor="#48A0F9" />
        <stop offset="0.896175" stopColor="#FFAA8F" />
      </linearGradient>
      <radialGradient
        id="paint29_radial_1219_6678"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(704.692 407.692) rotate(75) scale(647.23)"
      >
        <stop offset="0.0572917" stopColor="white" />
        <stop offset="1" stopColor="white" stopOpacity="0" />
      </radialGradient>
      <radialGradient
        id="paint30_radial_1219_6678"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(810 1025.5) rotate(-102.781) scale(592.172 1639.45)"
      >
        <stop offset="0.525786" stopColor="white" />
        <stop offset="1" stopColor="white" stopOpacity="0" />
      </radialGradient>
      <linearGradient
        id="paint31_linear_1219_6678"
        x1="887.5"
        y1="-2.49998"
        x2="886"
        y2="320"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="white" />
        <stop offset="1" stopColor="white" stopOpacity="0" />
      </linearGradient>
      <linearGradient
        id="paint32_linear_1219_6678"
        x1="1307"
        y1="889"
        x2="292.5"
        y2="23.5001"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.777821" stopColor="#48A0F9" />
        <stop offset="0.896175" stopColor="#FFAA8F" />
      </linearGradient>
      <radialGradient
        id="paint33_radial_1219_6678"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(704.692 407.692) rotate(75) scale(647.23)"
      >
        <stop offset="0.0572917" stopColor="white" />
        <stop offset="1" stopColor="white" stopOpacity="0" />
      </radialGradient>
      <radialGradient
        id="paint34_radial_1219_6678"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(810 1025.5) rotate(-102.781) scale(592.172 1639.45)"
      >
        <stop offset="0.525786" stopColor="white" />
        <stop offset="1" stopColor="white" stopOpacity="0" />
      </radialGradient>
      <linearGradient
        id="paint35_linear_1219_6678"
        x1="887.5"
        y1="-2.49998"
        x2="886"
        y2="320"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="white" />
        <stop offset="1" stopColor="white" stopOpacity="0" />
      </linearGradient>
      <linearGradient
        id="paint36_linear_1219_6678"
        x1="1307"
        y1="889"
        x2="292.5"
        y2="23.5001"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.777821" stopColor="#48A0F9" />
        <stop offset="0.896175" stopColor="#FFAA8F" />
      </linearGradient>
      <radialGradient
        id="paint37_radial_1219_6678"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(704.692 407.692) rotate(75) scale(647.23)"
      >
        <stop offset="0.0572917" stopColor="white" />
        <stop offset="1" stopColor="white" stopOpacity="0" />
      </radialGradient>
      <radialGradient
        id="paint38_radial_1219_6678"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(810 1025.5) rotate(-102.781) scale(592.172 1639.45)"
      >
        <stop offset="0.525786" stopColor="white" />
        <stop offset="1" stopColor="white" stopOpacity="0" />
      </radialGradient>
      <linearGradient
        id="paint39_linear_1219_6678"
        x1="887.5"
        y1="-2.49998"
        x2="886"
        y2="320"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="white" />
        <stop offset="1" stopColor="white" stopOpacity="0" />
      </linearGradient>
      <linearGradient
        id="paint40_linear_1219_6678"
        x1="1307"
        y1="889"
        x2="292.5"
        y2="23.5001"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.777821" stopColor="#48A0F9" />
        <stop offset="0.896175" stopColor="#FFAA8F" />
      </linearGradient>
      <radialGradient
        id="paint41_radial_1219_6678"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(704.692 407.692) rotate(75) scale(647.23)"
      >
        <stop offset="0.0572917" stopColor="white" />
        <stop offset="1" stopColor="white" stopOpacity="0" />
      </radialGradient>
      <radialGradient
        id="paint42_radial_1219_6678"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(810 1025.5) rotate(-102.781) scale(592.172 1639.45)"
      >
        <stop offset="0.525786" stopColor="white" />
        <stop offset="1" stopColor="white" stopOpacity="0" />
      </radialGradient>
      <linearGradient
        id="paint43_linear_1219_6678"
        x1="887.5"
        y1="-2.49998"
        x2="886"
        y2="320"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="white" />
        <stop offset="1" stopColor="white" stopOpacity="0" />
      </linearGradient>
      <linearGradient
        id="paint44_linear_1219_6678"
        x1="1307"
        y1="889"
        x2="292.5"
        y2="23.5001"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.777821" stopColor="#48A0F9" />
        <stop offset="0.896175" stopColor="#FFAA8F" />
      </linearGradient>
      <radialGradient
        id="paint45_radial_1219_6678"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(704.692 407.692) rotate(75) scale(647.23)"
      >
        <stop offset="0.0572917" stopColor="white" />
        <stop offset="1" stopColor="white" stopOpacity="0" />
      </radialGradient>
      <radialGradient
        id="paint46_radial_1219_6678"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(810 1025.5) rotate(-102.781) scale(592.172 1639.45)"
      >
        <stop offset="0.525786" stopColor="white" />
        <stop offset="1" stopColor="white" stopOpacity="0" />
      </radialGradient>
      <linearGradient
        id="paint47_linear_1219_6678"
        x1="887.5"
        y1="-2.49998"
        x2="886"
        y2="320"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="white" />
        <stop offset="1" stopColor="white" stopOpacity="0" />
      </linearGradient>
      <linearGradient
        id="paint48_linear_1219_6678"
        x1="1307"
        y1="889"
        x2="292.5"
        y2="23.5001"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.777821" stopColor="#48A0F9" />
        <stop offset="0.896175" stopColor="#FFAA8F" />
      </linearGradient>
      <radialGradient
        id="paint49_radial_1219_6678"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(704.692 407.692) rotate(75) scale(647.23)"
      >
        <stop offset="0.0572917" stopColor="white" />
        <stop offset="1" stopColor="white" stopOpacity="0" />
      </radialGradient>
      <radialGradient
        id="paint50_radial_1219_6678"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(810 1025.5) rotate(-102.781) scale(592.172 1639.45)"
      >
        <stop offset="0.525786" stopColor="white" />
        <stop offset="1" stopColor="white" stopOpacity="0" />
      </radialGradient>
      <linearGradient
        id="paint51_linear_1219_6678"
        x1="887.5"
        y1="-2.49998"
        x2="886"
        y2="320"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="white" />
        <stop offset="1" stopColor="white" stopOpacity="0" />
      </linearGradient>
      <linearGradient
        id="paint52_linear_1219_6678"
        x1="1307"
        y1="889"
        x2="292.5"
        y2="23.5001"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.777821" stopColor="#48A0F9" />
        <stop offset="0.896175" stopColor="#FFAA8F" />
      </linearGradient>
      <radialGradient
        id="paint53_radial_1219_6678"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(704.692 407.692) rotate(75) scale(647.23)"
      >
        <stop offset="0.0572917" stopColor="white" />
        <stop offset="1" stopColor="white" stopOpacity="0" />
      </radialGradient>
      <radialGradient
        id="paint54_radial_1219_6678"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(810 1025.5) rotate(-102.781) scale(592.172 1639.45)"
      >
        <stop offset="0.525786" stopColor="white" />
        <stop offset="1" stopColor="white" stopOpacity="0" />
      </radialGradient>
      <linearGradient
        id="paint55_linear_1219_6678"
        x1="887.5"
        y1="-2.49998"
        x2="886"
        y2="320"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="white" />
        <stop offset="1" stopColor="white" stopOpacity="0" />
      </linearGradient>
      <linearGradient
        id="paint56_linear_1219_6678"
        x1="1307"
        y1="889"
        x2="292.5"
        y2="23.5001"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.777821" stopColor="#48A0F9" />
        <stop offset="0.896175" stopColor="#FFAA8F" />
      </linearGradient>
      <radialGradient
        id="paint57_radial_1219_6678"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(704.692 407.692) rotate(75) scale(647.23)"
      >
        <stop offset="0.0572917" stopColor="white" />
        <stop offset="1" stopColor="white" stopOpacity="0" />
      </radialGradient>
      <radialGradient
        id="paint58_radial_1219_6678"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(810 1025.5) rotate(-102.781) scale(592.172 1639.45)"
      >
        <stop offset="0.525786" stopColor="white" />
        <stop offset="1" stopColor="white" stopOpacity="0" />
      </radialGradient>
      <linearGradient
        id="paint59_linear_1219_6678"
        x1="887.5"
        y1="-2.49998"
        x2="886"
        y2="320"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="white" />
        <stop offset="1" stopColor="white" stopOpacity="0" />
      </linearGradient>
      <linearGradient
        id="paint60_linear_1219_6678"
        x1="1307"
        y1="889"
        x2="292.5"
        y2="23.5001"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.777821" stopColor="#48A0F9" />
        <stop offset="0.896175" stopColor="#FFAA8F" />
      </linearGradient>
      <radialGradient
        id="paint61_radial_1219_6678"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(704.692 407.692) rotate(75) scale(647.23)"
      >
        <stop offset="0.0572917" stopColor="white" />
        <stop offset="1" stopColor="white" stopOpacity="0" />
      </radialGradient>
      <radialGradient
        id="paint62_radial_1219_6678"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(810 1025.5) rotate(-102.781) scale(592.172 1639.45)"
      >
        <stop offset="0.525786" stopColor="white" />
        <stop offset="1" stopColor="white" stopOpacity="0" />
      </radialGradient>
      <linearGradient
        id="paint63_linear_1219_6678"
        x1="887.5"
        y1="-2.49998"
        x2="886"
        y2="320"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="white" />
        <stop offset="1" stopColor="white" stopOpacity="0" />
      </linearGradient>
      <linearGradient
        id="paint64_linear_1219_6678"
        x1="1307"
        y1="889"
        x2="292.5"
        y2="23.5001"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.777821" stopColor="#48A0F9" />
        <stop offset="0.896175" stopColor="#FFAA8F" />
      </linearGradient>
      <radialGradient
        id="paint65_radial_1219_6678"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(704.692 407.692) rotate(75) scale(647.23)"
      >
        <stop offset="0.0572917" stopColor="white" />
        <stop offset="1" stopColor="white" stopOpacity="0" />
      </radialGradient>
      <radialGradient
        id="paint66_radial_1219_6678"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(810 1025.5) rotate(-102.781) scale(592.172 1639.45)"
      >
        <stop offset="0.525786" stopColor="white" />
        <stop offset="1" stopColor="white" stopOpacity="0" />
      </radialGradient>
      <linearGradient
        id="paint67_linear_1219_6678"
        x1="887.5"
        y1="-2.49998"
        x2="886"
        y2="320"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="white" />
        <stop offset="1" stopColor="white" stopOpacity="0" />
      </linearGradient>
      <linearGradient
        id="paint68_linear_1219_6678"
        x1="1307"
        y1="889"
        x2="292.5"
        y2="23.5001"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.777821" stopColor="#48A0F9" />
        <stop offset="0.896175" stopColor="#FFAA8F" />
      </linearGradient>
      <radialGradient
        id="paint69_radial_1219_6678"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(704.692 407.692) rotate(75) scale(647.23)"
      >
        <stop offset="0.0572917" stopColor="white" />
        <stop offset="1" stopColor="white" stopOpacity="0" />
      </radialGradient>
      <radialGradient
        id="paint70_radial_1219_6678"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(810 1025.5) rotate(-102.781) scale(592.172 1639.45)"
      >
        <stop offset="0.525786" stopColor="white" />
        <stop offset="1" stopColor="white" stopOpacity="0" />
      </radialGradient>
      <linearGradient
        id="paint71_linear_1219_6678"
        x1="887.5"
        y1="-2.49998"
        x2="886"
        y2="320"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="white" />
        <stop offset="1" stopColor="white" stopOpacity="0" />
      </linearGradient>
      <linearGradient
        id="paint72_linear_1219_6678"
        x1="1307"
        y1="889"
        x2="292.5"
        y2="23.5001"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.777821" stopColor="#48A0F9" />
        <stop offset="0.896175" stopColor="#FFAA8F" />
      </linearGradient>
      <radialGradient
        id="paint73_radial_1219_6678"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(704.692 407.692) rotate(75) scale(647.23)"
      >
        <stop offset="0.0572917" stopColor="white" />
        <stop offset="1" stopColor="white" stopOpacity="0" />
      </radialGradient>
      <radialGradient
        id="paint74_radial_1219_6678"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(810 1025.5) rotate(-102.781) scale(592.172 1639.45)"
      >
        <stop offset="0.525786" stopColor="white" />
        <stop offset="1" stopColor="white" stopOpacity="0" />
      </radialGradient>
      <linearGradient
        id="paint75_linear_1219_6678"
        x1="887.5"
        y1="-2.49998"
        x2="886"
        y2="320"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="white" />
        <stop offset="1" stopColor="white" stopOpacity="0" />
      </linearGradient>
      <linearGradient
        id="paint76_linear_1219_6678"
        x1="1307"
        y1="889"
        x2="292.5"
        y2="23.5001"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.777821" stopColor="#48A0F9" />
        <stop offset="0.896175" stopColor="#FFAA8F" />
      </linearGradient>
      <radialGradient
        id="paint77_radial_1219_6678"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(704.692 407.692) rotate(75) scale(647.23)"
      >
        <stop offset="0.0572917" stopColor="white" />
        <stop offset="1" stopColor="white" stopOpacity="0" />
      </radialGradient>
      <radialGradient
        id="paint78_radial_1219_6678"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(810 1025.5) rotate(-102.781) scale(592.172 1639.45)"
      >
        <stop offset="0.525786" stopColor="white" />
        <stop offset="1" stopColor="white" stopOpacity="0" />
      </radialGradient>
      <linearGradient
        id="paint79_linear_1219_6678"
        x1="887.5"
        y1="-2.49998"
        x2="886"
        y2="320"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="white" />
        <stop offset="1" stopColor="white" stopOpacity="0" />
      </linearGradient>
      <linearGradient
        id="paint80_linear_1219_6678"
        x1="1307"
        y1="889"
        x2="292.5"
        y2="23.5001"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.777821" stopColor="#48A0F9" />
        <stop offset="0.896175" stopColor="#FFAA8F" />
      </linearGradient>
      <radialGradient
        id="paint81_radial_1219_6678"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(704.692 407.692) rotate(75) scale(647.23)"
      >
        <stop offset="0.0572917" stopColor="white" />
        <stop offset="1" stopColor="white" stopOpacity="0" />
      </radialGradient>
      <radialGradient
        id="paint82_radial_1219_6678"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(810 1025.5) rotate(-102.781) scale(592.172 1639.45)"
      >
        <stop offset="0.525786" stopColor="white" />
        <stop offset="1" stopColor="white" stopOpacity="0" />
      </radialGradient>
      <linearGradient
        id="paint83_linear_1219_6678"
        x1="887.5"
        y1="-2.49998"
        x2="886"
        y2="320"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="white" />
        <stop offset="1" stopColor="white" stopOpacity="0" />
      </linearGradient>
      <linearGradient
        id="paint84_linear_1219_6678"
        x1="1307"
        y1="889"
        x2="292.5"
        y2="23.5001"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.777821" stopColor="#48A0F9" />
        <stop offset="0.896175" stopColor="#FFAA8F" />
      </linearGradient>
      <radialGradient
        id="paint85_radial_1219_6678"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(704.692 407.692) rotate(75) scale(647.23)"
      >
        <stop offset="0.0572917" stopColor="white" />
        <stop offset="1" stopColor="white" stopOpacity="0" />
      </radialGradient>
      <radialGradient
        id="paint86_radial_1219_6678"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(810 1025.5) rotate(-102.781) scale(592.172 1639.45)"
      >
        <stop offset="0.525786" stopColor="white" />
        <stop offset="1" stopColor="white" stopOpacity="0" />
      </radialGradient>
      <linearGradient
        id="paint87_linear_1219_6678"
        x1="887.5"
        y1="-2.49998"
        x2="886"
        y2="320"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="white" />
        <stop offset="1" stopColor="white" stopOpacity="0" />
      </linearGradient>
      <linearGradient
        id="paint88_linear_1219_6678"
        x1="1307"
        y1="889"
        x2="292.5"
        y2="23.5001"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.777821" stopColor="#48A0F9" />
        <stop offset="0.896175" stopColor="#FFAA8F" />
      </linearGradient>
      <radialGradient
        id="paint89_radial_1219_6678"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(704.692 407.692) rotate(75) scale(647.23)"
      >
        <stop offset="0.0572917" stopColor="white" />
        <stop offset="1" stopColor="white" stopOpacity="0" />
      </radialGradient>
      <radialGradient
        id="paint90_radial_1219_6678"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(810 1025.5) rotate(-102.781) scale(592.172 1639.45)"
      >
        <stop offset="0.525786" stopColor="white" />
        <stop offset="1" stopColor="white" stopOpacity="0" />
      </radialGradient>
      <linearGradient
        id="paint91_linear_1219_6678"
        x1="887.5"
        y1="-2.49998"
        x2="886"
        y2="320"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="white" />
        <stop offset="1" stopColor="white" stopOpacity="0" />
      </linearGradient>
      <linearGradient
        id="paint92_linear_1219_6678"
        x1="1307"
        y1="889"
        x2="292.5"
        y2="23.5001"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.777821" stopColor="#48A0F9" />
        <stop offset="0.896175" stopColor="#FFAA8F" />
      </linearGradient>
      <radialGradient
        id="paint93_radial_1219_6678"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(704.692 407.692) rotate(75) scale(647.23)"
      >
        <stop offset="0.0572917" stopColor="white" />
        <stop offset="1" stopColor="white" stopOpacity="0" />
      </radialGradient>
      <radialGradient
        id="paint94_radial_1219_6678"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(810 1025.5) rotate(-102.781) scale(592.172 1639.45)"
      >
        <stop offset="0.525786" stopColor="white" />
        <stop offset="1" stopColor="white" stopOpacity="0" />
      </radialGradient>
      <linearGradient
        id="paint95_linear_1219_6678"
        x1="887.5"
        y1="-2.49998"
        x2="886"
        y2="320"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="white" />
        <stop offset="1" stopColor="white" stopOpacity="0" />
      </linearGradient>
      <linearGradient
        id="paint96_linear_1219_6678"
        x1="1307"
        y1="889"
        x2="292.5"
        y2="23.5001"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.777821" stopColor="#48A0F9" />
        <stop offset="0.896175" stopColor="#FFAA8F" />
      </linearGradient>
      <radialGradient
        id="paint97_radial_1219_6678"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(704.692 407.692) rotate(75) scale(647.23)"
      >
        <stop offset="0.0572917" stopColor="white" />
        <stop offset="1" stopColor="white" stopOpacity="0" />
      </radialGradient>
      <radialGradient
        id="paint98_radial_1219_6678"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(810 1025.5) rotate(-102.781) scale(592.172 1639.45)"
      >
        <stop offset="0.525786" stopColor="white" />
        <stop offset="1" stopColor="white" stopOpacity="0" />
      </radialGradient>
      <linearGradient
        id="paint99_linear_1219_6678"
        x1="887.5"
        y1="-2.49998"
        x2="886"
        y2="320"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="white" />
        <stop offset="1" stopColor="white" stopOpacity="0" />
      </linearGradient>
      <linearGradient
        id="paint100_linear_1219_6678"
        x1="1307"
        y1="889"
        x2="292.5"
        y2="23.5001"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.777821" stopColor="#48A0F9" />
        <stop offset="0.896175" stopColor="#FFAA8F" />
      </linearGradient>
      <radialGradient
        id="paint101_radial_1219_6678"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(704.692 407.692) rotate(75) scale(647.23)"
      >
        <stop offset="0.0572917" stopColor="white" />
        <stop offset="1" stopColor="white" stopOpacity="0" />
      </radialGradient>
      <radialGradient
        id="paint102_radial_1219_6678"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(810 1025.5) rotate(-102.781) scale(592.172 1639.45)"
      >
        <stop offset="0.525786" stopColor="white" />
        <stop offset="1" stopColor="white" stopOpacity="0" />
      </radialGradient>
      <linearGradient
        id="paint103_linear_1219_6678"
        x1="887.5"
        y1="-2.49998"
        x2="886"
        y2="320"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="white" />
        <stop offset="1" stopColor="white" stopOpacity="0" />
      </linearGradient>
      <linearGradient
        id="paint104_linear_1219_6678"
        x1="1307"
        y1="889"
        x2="292.5"
        y2="23.5001"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.777821" stopColor="#48A0F9" />
        <stop offset="0.896175" stopColor="#FFAA8F" />
      </linearGradient>
      <radialGradient
        id="paint105_radial_1219_6678"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(704.692 407.692) rotate(75) scale(647.23)"
      >
        <stop offset="0.0572917" stopColor="white" />
        <stop offset="1" stopColor="white" stopOpacity="0" />
      </radialGradient>
      <radialGradient
        id="paint106_radial_1219_6678"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(810 1025.5) rotate(-102.781) scale(592.172 1639.45)"
      >
        <stop offset="0.525786" stopColor="white" />
        <stop offset="1" stopColor="white" stopOpacity="0" />
      </radialGradient>
      <linearGradient
        id="paint107_linear_1219_6678"
        x1="887.5"
        y1="-2.49998"
        x2="886"
        y2="320"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="white" />
        <stop offset="1" stopColor="white" stopOpacity="0" />
      </linearGradient>
      <linearGradient
        id="paint108_linear_1219_6678"
        x1="1307"
        y1="889"
        x2="292.5"
        y2="23.5001"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.777821" stopColor="#48A0F9" />
        <stop offset="0.896175" stopColor="#FFAA8F" />
      </linearGradient>
      <radialGradient
        id="paint109_radial_1219_6678"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(704.692 407.692) rotate(75) scale(647.23)"
      >
        <stop offset="0.0572917" stopColor="white" />
        <stop offset="1" stopColor="white" stopOpacity="0" />
      </radialGradient>
      <radialGradient
        id="paint110_radial_1219_6678"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(810 1025.5) rotate(-102.781) scale(592.172 1639.45)"
      >
        <stop offset="0.525786" stopColor="white" />
        <stop offset="1" stopColor="white" stopOpacity="0" />
      </radialGradient>
      <linearGradient
        id="paint111_linear_1219_6678"
        x1="887.5"
        y1="-2.49998"
        x2="886"
        y2="320"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="white" />
        <stop offset="1" stopColor="white" stopOpacity="0" />
      </linearGradient>
      <linearGradient
        id="paint112_linear_1219_6678"
        x1="1307"
        y1="889"
        x2="292.5"
        y2="23.5001"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.777821" stopColor="#48A0F9" />
        <stop offset="0.896175" stopColor="#FFAA8F" />
      </linearGradient>
      <radialGradient
        id="paint113_radial_1219_6678"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(704.692 407.692) rotate(75) scale(647.23)"
      >
        <stop offset="0.0572917" stopColor="white" />
        <stop offset="1" stopColor="white" stopOpacity="0" />
      </radialGradient>
      <radialGradient
        id="paint114_radial_1219_6678"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(810 1025.5) rotate(-102.781) scale(592.172 1639.45)"
      >
        <stop offset="0.525786" stopColor="white" />
        <stop offset="1" stopColor="white" stopOpacity="0" />
      </radialGradient>
      <linearGradient
        id="paint115_linear_1219_6678"
        x1="887.5"
        y1="-2.49998"
        x2="886"
        y2="320"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="white" />
        <stop offset="1" stopColor="white" stopOpacity="0" />
      </linearGradient>
      <linearGradient
        id="paint116_linear_1219_6678"
        x1="1307"
        y1="889"
        x2="292.5"
        y2="23.5001"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.777821" stopColor="#48A0F9" />
        <stop offset="0.896175" stopColor="#FFAA8F" />
      </linearGradient>
      <radialGradient
        id="paint117_radial_1219_6678"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(704.692 407.692) rotate(75) scale(647.23)"
      >
        <stop offset="0.0572917" stopColor="white" />
        <stop offset="1" stopColor="white" stopOpacity="0" />
      </radialGradient>
      <radialGradient
        id="paint118_radial_1219_6678"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(810 1025.5) rotate(-102.781) scale(592.172 1639.45)"
      >
        <stop offset="0.525786" stopColor="white" />
        <stop offset="1" stopColor="white" stopOpacity="0" />
      </radialGradient>
      <linearGradient
        id="paint119_linear_1219_6678"
        x1="887.5"
        y1="-2.49998"
        x2="886"
        y2="320"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="white" />
        <stop offset="1" stopColor="white" stopOpacity="0" />
      </linearGradient>
      <linearGradient
        id="paint120_linear_1219_6678"
        x1="1307"
        y1="889"
        x2="292.5"
        y2="23.5001"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.777821" stopColor="#48A0F9" />
        <stop offset="0.896175" stopColor="#FFAA8F" />
      </linearGradient>
      <radialGradient
        id="paint121_radial_1219_6678"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(704.692 407.692) rotate(75) scale(647.23)"
      >
        <stop offset="0.0572917" stopColor="white" />
        <stop offset="1" stopColor="white" stopOpacity="0" />
      </radialGradient>
      <radialGradient
        id="paint122_radial_1219_6678"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(810 1025.5) rotate(-102.781) scale(592.172 1639.45)"
      >
        <stop offset="0.525786" stopColor="white" />
        <stop offset="1" stopColor="white" stopOpacity="0" />
      </radialGradient>
      <linearGradient
        id="paint123_linear_1219_6678"
        x1="887.5"
        y1="-2.49998"
        x2="886"
        y2="320"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="white" />
        <stop offset="1" stopColor="white" stopOpacity="0" />
      </linearGradient>
      <linearGradient
        id="paint124_linear_1219_6678"
        x1="1307"
        y1="889"
        x2="292.5"
        y2="23.5001"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.777821" stopColor="#48A0F9" />
        <stop offset="0.896175" stopColor="#FFAA8F" />
      </linearGradient>
      <radialGradient
        id="paint125_radial_1219_6678"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(704.692 407.692) rotate(75) scale(647.23)"
      >
        <stop offset="0.0572917" stopColor="white" />
        <stop offset="1" stopColor="white" stopOpacity="0" />
      </radialGradient>
      <radialGradient
        id="paint126_radial_1219_6678"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(810 1025.5) rotate(-102.781) scale(592.172 1639.45)"
      >
        <stop offset="0.525786" stopColor="white" />
        <stop offset="1" stopColor="white" stopOpacity="0" />
      </radialGradient>
      <linearGradient
        id="paint127_linear_1219_6678"
        x1="887.5"
        y1="-2.49998"
        x2="886"
        y2="320"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="white" />
        <stop offset="1" stopColor="white" stopOpacity="0" />
      </linearGradient>
      <linearGradient
        id="paint128_linear_1219_6678"
        x1="1307"
        y1="889"
        x2="292.5"
        y2="23.5001"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.777821" stopColor="#48A0F9" />
        <stop offset="0.896175" stopColor="#FFAA8F" />
      </linearGradient>
      <radialGradient
        id="paint129_radial_1219_6678"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(704.692 407.692) rotate(75) scale(647.23)"
      >
        <stop offset="0.0572917" stopColor="white" />
        <stop offset="1" stopColor="white" stopOpacity="0" />
      </radialGradient>
      <radialGradient
        id="paint130_radial_1219_6678"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(810 1025.5) rotate(-102.781) scale(592.172 1639.45)"
      >
        <stop offset="0.525786" stopColor="white" />
        <stop offset="1" stopColor="white" stopOpacity="0" />
      </radialGradient>
      <linearGradient
        id="paint131_linear_1219_6678"
        x1="887.5"
        y1="-2.49998"
        x2="886"
        y2="320"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="white" />
        <stop offset="1" stopColor="white" stopOpacity="0" />
      </linearGradient>
    </defs>
  </svg>
);

export default BannerBg;
