const WeldBannerArrowDown = (props) => (
  <svg
    width="126"
    height="47"
    viewBox="0 0 126 47"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M125.556 10.0999C125.82 10.0176 125.966 9.73711 125.884 9.47355L124.541 5.17849C124.459 4.91492 124.179 4.76805 123.915 4.85044C123.652 4.93282 123.505 5.21327 123.587 5.47684L124.78 9.29467L120.963 10.4881C120.699 10.5705 120.552 10.8509 120.635 11.1145C120.717 11.378 120.997 11.5249 121.261 11.4425L125.556 10.0999ZM1.09624 46.7644C29.1211 10.2586 82.3212 -12.3772 125.175 10.0657L125.639 9.17979C82.2011 -13.569 28.5043 9.41992 0.303022 46.1554L1.09624 46.7644Z"
      fill="#0790F1"
    />
  </svg>
);

export default WeldBannerArrowDown;
