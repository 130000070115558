// core
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { Link as RouterLink } from "react-router-dom";
// store
import { getNews } from "../../store/modules/news/services";
import { selectNews } from "../../store/modules/news/selectors";

// utils

// components
import PageLoader from "../../components/atoms/PageLoader";

// styles
import "./index.scss";

import AppButton from "../../components/atoms/Button";
import ArrowSmDown from "../../components/icons/ArrowSmDown";

const News = () => {
  const { t } = useTranslation();
  // store
  const dispatch = useDispatch();
  const pageNews = useSelector(selectNews);
  const [page, setPage] = useState(1);
  const [news, setNews] = useState([]);

  useEffect(() => {
    dispatch(getNews({ per_page: 8, page: 1 }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (pageNews.data) {
      if (page > 1) {
        setNews((prevState) => {
          return [...prevState, ...pageNews.data];
        });
      } else {
        setNews(pageNews.data);
      }
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageNews.data]);

  const onloadMore = () => {
    let nextPage = page + 1;
    setPage(nextPage);

    dispatch(getNews({ per_page: 8, page: nextPage }));
  };
  // @ts-ignore
  return (
    <div className="news">
      <div className="app__wrapper">
        <div className="news__title">{t("news.title")}</div>
        {pageNews.isLoading && page === 1 ? (
          <PageLoader active={true} />
        ) : (
          <>
            {news.length > 0 ? (
              <>
                <div className="news__grid">
                  {news.map((item) => (
                    <RouterLink
                      to={`/article/${item.slug}`}
                      key={item.id}
                      className="news__card"
                    >
                      <div className="news__card-img">
                        <img src={item.image} />
                      </div>
                      <div className="news__card-title">{item.title}</div>
                      {item.short_description && (
                        <div
                          className="news__card-text"
                          dangerouslySetInnerHTML={{
                            __html: item.short_description,
                          }}
                        />
                      )}
                    </RouterLink>
                  ))}
                </div>
                {pageNews.meta.last_page > pageNews.meta.current_page && (
                  <div className="news__actions">
                    <AppButton
                      loading={pageNews.isLoading}
                      className="app-button--fill news__btn"
                      onClick={() => onloadMore()}
                    >
                      <ArrowSmDown />
                      {t("actions.loadMore")}
                    </AppButton>
                  </div>
                )}
              </>
            ) : (
              <div className="news__empty">{t("news.emptyText")}</div>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default News;
