const WeldCTABg = () => (
  <svg
    width="655"
    height="601"
    viewBox="0 0 655 601"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_2308_38472)">
      <g opacity="0.45">
        <path
          d="M253.552 380.002C249.584 380.002 246.181 378.975 243.672 376.868C239.101 373.033 238.357 366.191 241.632 358.098L277.131 270.358C281.992 258.341 294.82 246.034 308.326 240.428C315.994 237.249 322.985 236.661 328.018 238.79L390.475 265.153C394.749 266.955 397.206 270.432 397.593 275.204C398.494 286.394 387.81 301.955 372.741 311.395L274.786 372.773C267.184 377.538 259.694 379.995 253.559 379.995L253.552 380.002ZM321.198 239.788C317.655 239.788 313.567 240.704 309.197 242.52C296.213 247.911 283.891 259.704 279.238 271.206L243.739 358.947C240.813 366.176 241.312 371.924 245.132 375.133C250.745 379.838 261.913 378.163 273.579 370.852L371.535 309.474C387.944 299.193 396.044 284.235 395.337 275.383C395.024 271.452 393.089 268.712 389.604 267.238L327.147 240.875C325.427 240.145 323.425 239.788 321.206 239.788H321.198Z"
          fill="url(#paint0_linear_2308_38472)"
        />
        <path
          d="M253.552 380.002C249.584 380.002 246.181 378.975 243.672 376.868C239.101 373.033 238.357 366.191 241.632 358.098L277.131 270.358C281.992 258.341 294.82 246.034 308.326 240.428C315.994 237.249 322.985 236.661 328.018 238.79L390.475 265.153C394.749 266.955 397.206 270.432 397.593 275.204C398.494 286.394 387.81 301.955 372.741 311.395L274.786 372.773C267.184 377.538 259.694 379.995 253.559 379.995L253.552 380.002ZM321.198 239.788C317.655 239.788 313.567 240.704 309.197 242.52C296.213 247.911 283.891 259.704 279.238 271.206L243.739 358.947C240.813 366.176 241.312 371.924 245.132 375.133C250.745 379.838 261.913 378.163 273.579 370.852L371.535 309.474C387.944 299.193 396.044 284.235 395.337 275.383C395.024 271.452 393.089 268.712 389.604 267.238L327.147 240.875C325.427 240.145 323.425 239.788 321.206 239.788H321.198Z"
          fill="url(#paint1_radial_2308_38472)"
        />
        <path
          d="M253.552 380.002C249.584 380.002 246.181 378.975 243.672 376.868C239.101 373.033 238.357 366.191 241.632 358.098L277.131 270.358C281.992 258.341 294.82 246.034 308.326 240.428C315.994 237.249 322.985 236.661 328.018 238.79L390.475 265.153C394.749 266.955 397.206 270.432 397.593 275.204C398.494 286.394 387.81 301.955 372.741 311.395L274.786 372.773C267.184 377.538 259.694 379.995 253.559 379.995L253.552 380.002ZM321.198 239.788C317.655 239.788 313.567 240.704 309.197 242.52C296.213 247.911 283.891 259.704 279.238 271.206L243.739 358.947C240.813 366.176 241.312 371.924 245.132 375.133C250.745 379.838 261.913 378.163 273.579 370.852L371.535 309.474C387.944 299.193 396.044 284.235 395.337 275.383C395.024 271.452 393.089 268.712 389.604 267.238L327.147 240.875C325.427 240.145 323.425 239.788 321.206 239.788H321.198Z"
          fill="url(#paint2_radial_2308_38472)"
        />
        <path
          d="M253.552 380.002C249.584 380.002 246.181 378.975 243.672 376.868C239.101 373.033 238.357 366.191 241.632 358.098L277.131 270.358C281.992 258.341 294.82 246.034 308.326 240.428C315.994 237.249 322.985 236.661 328.018 238.79L390.475 265.153C394.749 266.955 397.206 270.432 397.593 275.204C398.494 286.394 387.81 301.955 372.741 311.395L274.786 372.773C267.184 377.538 259.694 379.995 253.559 379.995L253.552 380.002ZM321.198 239.788C317.655 239.788 313.567 240.704 309.197 242.52C296.213 247.911 283.891 259.704 279.238 271.206L243.739 358.947C240.813 366.176 241.312 371.924 245.132 375.133C250.745 379.838 261.913 378.163 273.579 370.852L371.535 309.474C387.944 299.193 396.044 284.235 395.337 275.383C395.024 271.452 393.089 268.712 389.604 267.238L327.147 240.875C325.427 240.145 323.425 239.788 321.206 239.788H321.198Z"
          fill="url(#paint3_linear_2308_38472)"
        />
      </g>
      <g opacity="0.45">
        <path
          d="M251.354 390.722C247.616 390.722 244.348 389.814 241.787 387.938C236.687 384.215 235.414 377.41 238.206 368.796L269.006 273.528C276.124 251.506 305.398 230.704 322.09 235.804L393.779 257.715C398.715 259.226 401.768 262.703 402.594 267.773C404.5 279.403 394.174 296.593 378.576 307.754L276.094 381.103C267.309 387.394 258.486 390.707 251.361 390.707L251.354 390.722ZM316.514 237.293C300.655 237.293 277.174 255.638 271.165 274.228L240.365 369.496C237.901 377.112 238.883 383.009 243.127 386.114C249.664 390.893 262.38 388.146 274.769 379.279L377.251 305.93C394.278 293.742 401.857 277.377 400.353 268.16C399.661 263.939 397.226 261.162 393.109 259.904L321.42 237.993C319.894 237.524 318.248 237.308 316.506 237.308L316.514 237.293Z"
          fill="url(#paint4_linear_2308_38472)"
        />
        <path
          d="M251.354 390.722C247.616 390.722 244.348 389.814 241.787 387.938C236.687 384.215 235.414 377.41 238.206 368.796L269.006 273.528C276.124 251.506 305.398 230.704 322.09 235.804L393.779 257.715C398.715 259.226 401.768 262.703 402.594 267.773C404.5 279.403 394.174 296.593 378.576 307.754L276.094 381.103C267.309 387.394 258.486 390.707 251.361 390.707L251.354 390.722ZM316.514 237.293C300.655 237.293 277.174 255.638 271.165 274.228L240.365 369.496C237.901 377.112 238.883 383.009 243.127 386.114C249.664 390.893 262.38 388.146 274.769 379.279L377.251 305.93C394.278 293.742 401.857 277.377 400.353 268.16C399.661 263.939 397.226 261.162 393.109 259.904L321.42 237.993C319.894 237.524 318.248 237.308 316.506 237.308L316.514 237.293Z"
          fill="url(#paint5_radial_2308_38472)"
        />
        <path
          d="M251.354 390.722C247.616 390.722 244.348 389.814 241.787 387.938C236.687 384.215 235.414 377.41 238.206 368.796L269.006 273.528C276.124 251.506 305.398 230.704 322.09 235.804L393.779 257.715C398.715 259.226 401.768 262.703 402.594 267.773C404.5 279.403 394.174 296.593 378.576 307.754L276.094 381.103C267.309 387.394 258.486 390.707 251.361 390.707L251.354 390.722ZM316.514 237.293C300.655 237.293 277.174 255.638 271.165 274.228L240.365 369.496C237.901 377.112 238.883 383.009 243.127 386.114C249.664 390.893 262.38 388.146 274.769 379.279L377.251 305.93C394.278 293.742 401.857 277.377 400.353 268.16C399.661 263.939 397.226 261.162 393.109 259.904L321.42 237.993C319.894 237.524 318.248 237.308 316.506 237.308L316.514 237.293Z"
          fill="url(#paint6_radial_2308_38472)"
        />
        <path
          d="M251.354 390.722C247.616 390.722 244.348 389.814 241.787 387.938C236.687 384.215 235.414 377.41 238.206 368.796L269.006 273.528C276.124 251.506 305.398 230.704 322.09 235.804L393.779 257.715C398.715 259.226 401.768 262.703 402.594 267.773C404.5 279.403 394.174 296.593 378.576 307.754L276.094 381.103C267.309 387.394 258.486 390.707 251.361 390.707L251.354 390.722ZM316.514 237.293C300.655 237.293 277.174 255.638 271.165 274.228L240.365 369.496C237.901 377.112 238.883 383.009 243.127 386.114C249.664 390.893 262.38 388.146 274.769 379.279L377.251 305.93C394.278 293.742 401.857 277.377 400.353 268.16C399.661 263.939 397.226 261.162 393.109 259.904L321.42 237.993C319.894 237.524 318.248 237.308 316.506 237.308L316.514 237.293Z"
          fill="url(#paint7_linear_2308_38472)"
        />
      </g>
      <g opacity="0.45">
        <path
          d="M249.821 402.106C246.352 402.106 243.262 401.34 240.746 399.754C235.154 396.225 233.278 389.331 235.452 380.337L260.319 277.617C266.059 253.904 296.353 229.447 315.264 233.244L396.736 249.645C402.424 250.792 406.132 254.254 407.464 259.659C410.472 271.869 400.89 290.273 384.69 303.429L278.358 389.748C268.508 397.744 258.1 402.099 249.836 402.099L249.821 402.106ZM311.139 235.12C293.42 235.12 267.548 257.351 262.515 278.153L237.648 380.873C235.72 388.847 237.246 394.87 241.952 397.84C248.347 401.876 261.637 400.402 276.914 387.998L383.245 301.679C400.689 287.519 407.598 269.747 405.246 260.21C404.136 255.713 401.032 252.832 396.267 251.871L314.795 235.47C313.618 235.231 312.397 235.12 311.124 235.12H311.139Z"
          fill="url(#paint8_linear_2308_38472)"
        />
        <path
          d="M249.821 402.106C246.352 402.106 243.262 401.34 240.746 399.754C235.154 396.225 233.278 389.331 235.452 380.337L260.319 277.617C266.059 253.904 296.353 229.447 315.264 233.244L396.736 249.645C402.424 250.792 406.132 254.254 407.464 259.659C410.472 271.869 400.89 290.273 384.69 303.429L278.358 389.748C268.508 397.744 258.1 402.099 249.836 402.099L249.821 402.106ZM311.139 235.12C293.42 235.12 267.548 257.351 262.515 278.153L237.648 380.873C235.72 388.847 237.246 394.87 241.952 397.84C248.347 401.876 261.637 400.402 276.914 387.998L383.245 301.679C400.689 287.519 407.598 269.747 405.246 260.21C404.136 255.713 401.032 252.832 396.267 251.871L314.795 235.47C313.618 235.231 312.397 235.12 311.124 235.12H311.139Z"
          fill="url(#paint9_radial_2308_38472)"
        />
        <path
          d="M249.821 402.106C246.352 402.106 243.262 401.34 240.746 399.754C235.154 396.225 233.278 389.331 235.452 380.337L260.319 277.617C266.059 253.904 296.353 229.447 315.264 233.244L396.736 249.645C402.424 250.792 406.132 254.254 407.464 259.659C410.472 271.869 400.89 290.273 384.69 303.429L278.358 389.748C268.508 397.744 258.1 402.099 249.836 402.099L249.821 402.106ZM311.139 235.12C293.42 235.12 267.548 257.351 262.515 278.153L237.648 380.873C235.72 388.847 237.246 394.87 241.952 397.84C248.347 401.876 261.637 400.402 276.914 387.998L383.245 301.679C400.689 287.519 407.598 269.747 405.246 260.21C404.136 255.713 401.032 252.832 396.267 251.871L314.795 235.47C313.618 235.231 312.397 235.12 311.124 235.12H311.139Z"
          fill="url(#paint10_radial_2308_38472)"
        />
        <path
          d="M249.821 402.106C246.352 402.106 243.262 401.34 240.746 399.754C235.154 396.225 233.278 389.331 235.452 380.337L260.319 277.617C266.059 253.904 296.353 229.447 315.264 233.244L396.736 249.645C402.424 250.792 406.132 254.254 407.464 259.659C410.472 271.869 400.89 290.273 384.69 303.429L278.358 389.748C268.508 397.744 258.1 402.099 249.836 402.099L249.821 402.106ZM311.139 235.12C293.42 235.12 267.548 257.351 262.515 278.153L237.648 380.873C235.72 388.847 237.246 394.87 241.952 397.84C248.347 401.876 261.637 400.402 276.914 387.998L383.245 301.679C400.689 287.519 407.598 269.747 405.246 260.21C404.136 255.713 401.032 252.832 396.267 251.871L314.795 235.47C313.618 235.231 312.397 235.12 311.124 235.12H311.139Z"
          fill="url(#paint11_linear_2308_38472)"
        />
      </g>
      <g opacity="0.45">
        <path
          d="M249.329 414.25C246.113 414.25 243.194 413.602 240.693 412.262C234.573 408.971 232.019 402.018 233.508 392.689L251.079 282.687C255.129 257.329 286.212 228.926 307.453 231.174L399.222 240.898C405.751 241.59 410.211 245.015 412.117 250.8C416.249 263.337 407.374 283.327 391.01 298.329L281.671 398.6C270.726 408.636 258.777 414.242 249.329 414.242V414.25ZM305.048 233.318C285.237 233.318 257.012 260.002 253.327 283.044L235.757 393.046C234.431 401.348 236.568 407.46 241.772 410.259C249.247 414.28 264.011 411.719 280.137 396.933L389.476 296.662C407.114 280.483 413.196 261.327 409.965 251.514C408.357 246.638 404.56 243.749 398.984 243.161L307.215 233.43C306.5 233.356 305.778 233.318 305.041 233.318H305.048Z"
          fill="url(#paint12_linear_2308_38472)"
        />
        <path
          d="M249.329 414.25C246.113 414.25 243.194 413.602 240.693 412.262C234.573 408.971 232.019 402.018 233.508 392.689L251.079 282.687C255.129 257.329 286.212 228.926 307.453 231.174L399.222 240.898C405.751 241.59 410.211 245.015 412.117 250.8C416.249 263.337 407.374 283.327 391.01 298.329L281.671 398.6C270.726 408.636 258.777 414.242 249.329 414.242V414.25ZM305.048 233.318C285.237 233.318 257.012 260.002 253.327 283.044L235.757 393.046C234.431 401.348 236.568 407.46 241.772 410.259C249.247 414.28 264.011 411.719 280.137 396.933L389.476 296.662C407.114 280.483 413.196 261.327 409.965 251.514C408.357 246.638 404.56 243.749 398.984 243.161L307.215 233.43C306.5 233.356 305.778 233.318 305.041 233.318H305.048Z"
          fill="url(#paint13_radial_2308_38472)"
        />
        <path
          d="M249.329 414.25C246.113 414.25 243.194 413.602 240.693 412.262C234.573 408.971 232.019 402.018 233.508 392.689L251.079 282.687C255.129 257.329 286.212 228.926 307.453 231.174L399.222 240.898C405.751 241.59 410.211 245.015 412.117 250.8C416.249 263.337 407.374 283.327 391.01 298.329L281.671 398.6C270.726 408.636 258.777 414.242 249.329 414.242V414.25ZM305.048 233.318C285.237 233.318 257.012 260.002 253.327 283.044L235.757 393.046C234.431 401.348 236.568 407.46 241.772 410.259C249.247 414.28 264.011 411.719 280.137 396.933L389.476 296.662C407.114 280.483 413.196 261.327 409.965 251.514C408.357 246.638 404.56 243.749 398.984 243.161L307.215 233.43C306.5 233.356 305.778 233.318 305.041 233.318H305.048Z"
          fill="url(#paint14_radial_2308_38472)"
        />
        <path
          d="M249.329 414.25C246.113 414.25 243.194 413.602 240.693 412.262C234.573 408.971 232.019 402.018 233.508 392.689L251.079 282.687C255.129 257.329 286.212 228.926 307.453 231.174L399.222 240.898C405.751 241.59 410.211 245.015 412.117 250.8C416.249 263.337 407.374 283.327 391.01 298.329L281.671 398.6C270.726 408.636 258.777 414.242 249.329 414.242V414.25ZM305.048 233.318C285.237 233.318 257.012 260.002 253.327 283.044L235.757 393.046C234.431 401.348 236.568 407.46 241.772 410.259C249.247 414.28 264.011 411.719 280.137 396.933L389.476 296.662C407.114 280.483 413.196 261.327 409.965 251.514C408.357 246.638 404.56 243.749 398.984 243.161L307.215 233.43C306.5 233.356 305.778 233.318 305.041 233.318H305.048Z"
          fill="url(#paint15_linear_2308_38472)"
        />
      </g>
      <g opacity="0.45">
        <path
          d="M249.933 427.024C246.999 427.024 244.274 426.496 241.847 425.409C235.139 422.401 231.848 415.44 232.571 405.813L241.371 288.857C243.396 261.921 274.911 229.229 298.654 229.728L401.173 231.508C408.64 231.634 413.949 234.985 416.51 241.186C421.811 253.999 413.83 275.538 397.525 292.409L286.205 407.585C274.256 419.951 260.683 427.024 249.925 427.024H249.933ZM298.207 231.992C276.005 231.992 245.533 263.738 243.627 289.021L234.826 405.977C234.179 414.583 237 420.748 242.77 423.331C253.06 427.947 270.25 420.822 284.575 405.999L395.894 290.823C413.688 272.411 418.676 252.347 414.41 242.043C412.214 236.742 407.62 233.875 401.128 233.764L298.609 231.984C298.475 231.984 298.341 231.984 298.207 231.984V231.992Z"
          fill="url(#paint16_linear_2308_38472)"
        />
        <path
          d="M249.933 427.024C246.999 427.024 244.274 426.496 241.847 425.409C235.139 422.401 231.848 415.44 232.571 405.813L241.371 288.857C243.396 261.921 274.911 229.229 298.654 229.728L401.173 231.508C408.64 231.634 413.949 234.985 416.51 241.186C421.811 253.999 413.83 275.538 397.525 292.409L286.205 407.585C274.256 419.951 260.683 427.024 249.925 427.024H249.933ZM298.207 231.992C276.005 231.992 245.533 263.738 243.627 289.021L234.826 405.977C234.179 414.583 237 420.748 242.77 423.331C253.06 427.947 270.25 420.822 284.575 405.999L395.894 290.823C413.688 272.411 418.676 252.347 414.41 242.043C412.214 236.742 407.62 233.875 401.128 233.764L298.609 231.984C298.475 231.984 298.341 231.984 298.207 231.984V231.992Z"
          fill="url(#paint17_radial_2308_38472)"
        />
        <path
          d="M249.933 427.024C246.999 427.024 244.274 426.496 241.847 425.409C235.139 422.401 231.848 415.44 232.571 405.813L241.371 288.857C243.396 261.921 274.911 229.229 298.654 229.728L401.173 231.508C408.64 231.634 413.949 234.985 416.51 241.186C421.811 253.999 413.83 275.538 397.525 292.409L286.205 407.585C274.256 419.951 260.683 427.024 249.925 427.024H249.933ZM298.207 231.992C276.005 231.992 245.533 263.738 243.627 289.021L234.826 405.977C234.179 414.583 237 420.748 242.77 423.331C253.06 427.947 270.25 420.822 284.575 405.999L395.894 290.823C413.688 272.411 418.676 252.347 414.41 242.043C412.214 236.742 407.62 233.875 401.128 233.764L298.609 231.984C298.475 231.984 298.341 231.984 298.207 231.984V231.992Z"
          fill="url(#paint18_radial_2308_38472)"
        />
        <path
          d="M249.933 427.024C246.999 427.024 244.274 426.496 241.847 425.409C235.139 422.401 231.848 415.44 232.571 405.813L241.371 288.857C243.396 261.921 274.911 229.229 298.654 229.728L401.173 231.508C408.64 231.634 413.949 234.985 416.51 241.186C421.811 253.999 413.83 275.538 397.525 292.409L286.205 407.585C274.256 419.951 260.683 427.024 249.925 427.024H249.933ZM298.207 231.992C276.005 231.992 245.533 263.738 243.627 289.021L234.826 405.977C234.179 414.583 237 420.748 242.77 423.331C253.06 427.947 270.25 420.822 284.575 405.999L395.894 290.823C413.688 272.411 418.676 252.347 414.41 242.043C412.214 236.742 407.62 233.875 401.128 233.764L298.609 231.984C298.475 231.984 298.341 231.984 298.207 231.984V231.992Z"
          fill="url(#paint19_linear_2308_38472)"
        />
      </g>
      <g opacity="0.45">
        <path
          d="M251.764 440.375C249.151 440.375 246.679 439.973 244.394 439.146C237.045 436.481 232.921 429.565 232.794 419.67L231.208 296.215C230.843 267.804 262.582 230.757 288.774 229.015L402.424 221.481C410.807 220.922 417.24 224.228 420.538 230.795C427.171 244.032 420.441 266.568 404.166 285.598L292.102 416.588C279.281 431.575 264.004 440.367 251.764 440.367V440.375ZM404.255 223.684C403.704 223.684 403.146 223.707 402.573 223.744L288.923 231.278C264.22 232.916 233.129 269.316 233.479 296.185L235.065 419.64C235.184 428.656 238.676 434.664 245.168 437.01C257.073 441.32 275.663 432.319 290.375 415.114L402.439 284.124C419.95 263.657 423.776 242.312 418.505 231.807C415.832 226.469 410.926 223.684 404.255 223.684Z"
          fill="url(#paint20_linear_2308_38472)"
        />
        <path
          d="M251.764 440.375C249.151 440.375 246.679 439.973 244.394 439.146C237.045 436.481 232.921 429.565 232.794 419.67L231.208 296.215C230.843 267.804 262.582 230.757 288.774 229.015L402.424 221.481C410.807 220.922 417.24 224.228 420.538 230.795C427.171 244.032 420.441 266.568 404.166 285.598L292.102 416.588C279.281 431.575 264.004 440.367 251.764 440.367V440.375ZM404.255 223.684C403.704 223.684 403.146 223.707 402.573 223.744L288.923 231.278C264.22 232.916 233.129 269.316 233.479 296.185L235.065 419.64C235.184 428.656 238.676 434.664 245.168 437.01C257.073 441.32 275.663 432.319 290.375 415.114L402.439 284.124C419.95 263.657 423.776 242.312 418.505 231.807C415.832 226.469 410.926 223.684 404.255 223.684Z"
          fill="url(#paint21_radial_2308_38472)"
        />
        <path
          d="M251.764 440.375C249.151 440.375 246.679 439.973 244.394 439.146C237.045 436.481 232.921 429.565 232.794 419.67L231.208 296.215C230.843 267.804 262.582 230.757 288.774 229.015L402.424 221.481C410.807 220.922 417.24 224.228 420.538 230.795C427.171 244.032 420.441 266.568 404.166 285.598L292.102 416.588C279.281 431.575 264.004 440.367 251.764 440.367V440.375ZM404.255 223.684C403.704 223.684 403.146 223.707 402.573 223.744L288.923 231.278C264.22 232.916 233.129 269.316 233.479 296.185L235.065 419.64C235.184 428.656 238.676 434.664 245.168 437.01C257.073 441.32 275.663 432.319 290.375 415.114L402.439 284.124C419.95 263.657 423.776 242.312 418.505 231.807C415.832 226.469 410.926 223.684 404.255 223.684Z"
          fill="url(#paint22_radial_2308_38472)"
        />
        <path
          d="M251.764 440.375C249.151 440.375 246.679 439.973 244.394 439.146C237.045 436.481 232.921 429.565 232.794 419.67L231.208 296.215C230.843 267.804 262.582 230.757 288.774 229.015L402.424 221.481C410.807 220.922 417.24 224.228 420.538 230.795C427.171 244.032 420.441 266.568 404.166 285.598L292.102 416.588C279.281 431.575 264.004 440.367 251.764 440.367V440.375ZM404.255 223.684C403.704 223.684 403.146 223.707 402.573 223.744L288.923 231.278C264.22 232.916 233.129 269.316 233.479 296.185L235.065 419.64C235.184 428.656 238.676 434.664 245.168 437.01C257.073 441.32 275.663 432.319 290.375 415.114L402.439 284.124C419.95 263.657 423.776 242.312 418.505 231.807C415.832 226.469 410.926 223.684 404.255 223.684Z"
          fill="url(#paint23_linear_2308_38472)"
        />
      </g>
      <g opacity="0.45">
        <path
          d="M254.742 454.162C252.59 454.162 250.528 453.902 248.577 453.358C240.507 451.117 235.466 444.305 234.394 434.172L220.68 304.858C217.524 275.122 249.016 233.384 277.792 229.17L402.855 210.84C412.37 209.448 419.919 212.56 424.096 219.611C432.04 233.012 426.59 256.963 410.836 277.847L299.487 425.491C286.138 443.195 268.649 454.155 254.742 454.155V454.162ZM403.175 213.089L278.112 231.419C250.885 235.409 219.943 276.462 222.929 304.627L236.643 433.941C237.625 443.218 241.958 449.181 249.18 451.177C262.864 454.974 282.802 443.851 297.67 424.136L409.02 276.492C424.23 256.323 429.628 233.407 422.138 220.772C418.408 214.474 411.841 211.816 403.175 213.089Z"
          fill="url(#paint24_linear_2308_38472)"
        />
        <path
          d="M254.742 454.162C252.59 454.162 250.528 453.902 248.577 453.358C240.507 451.117 235.466 444.305 234.394 434.172L220.68 304.858C217.524 275.122 249.016 233.384 277.792 229.17L402.855 210.84C412.37 209.448 419.919 212.56 424.096 219.611C432.04 233.012 426.59 256.963 410.836 277.847L299.487 425.491C286.138 443.195 268.649 454.155 254.742 454.155V454.162ZM403.175 213.089L278.112 231.419C250.885 235.409 219.943 276.462 222.929 304.627L236.643 433.941C237.625 443.218 241.958 449.181 249.18 451.177C262.864 454.974 282.802 443.851 297.67 424.136L409.02 276.492C424.23 256.323 429.628 233.407 422.138 220.772C418.408 214.474 411.841 211.816 403.175 213.089Z"
          fill="url(#paint25_radial_2308_38472)"
        />
        <path
          d="M254.742 454.162C252.59 454.162 250.528 453.902 248.577 453.358C240.507 451.117 235.466 444.305 234.394 434.172L220.68 304.858C217.524 275.122 249.016 233.384 277.792 229.17L402.855 210.84C412.37 209.448 419.919 212.56 424.096 219.611C432.04 233.012 426.59 256.963 410.836 277.847L299.487 425.491C286.138 443.195 268.649 454.155 254.742 454.155V454.162ZM403.175 213.089L278.112 231.419C250.885 235.409 219.943 276.462 222.929 304.627L236.643 433.941C237.625 443.218 241.958 449.181 249.18 451.177C262.864 454.974 282.802 443.851 297.67 424.136L409.02 276.492C424.23 256.323 429.628 233.407 422.138 220.772C418.408 214.474 411.841 211.816 403.175 213.089Z"
          fill="url(#paint26_radial_2308_38472)"
        />
        <path
          d="M254.742 454.162C252.59 454.162 250.528 453.902 248.577 453.358C240.507 451.117 235.466 444.305 234.394 434.172L220.68 304.858C217.524 275.122 249.016 233.384 277.792 229.17L402.855 210.84C412.37 209.448 419.919 212.56 424.096 219.611C432.04 233.012 426.59 256.963 410.836 277.847L299.487 425.491C286.138 443.195 268.649 454.155 254.742 454.155V454.162ZM403.175 213.089L278.112 231.419C250.885 235.409 219.943 276.462 222.929 304.627L236.643 433.941C237.625 443.218 241.958 449.181 249.18 451.177C262.864 454.974 282.802 443.851 297.67 424.136L409.02 276.492C424.23 256.323 429.628 233.407 422.138 220.772C418.408 214.474 411.841 211.816 403.175 213.089Z"
          fill="url(#paint27_linear_2308_38472)"
        />
      </g>
      <g opacity="0.45">
        <path
          d="M259.493 468.383C257.833 468.383 256.21 468.234 254.646 467.936C245.757 466.223 239.689 459.59 237.552 449.249L209.857 314.909C203.491 284.034 234.262 237.405 265.658 230.354L402.313 199.658C413.064 197.246 421.857 200.082 427.083 207.654C436.404 221.16 432.451 246.443 417.471 269.129L308.512 434.165C294.739 455.026 275.284 468.383 259.493 468.383ZM402.559 200.76L402.805 201.869L266.149 232.566C236.361 239.259 206.037 285.173 212.068 314.447L239.764 448.787C241.729 458.309 247.015 464.161 255.063 465.71C270.683 468.71 291.88 455.227 306.613 432.914L415.573 267.878C430.054 245.937 434.022 221.703 425.207 208.942C420.472 202.085 412.721 199.643 402.797 201.869L402.551 200.76H402.559Z"
          fill="url(#paint28_linear_2308_38472)"
        />
        <path
          d="M259.493 468.383C257.833 468.383 256.21 468.234 254.646 467.936C245.757 466.223 239.689 459.59 237.552 449.249L209.857 314.909C203.491 284.034 234.262 237.405 265.658 230.354L402.313 199.658C413.064 197.246 421.857 200.082 427.083 207.654C436.404 221.16 432.451 246.443 417.471 269.129L308.512 434.165C294.739 455.026 275.284 468.383 259.493 468.383ZM402.559 200.76L402.805 201.869L266.149 232.566C236.361 239.259 206.037 285.173 212.068 314.447L239.764 448.787C241.729 458.309 247.015 464.161 255.063 465.71C270.683 468.71 291.88 455.227 306.613 432.914L415.573 267.878C430.054 245.937 434.022 221.703 425.207 208.942C420.472 202.085 412.721 199.643 402.797 201.869L402.551 200.76H402.559Z"
          fill="url(#paint29_radial_2308_38472)"
        />
        <path
          d="M259.493 468.383C257.833 468.383 256.21 468.234 254.646 467.936C245.757 466.223 239.689 459.59 237.552 449.249L209.857 314.909C203.491 284.034 234.262 237.405 265.658 230.354L402.313 199.658C413.064 197.246 421.857 200.082 427.083 207.654C436.404 221.16 432.451 246.443 417.471 269.129L308.512 434.165C294.739 455.026 275.284 468.383 259.493 468.383ZM402.559 200.76L402.805 201.869L266.149 232.566C236.361 239.259 206.037 285.173 212.068 314.447L239.764 448.787C241.729 458.309 247.015 464.161 255.063 465.71C270.683 468.71 291.88 455.227 306.613 432.914L415.573 267.878C430.054 245.937 434.022 221.703 425.207 208.942C420.472 202.085 412.721 199.643 402.797 201.869L402.551 200.76H402.559Z"
          fill="url(#paint30_radial_2308_38472)"
        />
        <path
          d="M259.493 468.383C257.833 468.383 256.21 468.234 254.646 467.936C245.757 466.223 239.689 459.59 237.552 449.249L209.857 314.909C203.491 284.034 234.262 237.405 265.658 230.354L402.313 199.658C413.064 197.246 421.857 200.082 427.083 207.654C436.404 221.16 432.451 246.443 417.471 269.129L308.512 434.165C294.739 455.026 275.284 468.383 259.493 468.383ZM402.559 200.76L402.805 201.869L266.149 232.566C236.361 239.259 206.037 285.173 212.068 314.447L239.764 448.787C241.729 458.309 247.015 464.161 255.063 465.71C270.683 468.71 291.88 455.227 306.613 432.914L415.573 267.878C430.054 245.937 434.022 221.703 425.207 208.942C420.472 202.085 412.721 199.643 402.797 201.869L402.551 200.76H402.559Z"
          fill="url(#paint31_linear_2308_38472)"
        />
      </g>
      <g opacity="0.45">
        <path
          d="M265.954 482.855C264.919 482.855 263.899 482.803 262.894 482.691C253.074 481.641 245.837 475.268 242.517 464.748L198.851 326.418C194.399 312.302 197.533 293.429 207.465 274.637C218.476 253.791 235.682 237.717 252.359 232.691L400.651 187.968C412.727 184.328 422.935 186.785 429.389 194.9C440.334 208.651 438.167 234.56 423.984 259.367L319.365 442.428C305.473 466.729 283.956 482.862 265.961 482.87L265.954 482.855ZM410.955 188.594C407.933 188.594 404.701 189.1 401.299 190.127L253.007 234.85C236.888 239.712 220.204 255.362 209.468 275.687C199.96 293.682 196.796 312.391 201.01 325.726L244.676 464.056C247.751 473.802 254.131 479.467 263.132 480.428C280.829 482.319 303.128 466.223 317.385 441.281L422.004 258.221C435.509 234.582 437.817 209.12 427.603 196.285C423.552 191.192 417.812 188.579 410.955 188.579V188.594Z"
          fill="url(#paint32_linear_2308_38472)"
        />
        <path
          d="M265.954 482.855C264.919 482.855 263.899 482.803 262.894 482.691C253.074 481.641 245.837 475.268 242.517 464.748L198.851 326.418C194.399 312.302 197.533 293.429 207.465 274.637C218.476 253.791 235.682 237.717 252.359 232.691L400.651 187.968C412.727 184.328 422.935 186.785 429.389 194.9C440.334 208.651 438.167 234.56 423.984 259.367L319.365 442.428C305.473 466.729 283.956 482.862 265.961 482.87L265.954 482.855ZM410.955 188.594C407.933 188.594 404.701 189.1 401.299 190.127L253.007 234.85C236.888 239.712 220.204 255.362 209.468 275.687C199.96 293.682 196.796 312.391 201.01 325.726L244.676 464.056C247.751 473.802 254.131 479.467 263.132 480.428C280.829 482.319 303.128 466.223 317.385 441.281L422.004 258.221C435.509 234.582 437.817 209.12 427.603 196.285C423.552 191.192 417.812 188.579 410.955 188.579V188.594Z"
          fill="url(#paint33_radial_2308_38472)"
        />
        <path
          d="M265.954 482.855C264.919 482.855 263.899 482.803 262.894 482.691C253.074 481.641 245.837 475.268 242.517 464.748L198.851 326.418C194.399 312.302 197.533 293.429 207.465 274.637C218.476 253.791 235.682 237.717 252.359 232.691L400.651 187.968C412.727 184.328 422.935 186.785 429.389 194.9C440.334 208.651 438.167 234.56 423.984 259.367L319.365 442.428C305.473 466.729 283.956 482.862 265.961 482.87L265.954 482.855ZM410.955 188.594C407.933 188.594 404.701 189.1 401.299 190.127L253.007 234.85C236.888 239.712 220.204 255.362 209.468 275.687C199.96 293.682 196.796 312.391 201.01 325.726L244.676 464.056C247.751 473.802 254.131 479.467 263.132 480.428C280.829 482.319 303.128 466.223 317.385 441.281L422.004 258.221C435.509 234.582 437.817 209.12 427.603 196.285C423.552 191.192 417.812 188.579 410.955 188.579V188.594Z"
          fill="url(#paint34_radial_2308_38472)"
        />
        <path
          d="M265.954 482.855C264.919 482.855 263.899 482.803 262.894 482.691C253.074 481.641 245.837 475.268 242.517 464.748L198.851 326.418C194.399 312.302 197.533 293.429 207.465 274.637C218.476 253.791 235.682 237.717 252.359 232.691L400.651 187.968C412.727 184.328 422.935 186.785 429.389 194.9C440.334 208.651 438.167 234.56 423.984 259.367L319.365 442.428C305.473 466.729 283.956 482.862 265.961 482.87L265.954 482.855ZM410.955 188.594C407.933 188.594 404.701 189.1 401.299 190.127L253.007 234.85C236.888 239.712 220.204 255.362 209.468 275.687C199.96 293.682 196.796 312.391 201.01 325.726L244.676 464.056C247.751 473.802 254.131 479.467 263.132 480.428C280.829 482.319 303.128 466.223 317.385 441.281L422.004 258.221C435.509 234.582 437.817 209.12 427.603 196.285C423.552 191.192 417.812 188.579 410.955 188.579V188.594Z"
          fill="url(#paint35_linear_2308_38472)"
        />
      </g>
      <g opacity="0.45">
        <path
          d="M274.286 497.449C274.07 497.449 273.854 497.449 273.631 497.449C262.746 497.233 254.184 491.232 249.516 480.556L187.781 339.5C181.728 325.675 183.053 306.34 191.422 286.454C201.458 262.6 219.251 243.406 237.872 236.355L397.681 175.849C411.165 170.742 422.943 172.7 430.849 181.358C443.365 195.065 443.112 222.053 430.232 248.528L332.157 450.083C318.532 478.084 294.827 497.449 274.286 497.449ZM398.076 176.914L398.478 177.971L238.668 238.477C220.614 245.312 203.304 264.036 193.506 287.332C185.384 306.652 184.051 325.332 189.851 338.592L251.586 479.647C255.941 489.609 263.58 494.977 273.668 495.178C293.532 495.543 316.708 476.625 330.102 449.093L428.177 247.538C440.655 221.897 441.049 195.914 429.159 182.892C421.923 174.97 411.023 173.221 398.471 177.971L398.069 176.914H398.076Z"
          fill="url(#paint36_linear_2308_38472)"
        />
        <path
          d="M274.286 497.449C274.07 497.449 273.854 497.449 273.631 497.449C262.746 497.233 254.184 491.232 249.516 480.556L187.781 339.5C181.728 325.675 183.053 306.34 191.422 286.454C201.458 262.6 219.251 243.406 237.872 236.355L397.681 175.849C411.165 170.742 422.943 172.7 430.849 181.358C443.365 195.065 443.112 222.053 430.232 248.528L332.157 450.083C318.532 478.084 294.827 497.449 274.286 497.449ZM398.076 176.914L398.478 177.971L238.668 238.477C220.614 245.312 203.304 264.036 193.506 287.332C185.384 306.652 184.051 325.332 189.851 338.592L251.586 479.647C255.941 489.609 263.58 494.977 273.668 495.178C293.532 495.543 316.708 476.625 330.102 449.093L428.177 247.538C440.655 221.897 441.049 195.914 429.159 182.892C421.923 174.97 411.023 173.221 398.471 177.971L398.069 176.914H398.076Z"
          fill="url(#paint37_radial_2308_38472)"
        />
        <path
          d="M274.286 497.449C274.07 497.449 273.854 497.449 273.631 497.449C262.746 497.233 254.184 491.232 249.516 480.556L187.781 339.5C181.728 325.675 183.053 306.34 191.422 286.454C201.458 262.6 219.251 243.406 237.872 236.355L397.681 175.849C411.165 170.742 422.943 172.7 430.849 181.358C443.365 195.065 443.112 222.053 430.232 248.528L332.157 450.083C318.532 478.084 294.827 497.449 274.286 497.449ZM398.076 176.914L398.478 177.971L238.668 238.477C220.614 245.312 203.304 264.036 193.506 287.332C185.384 306.652 184.051 325.332 189.851 338.592L251.586 479.647C255.941 489.609 263.58 494.977 273.668 495.178C293.532 495.543 316.708 476.625 330.102 449.093L428.177 247.538C440.655 221.897 441.049 195.914 429.159 182.892C421.923 174.97 411.023 173.221 398.471 177.971L398.069 176.914H398.076Z"
          fill="url(#paint38_radial_2308_38472)"
        />
        <path
          d="M274.286 497.449C274.07 497.449 273.854 497.449 273.631 497.449C262.746 497.233 254.184 491.232 249.516 480.556L187.781 339.5C181.728 325.675 183.053 306.34 191.422 286.454C201.458 262.6 219.251 243.406 237.872 236.355L397.681 175.849C411.165 170.742 422.943 172.7 430.849 181.358C443.365 195.065 443.112 222.053 430.232 248.528L332.157 450.083C318.532 478.084 294.827 497.449 274.286 497.449ZM398.076 176.914L398.478 177.971L238.668 238.477C220.614 245.312 203.304 264.036 193.506 287.332C185.384 306.652 184.051 325.332 189.851 338.592L251.586 479.647C255.941 489.609 263.58 494.977 273.668 495.178C293.532 495.543 316.708 476.625 330.102 449.093L428.177 247.538C440.655 221.897 441.049 195.914 429.159 182.892C421.923 174.97 411.023 173.221 398.471 177.971L398.069 176.914H398.076Z"
          fill="url(#paint39_linear_2308_38472)"
        />
      </g>
      <g opacity="0.45">
        <path
          d="M284.791 511.997C273.765 511.997 264.593 506.554 258.785 496.481L176.792 354.235C169.012 340.744 168.394 321 175.095 300.064C183.657 273.314 201.704 250.881 222.2 241.523L393.23 163.416C408.187 156.581 421.715 157.884 431.326 167.072C445.725 180.845 447.594 208.131 436.099 236.594L347.062 456.948C334.629 487.718 310.008 510.322 287.196 511.908C286.385 511.967 285.588 511.989 284.799 511.989L284.791 511.997ZM411.21 161.383C405.909 161.383 400.168 162.746 394.175 165.486L223.146 243.593C203.215 252.698 185.637 274.602 177.261 300.764C170.754 321.089 171.305 340.171 178.765 353.11L260.758 495.357C266.603 505.505 275.686 510.433 287.04 509.659C309.025 508.133 332.842 486.11 344.963 456.114L433.999 235.76C444.981 208.586 443.276 181.649 429.763 168.724C424.663 163.848 418.335 161.391 411.217 161.391L411.21 161.383Z"
          fill="url(#paint40_linear_2308_38472)"
        />
        <path
          d="M284.791 511.997C273.765 511.997 264.593 506.554 258.785 496.481L176.792 354.235C169.012 340.744 168.394 321 175.095 300.064C183.657 273.314 201.704 250.881 222.2 241.523L393.23 163.416C408.187 156.581 421.715 157.884 431.326 167.072C445.725 180.845 447.594 208.131 436.099 236.594L347.062 456.948C334.629 487.718 310.008 510.322 287.196 511.908C286.385 511.967 285.588 511.989 284.799 511.989L284.791 511.997ZM411.21 161.383C405.909 161.383 400.168 162.746 394.175 165.486L223.146 243.593C203.215 252.698 185.637 274.602 177.261 300.764C170.754 321.089 171.305 340.171 178.765 353.11L260.758 495.357C266.603 505.505 275.686 510.433 287.04 509.659C309.025 508.133 332.842 486.11 344.963 456.114L433.999 235.76C444.981 208.586 443.276 181.649 429.763 168.724C424.663 163.848 418.335 161.391 411.217 161.391L411.21 161.383Z"
          fill="url(#paint41_radial_2308_38472)"
        />
        <path
          d="M284.791 511.997C273.765 511.997 264.593 506.554 258.785 496.481L176.792 354.235C169.012 340.744 168.394 321 175.095 300.064C183.657 273.314 201.704 250.881 222.2 241.523L393.23 163.416C408.187 156.581 421.715 157.884 431.326 167.072C445.725 180.845 447.594 208.131 436.099 236.594L347.062 456.948C334.629 487.718 310.008 510.322 287.196 511.908C286.385 511.967 285.588 511.989 284.799 511.989L284.791 511.997ZM411.21 161.383C405.909 161.383 400.168 162.746 394.175 165.486L223.146 243.593C203.215 252.698 185.637 274.602 177.261 300.764C170.754 321.089 171.305 340.171 178.765 353.11L260.758 495.357C266.603 505.505 275.686 510.433 287.04 509.659C309.025 508.133 332.842 486.11 344.963 456.114L433.999 235.76C444.981 208.586 443.276 181.649 429.763 168.724C424.663 163.848 418.335 161.391 411.217 161.391L411.21 161.383Z"
          fill="url(#paint42_radial_2308_38472)"
        />
        <path
          d="M284.791 511.997C273.765 511.997 264.593 506.554 258.785 496.481L176.792 354.235C169.012 340.744 168.394 321 175.095 300.064C183.657 273.314 201.704 250.881 222.2 241.523L393.23 163.416C408.187 156.581 421.715 157.884 431.326 167.072C445.725 180.845 447.594 208.131 436.099 236.594L347.062 456.948C334.629 487.718 310.008 510.322 287.196 511.908C286.385 511.967 285.588 511.989 284.799 511.989L284.791 511.997ZM411.21 161.383C405.909 161.383 400.168 162.746 394.175 165.486L223.146 243.593C203.215 252.698 185.637 274.602 177.261 300.764C170.754 321.089 171.305 340.171 178.765 353.11L260.758 495.357C266.603 505.505 275.686 510.433 287.04 509.659C309.025 508.133 332.842 486.11 344.963 456.114L433.999 235.76C444.981 208.586 443.276 181.649 429.763 168.724C424.663 163.848 418.335 161.391 411.217 161.391L411.21 161.383Z"
          fill="url(#paint43_linear_2308_38472)"
        />
      </g>
      <g opacity="0.45">
        <path
          d="M297.62 526.298C286.773 526.298 277.332 521.466 270.587 512.331L166.057 370.687C156.386 357.576 153.669 337.452 158.612 315.459C165.134 286.468 183.486 260.135 205.368 248.386L387.111 150.788C403.348 142.07 419.214 142.539 430.642 152.076C446.85 165.589 451.087 193.65 441.43 223.557L364.217 462.798C353.555 495.825 328.763 521.727 303.919 525.777C301.774 526.127 299.668 526.298 297.613 526.298H297.62ZM387.654 151.786L388.19 152.783L206.447 250.381C185.117 261.832 167.211 287.577 160.831 315.958C156.029 337.311 158.605 356.765 167.881 369.347L272.411 510.991C280.012 521.287 290.785 525.628 303.561 523.543C327.594 519.62 351.657 494.358 362.065 462.106L439.279 222.864C448.645 193.843 444.691 166.743 429.198 153.818C418.358 144.772 403.788 144.407 388.19 152.791L387.654 151.793V151.786Z"
          fill="url(#paint44_linear_2308_38472)"
        />
        <path
          d="M297.62 526.298C286.773 526.298 277.332 521.466 270.587 512.331L166.057 370.687C156.386 357.576 153.669 337.452 158.612 315.459C165.134 286.468 183.486 260.135 205.368 248.386L387.111 150.788C403.348 142.07 419.214 142.539 430.642 152.076C446.85 165.589 451.087 193.65 441.43 223.557L364.217 462.798C353.555 495.825 328.763 521.727 303.919 525.777C301.774 526.127 299.668 526.298 297.613 526.298H297.62ZM387.654 151.786L388.19 152.783L206.447 250.381C185.117 261.832 167.211 287.577 160.831 315.958C156.029 337.311 158.605 356.765 167.881 369.347L272.411 510.991C280.012 521.287 290.785 525.628 303.561 523.543C327.594 519.62 351.657 494.358 362.065 462.106L439.279 222.864C448.645 193.843 444.691 166.743 429.198 153.818C418.358 144.772 403.788 144.407 388.19 152.791L387.654 151.793V151.786Z"
          fill="url(#paint45_radial_2308_38472)"
        />
        <path
          d="M297.62 526.298C286.773 526.298 277.332 521.466 270.587 512.331L166.057 370.687C156.386 357.576 153.669 337.452 158.612 315.459C165.134 286.468 183.486 260.135 205.368 248.386L387.111 150.788C403.348 142.07 419.214 142.539 430.642 152.076C446.85 165.589 451.087 193.65 441.43 223.557L364.217 462.798C353.555 495.825 328.763 521.727 303.919 525.777C301.774 526.127 299.668 526.298 297.613 526.298H297.62ZM387.654 151.786L388.19 152.783L206.447 250.381C185.117 261.832 167.211 287.577 160.831 315.958C156.029 337.311 158.605 356.765 167.881 369.347L272.411 510.991C280.012 521.287 290.785 525.628 303.561 523.543C327.594 519.62 351.657 494.358 362.065 462.106L439.279 222.864C448.645 193.843 444.691 166.743 429.198 153.818C418.358 144.772 403.788 144.407 388.19 152.791L387.654 151.793V151.786Z"
          fill="url(#paint46_radial_2308_38472)"
        />
        <path
          d="M297.62 526.298C286.773 526.298 277.332 521.466 270.587 512.331L166.057 370.687C156.386 357.576 153.669 337.452 158.612 315.459C165.134 286.468 183.486 260.135 205.368 248.386L387.111 150.788C403.348 142.07 419.214 142.539 430.642 152.076C446.85 165.589 451.087 193.65 441.43 223.557L364.217 462.798C353.555 495.825 328.763 521.727 303.919 525.777C301.774 526.127 299.668 526.298 297.613 526.298H297.62ZM387.654 151.786L388.19 152.783L206.447 250.381C185.117 261.832 167.211 287.577 160.831 315.958C156.029 337.311 158.605 356.765 167.881 369.347L272.411 510.991C280.012 521.287 290.785 525.628 303.561 523.543C327.594 519.62 351.657 494.358 362.065 462.106L439.279 222.864C448.645 193.843 444.691 166.743 429.198 153.818C418.358 144.772 403.788 144.407 388.19 152.791L387.654 151.793V151.786Z"
          fill="url(#paint47_linear_2308_38472)"
        />
      </g>
      <g opacity="0.45">
        <path
          d="M312.889 540.131C302.295 540.131 292.713 535.947 285.178 527.861L155.76 388.92C143.967 376.264 138.971 355.737 142.061 332.613C146.245 301.254 164.046 271.615 187.409 257.112L379.129 138.094C396.855 127.09 414.895 126.465 428.616 136.389C446.767 149.515 453.624 178.164 446.083 209.381L383.782 467.34C375.331 502.317 350.83 531.629 324.191 538.612C320.334 539.625 316.545 540.123 312.882 540.123L312.889 540.131ZM380.32 140.022L188.6 259.04C165.788 273.201 148.404 302.2 144.302 332.918C141.309 355.365 146.089 375.214 157.413 387.379L286.831 526.32C296.368 536.557 309.427 540.146 323.61 536.431C349.475 529.648 373.314 501.022 381.571 466.819L443.871 208.86C451.19 178.543 444.683 150.825 427.284 138.235C414.344 128.877 397.228 129.532 380.32 140.03V140.022Z"
          fill="url(#paint48_linear_2308_38472)"
        />
        <path
          d="M312.889 540.131C302.295 540.131 292.713 535.947 285.178 527.861L155.76 388.92C143.967 376.264 138.971 355.737 142.061 332.613C146.245 301.254 164.046 271.615 187.409 257.112L379.129 138.094C396.855 127.09 414.895 126.465 428.616 136.389C446.767 149.515 453.624 178.164 446.083 209.381L383.782 467.34C375.331 502.317 350.83 531.629 324.191 538.612C320.334 539.625 316.545 540.123 312.882 540.123L312.889 540.131ZM380.32 140.022L188.6 259.04C165.788 273.201 148.404 302.2 144.302 332.918C141.309 355.365 146.089 375.214 157.413 387.379L286.831 526.32C296.368 536.557 309.427 540.146 323.61 536.431C349.475 529.648 373.314 501.022 381.571 466.819L443.871 208.86C451.19 178.543 444.683 150.825 427.284 138.235C414.344 128.877 397.228 129.532 380.32 140.03V140.022Z"
          fill="url(#paint49_radial_2308_38472)"
        />
        <path
          d="M312.889 540.131C302.295 540.131 292.713 535.947 285.178 527.861L155.76 388.92C143.967 376.264 138.971 355.737 142.061 332.613C146.245 301.254 164.046 271.615 187.409 257.112L379.129 138.094C396.855 127.09 414.895 126.465 428.616 136.389C446.767 149.515 453.624 178.164 446.083 209.381L383.782 467.34C375.331 502.317 350.83 531.629 324.191 538.612C320.334 539.625 316.545 540.123 312.882 540.123L312.889 540.131ZM380.32 140.022L188.6 259.04C165.788 273.201 148.404 302.2 144.302 332.918C141.309 355.365 146.089 375.214 157.413 387.379L286.831 526.32C296.368 536.557 309.427 540.146 323.61 536.431C349.475 529.648 373.314 501.022 381.571 466.819L443.871 208.86C451.19 178.543 444.683 150.825 427.284 138.235C414.344 128.877 397.228 129.532 380.32 140.03V140.022Z"
          fill="url(#paint50_radial_2308_38472)"
        />
        <path
          d="M312.889 540.131C302.295 540.131 292.713 535.947 285.178 527.861L155.76 388.92C143.967 376.264 138.971 355.737 142.061 332.613C146.245 301.254 164.046 271.615 187.409 257.112L379.129 138.094C396.855 127.09 414.895 126.465 428.616 136.389C446.767 149.515 453.624 178.164 446.083 209.381L383.782 467.34C375.331 502.317 350.83 531.629 324.191 538.612C320.334 539.625 316.545 540.123 312.882 540.123L312.889 540.131ZM380.32 140.022L188.6 259.04C165.788 273.201 148.404 302.2 144.302 332.918C141.309 355.365 146.089 375.214 157.413 387.379L286.831 526.32C296.368 536.557 309.427 540.146 323.61 536.431C349.475 529.648 373.314 501.022 381.571 466.819L443.871 208.86C451.19 178.543 444.683 150.825 427.284 138.235C414.344 128.877 397.228 129.532 380.32 140.03V140.022Z"
          fill="url(#paint51_linear_2308_38472)"
        />
      </g>
      <g opacity="0.45">
        <path
          d="M330.758 553.249C320.513 553.249 310.939 549.72 302.824 542.796L146.119 408.97C131.928 396.849 124.409 375.914 125.503 351.531C127 318.147 143.826 285.321 168.372 267.907L369.056 125.512C388.242 111.902 408.634 109.929 425.006 120.092C445.562 132.86 455.084 161.219 449.85 194.089L405.872 470.303C399.96 507.439 376.828 539.446 348.321 549.951C342.35 552.155 336.446 553.242 330.751 553.242L330.758 553.249ZM402.507 115.952C392.083 115.952 381.05 119.787 370.373 127.358L169.69 269.754C145.694 286.781 129.241 318.921 127.774 351.635C126.709 375.311 133.939 395.584 147.593 407.25L304.298 541.076C316.27 551.299 331.629 553.703 347.547 547.837C375.31 537.607 397.853 506.308 403.638 469.961L447.617 193.746C452.702 161.792 443.581 134.312 423.815 122.028C417.285 117.97 410.056 115.96 402.507 115.96V115.952Z"
          fill="url(#paint52_linear_2308_38472)"
        />
        <path
          d="M330.758 553.249C320.513 553.249 310.939 549.72 302.824 542.796L146.119 408.97C131.928 396.849 124.409 375.914 125.503 351.531C127 318.147 143.826 285.321 168.372 267.907L369.056 125.512C388.242 111.902 408.634 109.929 425.006 120.092C445.562 132.86 455.084 161.219 449.85 194.089L405.872 470.303C399.96 507.439 376.828 539.446 348.321 549.951C342.35 552.155 336.446 553.242 330.751 553.242L330.758 553.249ZM402.507 115.952C392.083 115.952 381.05 119.787 370.373 127.358L169.69 269.754C145.694 286.781 129.241 318.921 127.774 351.635C126.709 375.311 133.939 395.584 147.593 407.25L304.298 541.076C316.27 551.299 331.629 553.703 347.547 547.837C375.31 537.607 397.853 506.308 403.638 469.961L447.617 193.746C452.702 161.792 443.581 134.312 423.815 122.028C417.285 117.97 410.056 115.96 402.507 115.96V115.952Z"
          fill="url(#paint53_radial_2308_38472)"
        />
        <path
          d="M330.758 553.249C320.513 553.249 310.939 549.72 302.824 542.796L146.119 408.97C131.928 396.849 124.409 375.914 125.503 351.531C127 318.147 143.826 285.321 168.372 267.907L369.056 125.512C388.242 111.902 408.634 109.929 425.006 120.092C445.562 132.86 455.084 161.219 449.85 194.089L405.872 470.303C399.96 507.439 376.828 539.446 348.321 549.951C342.35 552.155 336.446 553.242 330.751 553.242L330.758 553.249ZM402.507 115.952C392.083 115.952 381.05 119.787 370.373 127.358L169.69 269.754C145.694 286.781 129.241 318.921 127.774 351.635C126.709 375.311 133.939 395.584 147.593 407.25L304.298 541.076C316.27 551.299 331.629 553.703 347.547 547.837C375.31 537.607 397.853 506.308 403.638 469.961L447.617 193.746C452.702 161.792 443.581 134.312 423.815 122.028C417.285 117.97 410.056 115.96 402.507 115.96V115.952Z"
          fill="url(#paint54_radial_2308_38472)"
        />
        <path
          d="M330.758 553.249C320.513 553.249 310.939 549.72 302.824 542.796L146.119 408.97C131.928 396.849 124.409 375.914 125.503 351.531C127 318.147 143.826 285.321 168.372 267.907L369.056 125.512C388.242 111.902 408.634 109.929 425.006 120.092C445.562 132.86 455.084 161.219 449.85 194.089L405.872 470.303C399.96 507.439 376.828 539.446 348.321 549.951C342.35 552.155 336.446 553.242 330.751 553.242L330.758 553.249ZM402.507 115.952C392.083 115.952 381.05 119.787 370.373 127.358L169.69 269.754C145.694 286.781 129.241 318.921 127.774 351.635C126.709 375.311 133.939 395.584 147.593 407.25L304.298 541.076C316.27 551.299 331.629 553.703 347.547 547.837C375.31 537.607 397.853 506.308 403.638 469.961L447.617 193.746C452.702 161.792 443.581 134.312 423.815 122.028C417.285 117.97 410.056 115.96 402.507 115.96V115.952Z"
          fill="url(#paint55_linear_2308_38472)"
        />
      </g>
      <g opacity="0.45">
        <path
          d="M351.379 565.4C341.611 565.4 332.238 562.534 323.795 556.831L137.384 430.874C120.439 419.423 110.097 398.056 109.018 372.251C107.551 337.222 123 301.388 148.365 280.966L356.71 113.235C377.251 96.6996 400.16 93.0663 419.569 103.259C442.448 115.275 455.082 143.805 452.551 177.71L430.618 471.39C427.759 509.681 406.064 544.986 376.625 559.243C368.153 563.345 359.628 565.385 351.379 565.385V565.4ZM357.424 114.129L358.132 115.015L149.787 282.746C124.965 302.729 109.852 337.832 111.281 372.169C112.331 397.252 122.307 417.964 138.649 429.013L325.061 554.97C339.928 565.02 357.894 565.825 375.635 557.233C404.366 543.318 425.555 508.765 428.354 471.256L450.288 177.576C452.752 144.587 440.579 116.891 418.512 105.299C399.944 95.5381 377.936 99.0895 358.132 115.029L357.424 114.144V114.129Z"
          fill="url(#paint56_linear_2308_38472)"
        />
        <path
          d="M351.379 565.4C341.611 565.4 332.238 562.534 323.795 556.831L137.384 430.874C120.439 419.423 110.097 398.056 109.018 372.251C107.551 337.222 123 301.388 148.365 280.966L356.71 113.235C377.251 96.6996 400.16 93.0663 419.569 103.259C442.448 115.275 455.082 143.805 452.551 177.71L430.618 471.39C427.759 509.681 406.064 544.986 376.625 559.243C368.153 563.345 359.628 565.385 351.379 565.385V565.4ZM357.424 114.129L358.132 115.015L149.787 282.746C124.965 302.729 109.852 337.832 111.281 372.169C112.331 397.252 122.307 417.964 138.649 429.013L325.061 554.97C339.928 565.02 357.894 565.825 375.635 557.233C404.366 543.318 425.555 508.765 428.354 471.256L450.288 177.576C452.752 144.587 440.579 116.891 418.512 105.299C399.944 95.5381 377.936 99.0895 358.132 115.029L357.424 114.144V114.129Z"
          fill="url(#paint57_radial_2308_38472)"
        />
        <path
          d="M351.379 565.4C341.611 565.4 332.238 562.534 323.795 556.831L137.384 430.874C120.439 419.423 110.097 398.056 109.018 372.251C107.551 337.222 123 301.388 148.365 280.966L356.71 113.235C377.251 96.6996 400.16 93.0663 419.569 103.259C442.448 115.275 455.082 143.805 452.551 177.71L430.618 471.39C427.759 509.681 406.064 544.986 376.625 559.243C368.153 563.345 359.628 565.385 351.379 565.385V565.4ZM357.424 114.129L358.132 115.015L149.787 282.746C124.965 302.729 109.852 337.832 111.281 372.169C112.331 397.252 122.307 417.964 138.649 429.013L325.061 554.97C339.928 565.02 357.894 565.825 375.635 557.233C404.366 543.318 425.555 508.765 428.354 471.256L450.288 177.576C452.752 144.587 440.579 116.891 418.512 105.299C399.944 95.5381 377.936 99.0895 358.132 115.029L357.424 114.144V114.129Z"
          fill="url(#paint58_radial_2308_38472)"
        />
        <path
          d="M351.379 565.4C341.611 565.4 332.238 562.534 323.795 556.831L137.384 430.874C120.439 419.423 110.097 398.056 109.018 372.251C107.551 337.222 123 301.388 148.365 280.966L356.71 113.235C377.251 96.6996 400.16 93.0663 419.569 103.259C442.448 115.275 455.082 143.805 452.551 177.71L430.618 471.39C427.759 509.681 406.064 544.986 376.625 559.243C368.153 563.345 359.628 565.385 351.379 565.385V565.4ZM357.424 114.129L358.132 115.015L149.787 282.746C124.965 302.729 109.852 337.832 111.281 372.169C112.331 397.252 122.307 417.964 138.649 429.013L325.061 554.97C339.928 565.02 357.894 565.825 375.635 557.233C404.366 543.318 425.555 508.765 428.354 471.256L450.288 177.576C452.752 144.587 440.579 116.891 418.512 105.299C399.944 95.5381 377.936 99.0895 358.132 115.029L357.424 114.144V114.129Z"
          fill="url(#paint59_linear_2308_38472)"
        />
      </g>
      <g opacity="0.45">
        <path
          d="M374.898 576.278C365.771 576.278 356.807 574.066 348.357 569.622L129.82 454.646C109.703 444.06 96.1602 422.26 92.661 394.832C88.0302 358.552 101.707 319.957 127.489 296.505L341.857 101.502C363.589 81.7349 389.148 76.0915 411.99 86.0084C437.4 97.0421 453.482 125.512 453.951 160.303L458.113 470.281C458.641 509.867 439.485 547.398 409.302 565.899C398.038 572.808 386.334 576.278 374.898 576.278ZM342.616 102.343L343.383 103.184L129.008 298.18C103.754 321.148 90.3679 358.977 94.902 394.542C98.3044 421.225 111.415 442.399 130.869 452.636L349.406 567.611C367.714 577.238 388.56 575.95 408.111 563.963C437.624 545.872 456.363 509.108 455.842 470.304L451.68 160.326C451.226 126.435 435.673 98.7545 411.089 88.0782C389.096 78.5335 364.423 84.0355 343.383 103.169L342.616 102.328V102.343Z"
          fill="url(#paint60_linear_2308_38472)"
        />
        <path
          d="M374.898 576.278C365.771 576.278 356.807 574.066 348.357 569.622L129.82 454.646C109.703 444.06 96.1602 422.26 92.661 394.832C88.0302 358.552 101.707 319.957 127.489 296.505L341.857 101.502C363.589 81.7349 389.148 76.0915 411.99 86.0084C437.4 97.0421 453.482 125.512 453.951 160.303L458.113 470.281C458.641 509.867 439.485 547.398 409.302 565.899C398.038 572.808 386.334 576.278 374.898 576.278ZM342.616 102.343L343.383 103.184L129.008 298.18C103.754 321.148 90.3679 358.977 94.902 394.542C98.3044 421.225 111.415 442.399 130.869 452.636L349.406 567.611C367.714 577.238 388.56 575.95 408.111 563.963C437.624 545.872 456.363 509.108 455.842 470.304L451.68 160.326C451.226 126.435 435.673 98.7545 411.089 88.0782C389.096 78.5335 364.423 84.0355 343.383 103.169L342.616 102.328V102.343Z"
          fill="url(#paint61_radial_2308_38472)"
        />
        <path
          d="M374.898 576.278C365.771 576.278 356.807 574.066 348.357 569.622L129.82 454.646C109.703 444.06 96.1602 422.26 92.661 394.832C88.0302 358.552 101.707 319.957 127.489 296.505L341.857 101.502C363.589 81.7349 389.148 76.0915 411.99 86.0084C437.4 97.0421 453.482 125.512 453.951 160.303L458.113 470.281C458.641 509.867 439.485 547.398 409.302 565.899C398.038 572.808 386.334 576.278 374.898 576.278ZM342.616 102.343L343.383 103.184L129.008 298.18C103.754 321.148 90.3679 358.977 94.902 394.542C98.3044 421.225 111.415 442.399 130.869 452.636L349.406 567.611C367.714 577.238 388.56 575.95 408.111 563.963C437.624 545.872 456.363 509.108 455.842 470.304L451.68 160.326C451.226 126.435 435.673 98.7545 411.089 88.0782C389.096 78.5335 364.423 84.0355 343.383 103.169L342.616 102.328V102.343Z"
          fill="url(#paint62_radial_2308_38472)"
        />
        <path
          d="M374.898 576.278C365.771 576.278 356.807 574.066 348.357 569.622L129.82 454.646C109.703 444.06 96.1602 422.26 92.661 394.832C88.0302 358.552 101.707 319.957 127.489 296.505L341.857 101.502C363.589 81.7349 389.148 76.0915 411.99 86.0084C437.4 97.0421 453.482 125.512 453.951 160.303L458.113 470.281C458.641 509.867 439.485 547.398 409.302 565.899C398.038 572.808 386.334 576.278 374.898 576.278ZM342.616 102.343L343.383 103.184L129.008 298.18C103.754 321.148 90.3679 358.977 94.902 394.542C98.3044 421.225 111.415 442.399 130.869 452.636L349.406 567.611C367.714 577.238 388.56 575.95 408.111 563.963C437.624 545.872 456.363 509.108 455.842 470.304L451.68 160.326C451.226 126.435 435.673 98.7545 411.089 88.0782C389.096 78.5335 364.423 84.0355 343.383 103.169L342.616 102.328V102.343Z"
          fill="url(#paint63_linear_2308_38472)"
        />
      </g>
      <g opacity="0.45">
        <path
          d="M401.107 585.449C392.858 585.449 384.653 583.908 376.769 580.774L123.739 480.257C99.9667 470.809 82.7461 448.615 76.5071 419.349C68.4663 381.654 79.7234 341.554 105.886 314.707L324.281 90.5494C346.616 67.6259 375.645 59.3618 401.919 68.4597C430.56 78.3692 449.955 105.827 453.804 141.906L488.431 466.573C492.675 506.375 476.206 546.683 446.463 569.264C432.392 579.947 416.66 585.442 401.1 585.442L401.107 585.449ZM325.092 91.3461L325.904 92.1352L107.509 316.278C81.8675 342.589 70.8413 381.9 78.7257 418.865C84.8084 447.372 101.515 468.978 124.573 478.143L377.603 578.659C399.611 587.4 424.209 583.32 445.1 567.455C474.218 545.343 490.345 505.839 486.183 466.804L451.555 142.137C447.803 106.951 428.974 80.2081 401.182 70.589C375.757 61.7963 347.614 69.8445 325.904 92.1204L325.092 91.3312V91.3461Z"
          fill="url(#paint64_linear_2308_38472)"
        />
        <path
          d="M401.107 585.449C392.858 585.449 384.653 583.908 376.769 580.774L123.739 480.257C99.9667 470.809 82.7461 448.615 76.5071 419.349C68.4663 381.654 79.7234 341.554 105.886 314.707L324.281 90.5494C346.616 67.6259 375.645 59.3618 401.919 68.4597C430.56 78.3692 449.955 105.827 453.804 141.906L488.431 466.573C492.675 506.375 476.206 546.683 446.463 569.264C432.392 579.947 416.66 585.442 401.1 585.442L401.107 585.449ZM325.092 91.3461L325.904 92.1352L107.509 316.278C81.8675 342.589 70.8413 381.9 78.7257 418.865C84.8084 447.372 101.515 468.978 124.573 478.143L377.603 578.659C399.611 587.4 424.209 583.32 445.1 567.455C474.218 545.343 490.345 505.839 486.183 466.804L451.555 142.137C447.803 106.951 428.974 80.2081 401.182 70.589C375.757 61.7963 347.614 69.8445 325.904 92.1204L325.092 91.3312V91.3461Z"
          fill="url(#paint65_radial_2308_38472)"
        />
        <path
          d="M401.107 585.449C392.858 585.449 384.653 583.908 376.769 580.774L123.739 480.257C99.9667 470.809 82.7461 448.615 76.5071 419.349C68.4663 381.654 79.7234 341.554 105.886 314.707L324.281 90.5494C346.616 67.6259 375.645 59.3618 401.919 68.4597C430.56 78.3692 449.955 105.827 453.804 141.906L488.431 466.573C492.675 506.375 476.206 546.683 446.463 569.264C432.392 579.947 416.66 585.442 401.1 585.442L401.107 585.449ZM325.092 91.3461L325.904 92.1352L107.509 316.278C81.8675 342.589 70.8413 381.9 78.7257 418.865C84.8084 447.372 101.515 468.978 124.573 478.143L377.603 578.659C399.611 587.4 424.209 583.32 445.1 567.455C474.218 545.343 490.345 505.839 486.183 466.804L451.555 142.137C447.803 106.951 428.974 80.2081 401.182 70.589C375.757 61.7963 347.614 69.8445 325.904 92.1204L325.092 91.3312V91.3461Z"
          fill="url(#paint66_radial_2308_38472)"
        />
        <path
          d="M401.107 585.449C392.858 585.449 384.653 583.908 376.769 580.774L123.739 480.257C99.9667 470.809 82.7461 448.615 76.5071 419.349C68.4663 381.654 79.7234 341.554 105.886 314.707L324.281 90.5494C346.616 67.6259 375.645 59.3618 401.919 68.4597C430.56 78.3692 449.955 105.827 453.804 141.906L488.431 466.573C492.675 506.375 476.206 546.683 446.463 569.264C432.392 579.947 416.66 585.442 401.1 585.442L401.107 585.449ZM325.092 91.3461L325.904 92.1352L107.509 316.278C81.8675 342.589 70.8413 381.9 78.7257 418.865C84.8084 447.372 101.515 468.978 124.573 478.143L377.603 578.659C399.611 587.4 424.209 583.32 445.1 567.455C474.218 545.343 490.345 505.839 486.183 466.804L451.555 142.137C447.803 106.951 428.974 80.2081 401.182 70.589C375.757 61.7963 347.614 69.8445 325.904 92.1204L325.092 91.3312V91.3461Z"
          fill="url(#paint67_linear_2308_38472)"
        />
      </g>
      <g opacity="0.45">
        <path
          d="M430.38 592.805C423.337 592.805 416.257 591.829 409.273 589.849L119.464 507.655C91.4779 499.718 70.0433 477.204 60.6476 445.89C49.2044 407.726 58.0492 365.527 83.7349 335.754L303.768 80.6766C326.707 54.09 358.549 42.9223 388.948 50.8142C420.754 59.0634 444.273 85.9179 451.867 122.637L521.635 459.916C529.944 500.098 517.101 541.813 488.11 568.779C471.172 584.533 450.906 592.819 430.373 592.819L430.38 592.805ZM304.632 81.4136L305.488 82.1507L85.4473 337.243C60.2679 366.435 51.5943 407.823 62.8141 445.25C71.9791 475.804 92.8477 497.76 120.075 505.481L409.884 587.675C436.47 595.217 464.419 587.72 486.568 567.111C514.979 540.681 527.569 499.785 519.416 460.371L449.648 123.091C442.233 87.2357 419.324 61.0363 388.382 53.003C358.832 45.3346 327.846 56.2342 305.488 82.1581L304.632 81.4211V81.4136Z"
          fill="url(#paint68_linear_2308_38472)"
        />
        <path
          d="M430.38 592.805C423.337 592.805 416.257 591.829 409.273 589.849L119.464 507.655C91.4779 499.718 70.0433 477.204 60.6476 445.89C49.2044 407.726 58.0492 365.527 83.7349 335.754L303.768 80.6766C326.707 54.09 358.549 42.9223 388.948 50.8142C420.754 59.0634 444.273 85.9179 451.867 122.637L521.635 459.916C529.944 500.098 517.101 541.813 488.11 568.779C471.172 584.533 450.906 592.819 430.373 592.819L430.38 592.805ZM304.632 81.4136L305.488 82.1507L85.4473 337.243C60.2679 366.435 51.5943 407.823 62.8141 445.25C71.9791 475.804 92.8477 497.76 120.075 505.481L409.884 587.675C436.47 595.217 464.419 587.72 486.568 567.111C514.979 540.681 527.569 499.785 519.416 460.371L449.648 123.091C442.233 87.2357 419.324 61.0363 388.382 53.003C358.832 45.3346 327.846 56.2342 305.488 82.1581L304.632 81.4211V81.4136Z"
          fill="url(#paint69_radial_2308_38472)"
        />
        <path
          d="M430.38 592.805C423.337 592.805 416.257 591.829 409.273 589.849L119.464 507.655C91.4779 499.718 70.0433 477.204 60.6476 445.89C49.2044 407.726 58.0492 365.527 83.7349 335.754L303.768 80.6766C326.707 54.09 358.549 42.9223 388.948 50.8142C420.754 59.0634 444.273 85.9179 451.867 122.637L521.635 459.916C529.944 500.098 517.101 541.813 488.11 568.779C471.172 584.533 450.906 592.819 430.373 592.819L430.38 592.805ZM304.632 81.4136L305.488 82.1507L85.4473 337.243C60.2679 366.435 51.5943 407.823 62.8141 445.25C71.9791 475.804 92.8477 497.76 120.075 505.481L409.884 587.675C436.47 595.217 464.419 587.72 486.568 567.111C514.979 540.681 527.569 499.785 519.416 460.371L449.648 123.091C442.233 87.2357 419.324 61.0363 388.382 53.003C358.832 45.3346 327.846 56.2342 305.488 82.1581L304.632 81.4211V81.4136Z"
          fill="url(#paint70_radial_2308_38472)"
        />
        <path
          d="M430.38 592.805C423.337 592.805 416.257 591.829 409.273 589.849L119.464 507.655C91.4779 499.718 70.0433 477.204 60.6476 445.89C49.2044 407.726 58.0492 365.527 83.7349 335.754L303.768 80.6766C326.707 54.09 358.549 42.9223 388.948 50.8142C420.754 59.0634 444.273 85.9179 451.867 122.637L521.635 459.916C529.944 500.098 517.101 541.813 488.11 568.779C471.172 584.533 450.906 592.819 430.373 592.819L430.38 592.805ZM304.632 81.4136L305.488 82.1507L85.4473 337.243C60.2679 366.435 51.5943 407.823 62.8141 445.25C71.9791 475.804 92.8477 497.76 120.075 505.481L409.884 587.675C436.47 595.217 464.419 587.72 486.568 567.111C514.979 540.681 527.569 499.785 519.416 460.371L449.648 123.091C442.233 87.2357 419.324 61.0363 388.382 53.003C358.832 45.3346 327.846 56.2342 305.488 82.1581L304.632 81.4211V81.4136Z"
          fill="url(#paint71_linear_2308_38472)"
        />
      </g>
      <g opacity="0.45">
        <path
          d="M462.499 597.874C457.071 597.874 451.599 597.383 446.105 596.385L117.365 536.787C84.5543 530.838 58.2581 508.175 45.2217 474.613C30.3612 436.367 36.5109 392.404 61.2585 359.868L280.115 72.1961C303.233 41.8125 337.8 27.2722 372.599 33.3176C407.941 39.4524 436.076 65.354 447.862 102.595L557.752 449.873C570.267 489.428 561.229 533.109 534.151 563.865C514.704 585.955 489.249 597.874 462.506 597.874H462.499ZM357.076 34.2408C328.449 34.2408 301.148 48.2972 281.917 73.5734L63.0677 361.246C38.7966 393.156 32.766 436.278 47.3436 473.794C60.0896 506.604 85.7604 528.754 117.774 534.553L446.514 594.152C478.275 599.914 509.589 588.322 532.446 562.361C558.995 532.201 567.87 489.354 555.585 450.55L445.695 103.272C434.17 66.8505 406.698 41.5371 372.204 35.5437C367.142 34.6652 362.086 34.2334 357.068 34.2334L357.076 34.2408Z"
          fill="url(#paint72_linear_2308_38472)"
        />
        <path
          d="M462.499 597.874C457.071 597.874 451.599 597.383 446.105 596.385L117.365 536.787C84.5543 530.838 58.2581 508.175 45.2217 474.613C30.3612 436.367 36.5109 392.404 61.2585 359.868L280.115 72.1961C303.233 41.8125 337.8 27.2722 372.599 33.3176C407.941 39.4524 436.076 65.354 447.862 102.595L557.752 449.873C570.267 489.428 561.229 533.109 534.151 563.865C514.704 585.955 489.249 597.874 462.506 597.874H462.499ZM357.076 34.2408C328.449 34.2408 301.148 48.2972 281.917 73.5734L63.0677 361.246C38.7966 393.156 32.766 436.278 47.3436 473.794C60.0896 506.604 85.7604 528.754 117.774 534.553L446.514 594.152C478.275 599.914 509.589 588.322 532.446 562.361C558.995 532.201 567.87 489.354 555.585 450.55L445.695 103.272C434.17 66.8505 406.698 41.5371 372.204 35.5437C367.142 34.6652 362.086 34.2334 357.068 34.2334L357.076 34.2408Z"
          fill="url(#paint73_radial_2308_38472)"
        />
        <path
          d="M462.499 597.874C457.071 597.874 451.599 597.383 446.105 596.385L117.365 536.787C84.5543 530.838 58.2581 508.175 45.2217 474.613C30.3612 436.367 36.5109 392.404 61.2585 359.868L280.115 72.1961C303.233 41.8125 337.8 27.2722 372.599 33.3176C407.941 39.4524 436.076 65.354 447.862 102.595L557.752 449.873C570.267 489.428 561.229 533.109 534.151 563.865C514.704 585.955 489.249 597.874 462.506 597.874H462.499ZM357.076 34.2408C328.449 34.2408 301.148 48.2972 281.917 73.5734L63.0677 361.246C38.7966 393.156 32.766 436.278 47.3436 473.794C60.0896 506.604 85.7604 528.754 117.774 534.553L446.514 594.152C478.275 599.914 509.589 588.322 532.446 562.361C558.995 532.201 567.87 489.354 555.585 450.55L445.695 103.272C434.17 66.8505 406.698 41.5371 372.204 35.5437C367.142 34.6652 362.086 34.2334 357.068 34.2334L357.076 34.2408Z"
          fill="url(#paint74_radial_2308_38472)"
        />
        <path
          d="M462.499 597.874C457.071 597.874 451.599 597.383 446.105 596.385L117.365 536.787C84.5543 530.838 58.2581 508.175 45.2217 474.613C30.3612 436.367 36.5109 392.404 61.2585 359.868L280.115 72.1961C303.233 41.8125 337.8 27.2722 372.599 33.3176C407.941 39.4524 436.076 65.354 447.862 102.595L557.752 449.873C570.267 489.428 561.229 533.109 534.151 563.865C514.704 585.955 489.249 597.874 462.506 597.874H462.499ZM357.076 34.2408C328.449 34.2408 301.148 48.2972 281.917 73.5734L63.0677 361.246C38.7966 393.156 32.766 436.278 47.3436 473.794C60.0896 506.604 85.7604 528.754 117.774 534.553L446.514 594.152C478.275 599.914 509.589 588.322 532.446 562.361C558.995 532.201 567.87 489.354 555.585 450.55L445.695 103.272C434.17 66.8505 406.698 41.5371 372.204 35.5437C367.142 34.6652 362.086 34.2334 357.068 34.2334L357.076 34.2408Z"
          fill="url(#paint75_linear_2308_38472)"
        />
      </g>
      <g opacity="0.45">
        <path
          d="M496.725 600.266C493.665 600.266 490.59 600.132 487.508 599.864L117.857 567.522C80.133 564.224 47.4414 541.092 30.4144 505.646C11.8984 467.102 15.003 422.826 38.7232 387.224L253.113 65.475C275.91 31.2646 312.994 12.89 352.319 16.3297C391.645 19.7693 424.976 44.301 441.49 81.951L596.75 436.034C613.934 475.218 609.303 519.352 584.377 554.099C563.329 583.432 531.069 600.251 496.733 600.258L496.725 600.266ZM342.968 18.191C307.939 18.191 275.545 35.888 254.997 66.7332L40.6068 388.474C17.3334 423.407 14.2808 466.842 32.4544 504.656C49.1315 539.372 81.1306 562.028 118.051 565.259L487.702 597.601C524.615 600.824 560.068 584.08 582.523 552.781C606.98 518.69 611.521 475.389 594.666 436.95L439.405 82.8668C423.234 45.9836 390.602 21.9582 352.118 18.593C349.051 18.325 345.999 18.191 342.968 18.191Z"
          fill="url(#paint76_linear_2308_38472)"
        />
        <path
          d="M496.725 600.266C493.665 600.266 490.59 600.132 487.508 599.864L117.857 567.522C80.133 564.224 47.4414 541.092 30.4144 505.646C11.8984 467.102 15.003 422.826 38.7232 387.224L253.113 65.475C275.91 31.2646 312.994 12.89 352.319 16.3297C391.645 19.7693 424.976 44.301 441.49 81.951L596.75 436.034C613.934 475.218 609.303 519.352 584.377 554.099C563.329 583.432 531.069 600.251 496.733 600.258L496.725 600.266ZM342.968 18.191C307.939 18.191 275.545 35.888 254.997 66.7332L40.6068 388.474C17.3334 423.407 14.2808 466.842 32.4544 504.656C49.1315 539.372 81.1306 562.028 118.051 565.259L487.702 597.601C524.615 600.824 560.068 584.08 582.523 552.781C606.98 518.69 611.521 475.389 594.666 436.95L439.405 82.8668C423.234 45.9836 390.602 21.9582 352.118 18.593C349.051 18.325 345.999 18.191 342.968 18.191Z"
          fill="url(#paint77_radial_2308_38472)"
        />
        <path
          d="M496.725 600.266C493.665 600.266 490.59 600.132 487.508 599.864L117.857 567.522C80.133 564.224 47.4414 541.092 30.4144 505.646C11.8984 467.102 15.003 422.826 38.7232 387.224L253.113 65.475C275.91 31.2646 312.994 12.89 352.319 16.3297C391.645 19.7693 424.976 44.301 441.49 81.951L596.75 436.034C613.934 475.218 609.303 519.352 584.377 554.099C563.329 583.432 531.069 600.251 496.733 600.258L496.725 600.266ZM342.968 18.191C307.939 18.191 275.545 35.888 254.997 66.7332L40.6068 388.474C17.3334 423.407 14.2808 466.842 32.4544 504.656C49.1315 539.372 81.1306 562.028 118.051 565.259L487.702 597.601C524.615 600.824 560.068 584.08 582.523 552.781C606.98 518.69 611.521 475.389 594.666 436.95L439.405 82.8668C423.234 45.9836 390.602 21.9582 352.118 18.593C349.051 18.325 345.999 18.191 342.968 18.191Z"
          fill="url(#paint78_radial_2308_38472)"
        />
        <path
          d="M496.725 600.266C493.665 600.266 490.59 600.132 487.508 599.864L117.857 567.522C80.133 564.224 47.4414 541.092 30.4144 505.646C11.8984 467.102 15.003 422.826 38.7232 387.224L253.113 65.475C275.91 31.2646 312.994 12.89 352.319 16.3297C391.645 19.7693 424.976 44.301 441.49 81.951L596.75 436.034C613.934 475.218 609.303 519.352 584.377 554.099C563.329 583.432 531.069 600.251 496.733 600.258L496.725 600.266ZM342.968 18.191C307.939 18.191 275.545 35.888 254.997 66.7332L40.6068 388.474C17.3334 423.407 14.2808 466.842 32.4544 504.656C49.1315 539.372 81.1306 562.028 118.051 565.259L487.702 597.601C524.615 600.824 560.068 584.08 582.523 552.781C606.98 518.69 611.521 475.389 594.666 436.95L439.405 82.8668C423.234 45.9836 390.602 21.9582 352.118 18.593C349.051 18.325 345.999 18.191 342.968 18.191Z"
          fill="url(#paint79_linear_2308_38472)"
        />
      </g>
      <g opacity="0.45">
        <path
          d="M533.644 599.7H121.355C77.5557 599.7 38.3273 577.052 16.4238 539.119C-5.4798 501.186 -5.4798 455.89 16.4238 417.957L222.572 60.9033C244.468 22.9703 283.704 0.322266 327.504 0.322266C371.303 0.322266 410.532 22.9703 432.428 60.9033L638.576 417.957C660.479 455.89 660.479 501.186 638.576 539.119C616.672 577.052 577.451 599.7 533.644 599.7ZM223.555 61.4691L224.537 62.0349L18.3893 419.088C-3.1048 456.314 -3.1048 500.754 18.3893 537.98C39.8834 575.205 78.3747 597.429 121.355 597.429H533.644C576.625 597.429 615.116 575.205 636.61 537.98C658.104 500.754 658.104 456.307 636.61 419.088L430.462 62.0349C408.976 24.8167 370.484 2.59303 327.504 2.59303C284.523 2.59303 246.031 24.8167 224.537 62.0424L223.555 61.4765V61.4691Z"
          fill="url(#paint80_linear_2308_38472)"
        />
        <path
          d="M533.644 599.7H121.355C77.5557 599.7 38.3273 577.052 16.4238 539.119C-5.4798 501.186 -5.4798 455.89 16.4238 417.957L222.572 60.9033C244.468 22.9703 283.704 0.322266 327.504 0.322266C371.303 0.322266 410.532 22.9703 432.428 60.9033L638.576 417.957C660.479 455.89 660.479 501.186 638.576 539.119C616.672 577.052 577.451 599.7 533.644 599.7ZM223.555 61.4691L224.537 62.0349L18.3893 419.088C-3.1048 456.314 -3.1048 500.754 18.3893 537.98C39.8834 575.205 78.3747 597.429 121.355 597.429H533.644C576.625 597.429 615.116 575.205 636.61 537.98C658.104 500.754 658.104 456.307 636.61 419.088L430.462 62.0349C408.976 24.8167 370.484 2.59303 327.504 2.59303C284.523 2.59303 246.031 24.8167 224.537 62.0424L223.555 61.4765V61.4691Z"
          fill="url(#paint81_radial_2308_38472)"
        />
        <path
          d="M533.644 599.7H121.355C77.5557 599.7 38.3273 577.052 16.4238 539.119C-5.4798 501.186 -5.4798 455.89 16.4238 417.957L222.572 60.9033C244.468 22.9703 283.704 0.322266 327.504 0.322266C371.303 0.322266 410.532 22.9703 432.428 60.9033L638.576 417.957C660.479 455.89 660.479 501.186 638.576 539.119C616.672 577.052 577.451 599.7 533.644 599.7ZM223.555 61.4691L224.537 62.0349L18.3893 419.088C-3.1048 456.314 -3.1048 500.754 18.3893 537.98C39.8834 575.205 78.3747 597.429 121.355 597.429H533.644C576.625 597.429 615.116 575.205 636.61 537.98C658.104 500.754 658.104 456.307 636.61 419.088L430.462 62.0349C408.976 24.8167 370.484 2.59303 327.504 2.59303C284.523 2.59303 246.031 24.8167 224.537 62.0424L223.555 61.4765V61.4691Z"
          fill="url(#paint82_radial_2308_38472)"
        />
        <path
          d="M533.644 599.7H121.355C77.5557 599.7 38.3273 577.052 16.4238 539.119C-5.4798 501.186 -5.4798 455.89 16.4238 417.957L222.572 60.9033C244.468 22.9703 283.704 0.322266 327.504 0.322266C371.303 0.322266 410.532 22.9703 432.428 60.9033L638.576 417.957C660.479 455.89 660.479 501.186 638.576 539.119C616.672 577.052 577.451 599.7 533.644 599.7ZM223.555 61.4691L224.537 62.0349L18.3893 419.088C-3.1048 456.314 -3.1048 500.754 18.3893 537.98C39.8834 575.205 78.3747 597.429 121.355 597.429H533.644C576.625 597.429 615.116 575.205 636.61 537.98C658.104 500.754 658.104 456.307 636.61 419.088L430.462 62.0349C408.976 24.8167 370.484 2.59303 327.504 2.59303C284.523 2.59303 246.031 24.8167 224.537 62.0424L223.555 61.4765V61.4691Z"
          fill="url(#paint83_linear_2308_38472)"
        />
      </g>
    </g>
    <defs>
      <linearGradient
        id="paint0_linear_2308_38472"
        x1="374.448"
        y1="377.093"
        x2="294.64"
        y2="248.442"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.61" stopColor="#48A0F9" />
        <stop offset="0.896175" stopColor="#FFAA8F" />
      </linearGradient>
      <radialGradient
        id="paint1_radial_2308_38472"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(318.632 308.767) rotate(90) scale(71.2354 79.0144)"
      >
        <stop offset="0.0572917" stopColor="white" />
        <stop offset="1" stopColor="white" stopOpacity="0" />
      </radialGradient>
      <radialGradient
        id="paint2_radial_2308_38472"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(311.529 377.447) rotate(-87.5386) scale(65.1868 200.11)"
      >
        <stop offset="0.525786" stopColor="white" />
        <stop offset="1" stopColor="white" stopOpacity="0" />
      </radialGradient>
      <linearGradient
        id="paint3_linear_2308_38472"
        x1="353.149"
        y1="270.366"
        x2="344.612"
        y2="305.059"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="white" />
        <stop offset="1" stopColor="white" stopOpacity="0" />
      </linearGradient>
      <linearGradient
        id="paint4_linear_2308_38472"
        x1="378.444"
        y1="387.543"
        x2="289.75"
        y2="250.063"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.61" stopColor="#48A0F9" />
        <stop offset="0.896175" stopColor="#FFAA8F" />
      </linearGradient>
      <radialGradient
        id="paint5_radial_2308_38472"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(319.789 312.874) rotate(90) scale(77.8484 83.0312)"
      >
        <stop offset="0.0572917" stopColor="white" />
        <stop offset="1" stopColor="white" stopOpacity="0" />
      </radialGradient>
      <radialGradient
        id="paint6_radial_2308_38472"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(312.325 387.929) rotate(-87.6331) scale(71.2333 210.298)"
      >
        <stop offset="0.525786" stopColor="white" />
        <stop offset="1" stopColor="white" stopOpacity="0" />
      </radialGradient>
      <linearGradient
        id="paint7_linear_2308_38472"
        x1="356.062"
        y1="270.908"
        x2="346.404"
        y2="308.646"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="white" />
        <stop offset="1" stopColor="white" stopOpacity="0" />
      </linearGradient>
      <linearGradient
        id="paint8_linear_2308_38472"
        x1="382.554"
        y1="398.65"
        x2="284.599"
        y2="252.77"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.61" stopColor="#48A0F9" />
        <stop offset="0.896175" stopColor="#FFAA8F" />
      </linearGradient>
      <radialGradient
        id="paint9_radial_2308_38472"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(321.292 317.479) rotate(90) scale(84.6274 86.7218)"
      >
        <stop offset="0.0572917" stopColor="white" />
        <stop offset="1" stopColor="white" stopOpacity="0" />
      </radialGradient>
      <radialGradient
        id="paint10_radial_2308_38472"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(313.497 399.071) rotate(-87.7258) scale(77.4312 219.66)"
      >
        <stop offset="0.525786" stopColor="white" />
        <stop offset="1" stopColor="white" stopOpacity="0" />
      </radialGradient>
      <linearGradient
        id="paint11_linear_2308_38472"
        x1="359.177"
        y1="271.859"
        x2="348.306"
        y2="312.674"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="white" />
        <stop offset="1" stopColor="white" stopOpacity="0" />
      </linearGradient>
      <linearGradient
        id="paint12_linear_2308_38472"
        x1="386.697"
        y1="410.509"
        x2="279.059"
        y2="256.785"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.61" stopColor="#48A0F9" />
        <stop offset="0.896175" stopColor="#FFAA8F" />
      </linearGradient>
      <radialGradient
        id="paint13_radial_2308_38472"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(323.109 322.649) rotate(90) scale(91.6005 90.0154)"
      >
        <stop offset="0.0572917" stopColor="white" />
        <stop offset="1" stopColor="white" stopOpacity="0" />
      </radialGradient>
      <radialGradient
        id="paint14_radial_2308_38472"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(315.017 410.964) rotate(-87.819) scale(83.8061 228.017)"
      >
        <stop offset="0.525786" stopColor="white" />
        <stop offset="1" stopColor="white" stopOpacity="0" />
      </radialGradient>
      <linearGradient
        id="paint15_linear_2308_38472"
        x1="362.433"
        y1="273.27"
        x2="350.233"
        y2="317.195"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="white" />
        <stop offset="1" stopColor="white" stopOpacity="0" />
      </linearGradient>
      <linearGradient
        id="paint16_linear_2308_38472"
        x1="390.884"
        y1="422.995"
        x2="273.328"
        y2="262.228"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.61" stopColor="#48A0F9" />
        <stop offset="0.896175" stopColor="#FFAA8F" />
      </linearGradient>
      <radialGradient
        id="paint17_radial_2308_38472"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(325.306 328.373) rotate(90) scale(98.6508 92.8329)"
      >
        <stop offset="0.0572917" stopColor="white" />
        <stop offset="1" stopColor="white" stopOpacity="0" />
      </radialGradient>
      <radialGradient
        id="paint18_radial_2308_38472"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(316.961 423.485) rotate(-87.9114) scale(90.251 235.168)"
      >
        <stop offset="0.525786" stopColor="white" />
        <stop offset="1" stopColor="white" stopOpacity="0" />
      </radialGradient>
      <linearGradient
        id="paint19_linear_2308_38472"
        x1="365.86"
        y1="275.194"
        x2="352.228"
        y2="322.194"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="white" />
        <stop offset="1" stopColor="white" stopOpacity="0" />
      </linearGradient>
      <linearGradient
        id="paint20_linear_2308_38472"
        x1="394.905"
        y1="435.904"
        x2="261.909"
        y2="266.535"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.61" stopColor="#48A0F9" />
        <stop offset="0.896175" stopColor="#FFAA8F" />
      </linearGradient>
      <radialGradient
        id="paint21_radial_2308_38472"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(327.137 330.897) rotate(90) scale(109.477 95.932)"
      >
        <stop offset="0.0572917" stopColor="white" />
        <stop offset="1" stopColor="white" stopOpacity="0" />
      </radialGradient>
      <radialGradient
        id="paint22_radial_2308_38472"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(318.514 436.448) rotate(-88.055) scale(100.147 243.04)"
      >
        <stop offset="0.525786" stopColor="white" />
        <stop offset="1" stopColor="white" stopOpacity="0" />
      </radialGradient>
      <linearGradient
        id="paint23_linear_2308_38472"
        x1="369.045"
        y1="271.882"
        x2="352.99"
        y2="323.427"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="white" />
        <stop offset="1" stopColor="white" stopOpacity="0" />
      </linearGradient>
      <linearGradient
        id="paint24_linear_2308_38472"
        x1="397.291"
        y1="449.187"
        x2="248.314"
        y2="265.017"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.61" stopColor="#48A0F9" />
        <stop offset="0.896175" stopColor="#FFAA8F" />
      </linearGradient>
      <radialGradient
        id="paint25_radial_2308_38472"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(324.088 332.339) rotate(90) scale(121.823 103.627)"
      >
        <stop offset="0.0572917" stopColor="white" />
        <stop offset="1" stopColor="white" stopOpacity="0" />
      </radialGradient>
      <radialGradient
        id="paint26_radial_2308_38472"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(314.773 449.792) rotate(-88.1119) scale(111.437 262.543)"
      >
        <stop offset="0.525786" stopColor="white" />
        <stop offset="1" stopColor="white" stopOpacity="0" />
      </radialGradient>
      <linearGradient
        id="paint27_linear_2308_38472"
        x1="369.358"
        y1="266.668"
        x2="351.052"
        y2="323.717"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="white" />
        <stop offset="1" stopColor="white" stopOpacity="0" />
      </linearGradient>
      <linearGradient
        id="paint28_linear_2308_38472"
        x1="399.347"
        y1="462.878"
        x2="233.634"
        y2="263.575"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.61" stopColor="#48A0F9" />
        <stop offset="0.896175" stopColor="#FFAA8F" />
      </linearGradient>
      <radialGradient
        id="paint29_radial_2308_38472"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(320.554 333.601) rotate(90) scale(134.781 111.54)"
      >
        <stop offset="0.0572917" stopColor="white" />
        <stop offset="1" stopColor="white" stopOpacity="0" />
      </radialGradient>
      <radialGradient
        id="paint30_radial_2308_38472"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(310.527 463.548) rotate(-88.1631) scale(123.286 282.6)"
      >
        <stop offset="0.525786" stopColor="white" />
        <stop offset="1" stopColor="white" stopOpacity="0" />
      </radialGradient>
      <linearGradient
        id="paint31_linear_2308_38472"
        x1="369.28"
        y1="260.945"
        x2="348.573"
        y2="323.731"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="white" />
        <stop offset="1" stopColor="white" stopOpacity="0" />
      </linearGradient>
      <linearGradient
        id="paint32_linear_2308_38472"
        x1="401.173"
        y1="476.814"
        x2="218.107"
        y2="261.986"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.61" stopColor="#48A0F9" />
        <stop offset="0.896175" stopColor="#FFAA8F" />
      </linearGradient>
      <radialGradient
        id="paint33_radial_2308_38472"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(316.597 334.598) rotate(90) scale(148.272 119.726)"
      >
        <stop offset="0.0572917" stopColor="white" />
        <stop offset="1" stopColor="white" stopOpacity="0" />
      </radialGradient>
      <radialGradient
        id="paint34_radial_2308_38472"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(305.835 477.551) rotate(-88.2076) scale(135.623 303.347)"
      >
        <stop offset="0.525786" stopColor="white" />
        <stop offset="1" stopColor="white" stopOpacity="0" />
      </radialGradient>
      <linearGradient
        id="paint35_linear_2308_38472"
        x1="368.9"
        y1="254.669"
        x2="345.668"
        y2="323.4"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="white" />
        <stop offset="1" stopColor="white" stopOpacity="0" />
      </linearGradient>
      <linearGradient
        id="paint36_linear_2308_38472"
        x1="402.483"
        y1="490.824"
        x2="201.554"
        y2="260.346"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.61" stopColor="#48A0F9" />
        <stop offset="0.896175" stopColor="#FFAA8F" />
      </linearGradient>
      <radialGradient
        id="paint37_radial_2308_38472"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(312.05 335.256) rotate(90) scale(162.193 128.017)"
      >
        <stop offset="0.0572917" stopColor="white" />
        <stop offset="1" stopColor="white" stopOpacity="0" />
      </radialGradient>
      <radialGradient
        id="paint38_radial_2308_38472"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(300.542 491.63) rotate(-88.248) scale(148.354 324.36)"
      >
        <stop offset="0.525786" stopColor="white" />
        <stop offset="1" stopColor="white" stopOpacity="0" />
      </radialGradient>
      <linearGradient
        id="paint39_linear_2308_38472"
        x1="367.974"
        y1="247.823"
        x2="342.099"
        y2="322.649"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="white" />
        <stop offset="1" stopColor="white" stopOpacity="0" />
      </linearGradient>
      <linearGradient
        id="paint40_linear_2308_38472"
        x1="403.493"
        y1="504.79"
        x2="184.353"
        y2="258.331"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.61" stopColor="#48A0F9" />
        <stop offset="0.896175" stopColor="#FFAA8F" />
      </linearGradient>
      <radialGradient
        id="paint41_radial_2308_38472"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(307.03 335.544) rotate(90) scale(176.453 136.552)"
      >
        <stop offset="0.0572917" stopColor="white" />
        <stop offset="1" stopColor="white" stopOpacity="0" />
      </radialGradient>
      <radialGradient
        id="paint42_radial_2308_38472"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(294.755 505.667) rotate(-88.2822) scale(161.393 345.992)"
      >
        <stop offset="0.525786" stopColor="white" />
        <stop offset="1" stopColor="white" stopOpacity="0" />
      </radialGradient>
      <linearGradient
        id="paint43_linear_2308_38472"
        x1="366.683"
        y1="240.424"
        x2="338.095"
        y2="321.481"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="white" />
        <stop offset="1" stopColor="white" stopOpacity="0" />
      </linearGradient>
      <linearGradient
        id="paint44_linear_2308_38472"
        x1="403.926"
        y1="518.503"
        x2="166.444"
        y2="255.986"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.61" stopColor="#48A0F9" />
        <stop offset="0.896175" stopColor="#FFAA8F" />
      </linearGradient>
      <radialGradient
        id="paint45_radial_2308_38472"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(301.372 335.434) rotate(90) scale(190.864 145.176)"
      >
        <stop offset="0.0572917" stopColor="white" />
        <stop offset="1" stopColor="white" stopOpacity="0" />
      </radialGradient>
      <radialGradient
        id="paint46_radial_2308_38472"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(288.321 519.451) rotate(-88.3116) scale(174.572 367.85)"
      >
        <stop offset="0.525786" stopColor="white" />
        <stop offset="1" stopColor="white" stopOpacity="0" />
      </radialGradient>
      <linearGradient
        id="paint47_linear_2308_38472"
        x1="364.792"
        y1="232.546"
        x2="333.452"
        y2="319.883"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="white" />
        <stop offset="1" stopColor="white" stopOpacity="0" />
      </linearGradient>
      <linearGradient
        id="paint48_linear_2308_38472"
        x1="403.901"
        y1="531.743"
        x2="148.012"
        y2="252.953"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.61" stopColor="#48A0F9" />
        <stop offset="0.896175" stopColor="#FFAA8F" />
      </linearGradient>
      <radialGradient
        id="paint49_radial_2308_38472"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(295.135 334.75) rotate(90) scale(205.381 153.969)"
      >
        <stop offset="0.0572917" stopColor="white" />
        <stop offset="1" stopColor="white" stopOpacity="0" />
      </radialGradient>
      <radialGradient
        id="paint50_radial_2308_38472"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(281.294 532.763) rotate(-88.3359) scale(187.848 390.134)"
      >
        <stop offset="0.525786" stopColor="white" />
        <stop offset="1" stopColor="white" stopOpacity="0" />
      </radialGradient>
      <linearGradient
        id="paint51_linear_2308_38472"
        x1="362.397"
        y1="224.036"
        x2="328.295"
        y2="317.701"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="white" />
        <stop offset="1" stopColor="white" stopOpacity="0" />
      </linearGradient>
      <linearGradient
        id="paint52_linear_2308_38472"
        x1="403.429"
        y1="544.273"
        x2="129.344"
        y2="248.987"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.61" stopColor="#48A0F9" />
        <stop offset="0.896175" stopColor="#FFAA8F" />
      </linearGradient>
      <radialGradient
        id="paint53_radial_2308_38472"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(288.331 333.461) rotate(90) scale(219.789 162.933)"
      >
        <stop offset="0.0572917" stopColor="white" />
        <stop offset="1" stopColor="white" stopOpacity="0" />
      </radialGradient>
      <radialGradient
        id="paint54_radial_2308_38472"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(273.685 545.365) rotate(-88.3544) scale(201.024 412.851)"
      >
        <stop offset="0.525786" stopColor="white" />
        <stop offset="1" stopColor="white" stopOpacity="0" />
      </radialGradient>
      <linearGradient
        id="paint55_linear_2308_38472"
        x1="359.509"
        y1="214.98"
        x2="322.701"
        y2="314.95"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="white" />
        <stop offset="1" stopColor="white" stopOpacity="0" />
      </linearGradient>
      <linearGradient
        id="paint56_linear_2308_38472"
        x1="402.382"
        y1="555.847"
        x2="110.515"
        y2="243.994"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.61" stopColor="#48A0F9" />
        <stop offset="0.896175" stopColor="#FFAA8F" />
      </linearGradient>
      <radialGradient
        id="paint57_radial_2308_38472"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(280.896 331.488) rotate(90) scale(233.912 171.974)"
      >
        <stop offset="0.0572917" stopColor="white" />
        <stop offset="1" stopColor="white" stopOpacity="0" />
      </radialGradient>
      <radialGradient
        id="paint58_radial_2308_38472"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(265.437 557.009) rotate(-88.368) scale(213.94 435.765)"
      >
        <stop offset="0.525786" stopColor="white" />
        <stop offset="1" stopColor="white" stopOpacity="0" />
      </radialGradient>
      <linearGradient
        id="paint59_linear_2308_38472"
        x1="356.024"
        y1="205.394"
        x2="316.604"
        y2="311.577"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="white" />
        <stop offset="1" stopColor="white" stopOpacity="0" />
      </linearGradient>
      <linearGradient
        id="paint60_linear_2308_38472"
        x1="404.345"
        y1="566.168"
        x2="95.6281"
        y2="234.151"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.61" stopColor="#48A0F9" />
        <stop offset="0.896175" stopColor="#FFAA8F" />
      </linearGradient>
      <radialGradient
        id="paint61_radial_2308_38472"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(274.946 328.749) rotate(90) scale(247.528 183.177)"
      >
        <stop offset="0.0572917" stopColor="white" />
        <stop offset="1" stopColor="white" stopOpacity="0" />
      </radialGradient>
      <radialGradient
        id="paint62_radial_2308_38472"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(258.48 567.398) rotate(-88.3573) scale(226.395 464.148)"
      >
        <stop offset="0.525786" stopColor="white" />
        <stop offset="1" stopColor="white" stopOpacity="0" />
      </radialGradient>
      <linearGradient
        id="paint63_linear_2308_38472"
        x1="354.968"
        y1="195.315"
        x2="313.459"
        y2="307.856"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="white" />
        <stop offset="1" stopColor="white" stopOpacity="0" />
      </linearGradient>
      <linearGradient
        id="paint64_linear_2308_38472"
        x1="428.152"
        y1="574.818"
        x2="106.143"
        y2="201.656"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.61" stopColor="#48A0F9" />
        <stop offset="0.896175" stopColor="#FFAA8F" />
      </linearGradient>
      <radialGradient
        id="paint65_radial_2308_38472"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(281.522 325.142) rotate(90) scale(260.308 207.569)"
      >
        <stop offset="0.0572917" stopColor="white" />
        <stop offset="1" stopColor="white" stopOpacity="0" />
      </radialGradient>
      <radialGradient
        id="paint66_radial_2308_38472"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(262.863 576.111) rotate(-88.23) scale(238.099 525.92)"
      >
        <stop offset="0.525786" stopColor="white" />
        <stop offset="1" stopColor="white" stopOpacity="0" />
      </radialGradient>
      <linearGradient
        id="paint67_linear_2308_38472"
        x1="372.2"
        y1="184.818"
        x2="331.005"
        y2="305.169"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="white" />
        <stop offset="1" stopColor="white" stopOpacity="0" />
      </linearGradient>
      <linearGradient
        id="paint68_linear_2308_38472"
        x1="455.365"
        y1="581.701"
        x2="123.237"
        y2="166.477"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.61" stopColor="#48A0F9" />
        <stop offset="0.896175" stopColor="#FFAA8F" />
      </linearGradient>
      <radialGradient
        id="paint69_radial_2308_38472"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(289.924 320.575) rotate(90) scale(272.245 234.198)"
      >
        <stop offset="0.0572917" stopColor="white" />
        <stop offset="1" stopColor="white" stopOpacity="0" />
      </radialGradient>
      <radialGradient
        id="paint70_radial_2308_38472"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(268.872 583.053) rotate(-88.0906) scale(249.037 593.343)"
      >
        <stop offset="0.525786" stopColor="white" />
        <stop offset="1" stopColor="white" stopOpacity="0" />
      </radialGradient>
      <linearGradient
        id="paint71_linear_2308_38472"
        x1="392.234"
        y1="173.816"
        x2="351.7"
        y2="301.572"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="white" />
        <stop offset="1" stopColor="white" stopOpacity="0" />
      </linearGradient>
      <linearGradient
        id="paint72_linear_2308_38472"
        x1="486.069"
        y1="586.318"
        x2="147.666"
        y2="129.142"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.61" stopColor="#48A0F9" />
        <stop offset="0.896175" stopColor="#FFAA8F" />
      </linearGradient>
      <radialGradient
        id="paint73_radial_2308_38472"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(300.26 314.922) rotate(90) scale(282.952 263.031)"
      >
        <stop offset="0.0572917" stopColor="white" />
        <stop offset="1" stopColor="white" stopOpacity="0" />
      </radialGradient>
      <radialGradient
        id="paint74_radial_2308_38472"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(276.616 587.724) rotate(-87.9368) scale(258.855 666.331)"
      >
        <stop offset="0.525786" stopColor="white" />
        <stop offset="1" stopColor="white" stopOpacity="0" />
      </radialGradient>
      <linearGradient
        id="paint75_linear_2308_38472"
        x1="415.166"
        y1="162.392"
        x2="375.661"
        y2="296.94"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="white" />
        <stop offset="1" stopColor="white" stopOpacity="0" />
      </linearGradient>
      <linearGradient
        id="paint76_linear_2308_38472"
        x1="520.601"
        y1="588.333"
        x2="180.244"
        y2="90.1296"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.61" stopColor="#48A0F9" />
        <stop offset="0.896175" stopColor="#FFAA8F" />
      </linearGradient>
      <radialGradient
        id="paint77_radial_2308_38472"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(312.719 308.093) rotate(90) scale(292.173 294.278)"
      >
        <stop offset="0.0572917" stopColor="white" />
        <stop offset="1" stopColor="white" stopOpacity="0" />
      </radialGradient>
      <radialGradient
        id="paint78_radial_2308_38472"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(286.266 589.785) rotate(-87.7647) scale(267.321 745.403)"
      >
        <stop offset="0.525786" stopColor="white" />
        <stop offset="1" stopColor="white" stopOpacity="0" />
      </radialGradient>
      <linearGradient
        id="paint79_linear_2308_38472"
        x1="441.275"
        y1="150.592"
        x2="403.178"
        y2="291.178"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="white" />
        <stop offset="1" stopColor="white" stopOpacity="0" />
      </linearGradient>
      <linearGradient
        id="paint80_linear_2308_38472"
        x1="558.853"
        y1="587.46"
        x2="220.9"
        y2="50.73"
        gradientUnits="userSpaceOnUse"
      >
        <stop offset="0.61" stopColor="#48A0F9" />
        <stop offset="0.896175" stopColor="#FFAA8F" />
      </linearGradient>
      <radialGradient
        id="paint81_radial_2308_38472"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(327.5 300.011) rotate(90) scale(299.689 327.504)"
      >
        <stop offset="0.0572917" stopColor="white" />
        <stop offset="1" stopColor="white" stopOpacity="0" />
      </radialGradient>
      <radialGradient
        id="paint82_radial_2308_38472"
        cx="0"
        cy="0"
        r="1"
        gradientUnits="userSpaceOnUse"
        gradientTransform="translate(298.06 588.949) rotate(-87.5749) scale(274.235 829.452)"
      >
        <stop offset="0.525786" stopColor="white" />
        <stop offset="1" stopColor="white" stopOpacity="0" />
      </radialGradient>
      <linearGradient
        id="paint83_linear_2308_38472"
        x1="470.571"
        y1="138.458"
        x2="434.18"
        y2="284.161"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="white" />
        <stop offset="1" stopColor="white" stopOpacity="0" />
      </linearGradient>
      <clipPath id="clip0_2308_38472">
        <rect
          width="655"
          height="599.943"
          fill="white"
          transform="translate(0 0.322266)"
        />
      </clipPath>
    </defs>
  </svg>
);

export default WeldCTABg;
