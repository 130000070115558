export const initialState = {
  blocks: {
    data: null,
    isLoading: false,
    isSuccess: false,
    error: null,
  },
  content: {
    data: null,
    isLoading: false,
    isSuccess: false,
    error: null,
  },
};
