// core
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
// store
import { getBlocks, getRoadmap } from "../../store/modules/blocks/services";
import { getGeneralData } from "../../store/modules/general-data/services";
import { getNews } from "../../store/modules/news/services";
import {
  selectBlocks,
  selectRoadmap,
} from "../../store/modules/blocks/selectors";
import { selectGeneralData } from "../../store/modules/general-data/selectors";
import { selectVariables } from "../../store/modules/variables/selectors";
import { selectNews } from "../../store/modules/news/selectors";

// utils

// components
import PageLoader from "../../components/atoms/PageLoader";
import HomeMain from "./HomeMain";
import HomeServices from "./HomeServices";
import HomeAbout from "./HomeAbout";
import HomeIcons from "./HomeIcons";
import HomeUsages from "./HomeUsages";
import HomeWelcome from "./HomeWelcome";
// import HomeSecurity from "./HomeSecurity";
import HomeContracts from "./HomeContracts";
import HomeFaqs from "./HomeFaqs";
import NewsSlider from "../../components/atoms/NewsSlider";
import HomeRoadmap from "./HomeRoadmap";

// styles
import "./index.scss";

import AOS from "aos";

const Home = () => {
  const { t } = useTranslation();
  // store
  const dispatch = useDispatch();
  const blocks = useSelector(selectBlocks);
  const generalData = useSelector(selectGeneralData);
  const variables = useSelector(selectVariables);
  const news = useSelector(selectNews);
  const roadmap = useSelector(selectRoadmap);

  const location = useLocation();

  useEffect(() => {
    dispatch(getBlocks());
    dispatch(getGeneralData());
    dispatch(getRoadmap());
    dispatch(getNews({ per_page: 10, page: 1 }));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (
      blocks.isSuccess &&
      generalData.isSuccess &&
      variables.isSuccess &&
      news.isSuccess &&
      roadmap.isSuccess
    ) {
      AOS.init({
        offset: 200,
        duration: 1000,
        easing: "ease-out-cubic",
        //     offset: 200,
        //     duration: 600,
        //     easing: "ease-out-cubic",
      });

      if (location.hash) {
        let anchor = location.hash.slice(1);
        let elem = document.getElementById(anchor);

        setTimeout(() => {
          if (elem) {
            window.scrollTo({ top: elem.offsetTop, behavior: "smooth" });
          }
        }, 300);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    blocks.isSuccess,
    generalData.isSuccess,
    variables.isSuccess,
    news.isSuccess,
    roadmap.isSuccess,
  ]);

  // @ts-ignore
  return (
    <div className="home">
      {blocks.isSuccess &&
      generalData.isSuccess &&
      variables.isSuccess &&
      roadmap.isSuccess &&
      news.isSuccess ? (
        <>
          <HomeMain data={blocks.data.main} />

          {news.data?.length > 0 && (
            <div className="home__slider">
              <div className="app__wrapper">
                <NewsSlider title={t("home.sliderTitle")} data={news.data} />
              </div>
            </div>
          )}

          <HomeServices
            data={blocks.data.service}
            generalData={generalData.data.services}
            variables={variables.data}
          />
          <HomeAbout
            data={blocks.data.about_token}
            variables={variables.data}
          />
          {roadmap.data?.length > 0 && <HomeRoadmap data={roadmap.data} />}
          <HomeIcons generalData={generalData.data.icons} />
          <HomeUsages generalData={generalData.data.usages} />
          {/*<HomeWorld variables={variables.data} />*/}
          <HomeWelcome variables={variables.data} />
          {/*<HomeSecurity*/}
          {/*  data={blocks.data.security}*/}
          {/*  variables={variables.data}*/}
          {/*/>*/}
          <HomeContracts generalData={generalData.data.contracts} />
          <HomeFaqs
            data={blocks.data.faq}
            generalData={generalData.data.faqs}
          />
        </>
      ) : (
        <PageLoader active={true} />
      )}
    </div>
  );
};

export default Home;
