// core
import React from "react";
import { useWindowSize } from "react-use";

// store

// components
import WeldCashbackImg from "../../../assets/images/weld-cashback.png";
import WeldCashbackMobImg from "../../../assets/images/weld-cashback-mob.png";

// styles
import "./index.scss";

const WeldCashback = ({ data }) => {
  // store
  const { width } = useWindowSize();

  return (
    <div className="weld-cashback">
      <div className="app__wrapper">
        <div className="weld-cashback__grid">
          <div className="weld-cashback__content">
            <div className="weld__title">{data.title}</div>
            {width > 1024 && (
              <div
                className="weld-cashback__text"
                dangerouslySetInnerHTML={{
                  __html: data.description,
                }}
              />
            )}
          </div>
          <div className="weld-cashback__globe">
            <img
              src={width > 425 ? WeldCashbackImg : WeldCashbackMobImg}
              className="weld-cashback__bg"
            />
            {(data.card_1_description || data.card_1_title) && (
              <div className="weld-cashback__card weld-cashback__card--1">
                {data.card_1_title && (
                  <div className="weld-cashback__card-text">
                    {data.card_1_title}
                  </div>
                )}
                {data.card_1_description && (
                  <div className="weld-cashback__card-title">
                    {data.card_1_description}
                  </div>
                )}
              </div>
            )}

            {(data.card_2_description || data.card_2_title) && (
              <div className="weld-cashback__card weld-cashback__card--2">
                {data.card_2_title && (
                  <div className="weld-cashback__card-text">
                    {data.card_2_title}
                  </div>
                )}
                {data.card_2_description && (
                  <div className="weld-cashback__card-title">
                    {data.card_2_description}
                  </div>
                )}
              </div>
            )}
          </div>
          {width <= 1024 && (
            <div
              className="weld-cashback__text"
              dangerouslySetInnerHTML={{
                __html: data.description,
              }}
            />
          )}
        </div>
      </div>
    </div>
  );
};

export default WeldCashback;
