// core
import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { FreeMode, Scrollbar, Mousewheel } from "swiper/modules";
// utils

// components

import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/scrollbar";

// styles
import "./index.scss";

const HomeRoadmap = ({ data }) => {
  return (
    <div className="home-roadmap">
      <div className="home-roadmap__bg" />
      <div className="app__wrapper">
        <div className="home-roadmap__content">
          <div className="home-roadmap__title">
            <svg
              width="32"
              height="32"
              viewBox="0 0 32 32"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <g clipPath="url(#clip0_1495_705)">
                <path
                  d="M12 30H4C3.46975 29.9994 2.9614 29.7885 2.58646 29.4135C2.21152 29.0386 2.00061 28.5302 2 28V24C2.00061 23.4698 2.21152 22.9614 2.58646 22.5865C2.9614 22.2115 3.46975 22.0006 4 22H12C12.5302 22.0006 13.0386 22.2115 13.4135 22.5865C13.7885 22.9614 13.9994 23.4698 14 24V28C13.9994 28.5302 13.7885 29.0386 13.4135 29.4135C13.0386 29.7885 12.5302 29.9994 12 30ZM4 24V28H12V24H4Z"
                  fill="#403F3F"
                />
                <path
                  d="M28 20H12C11.4698 19.9994 10.9614 19.7885 10.5865 19.4135C10.2115 19.0386 10.0006 18.5302 10 18V14C10.0006 13.4698 10.2115 12.9614 10.5865 12.5865C10.9614 12.2115 11.4698 12.0006 12 12H28C28.5302 12.0006 29.0386 12.2115 29.4135 12.5865C29.7885 12.9614 29.9994 13.4698 30 14V18C29.9994 18.5302 29.7885 19.0386 29.4135 19.4135C29.0386 19.7885 28.5302 19.9994 28 20ZM12 14V18H28V14H12Z"
                  fill="#403F3F"
                />
                <path
                  d="M16 10H4C3.46975 9.99939 2.9614 9.78848 2.58646 9.41354C2.21152 9.0386 2.00061 8.53025 2 8V4C2.00061 3.46975 2.21152 2.9614 2.58646 2.58646C2.9614 2.21152 3.46975 2.00061 4 2H16C16.5302 2.00061 17.0386 2.21152 17.4135 2.58646C17.7885 2.9614 17.9994 3.46975 18 4V8C17.9994 8.53025 17.7885 9.0386 17.4135 9.41354C17.0386 9.78848 16.5302 9.99939 16 10ZM4 4V8H16V4H4Z"
                  fill="#403F3F"
                />
              </g>
              <defs>
                <clipPath id="clip0_1495_705">
                  <rect width="32" height="32" fill="white" />
                </clipPath>
              </defs>
            </svg>
            Roadmap
            <span>Q4</span>
          </div>
          <div className="home-roadmap__slider">
            <Swiper
              slidesPerView={"auto"}
              spaceBetween={0}
              freeMode={true}
              scrollbar={{
                enabled: true,
                draggable: true,
              }}
              mousewheel={true}
              modules={[FreeMode, Scrollbar, Mousewheel]}
              className="mySwiper"
            >
              {data.map((item, index) => (
                <SwiperSlide key={item.id}>
                  <div className="home-roadmap__card">
                    <div className="home-roadmap__card-num">
                      {index > 9 ? index + 1 : `0${index + 1}`}
                    </div>
                    <div className="home-roadmap__card-info">
                      <div className="home-roadmap__card-date">{item.date}</div>
                      <div className="home-roadmap__card-title">
                        {item.title}
                      </div>
                    </div>
                  </div>
                </SwiperSlide>
              ))}
            </Swiper>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HomeRoadmap;
