// core
import React from "react";
import { useTranslation } from "react-i18next";

// utils

// components
import ProofCard from "../../../components/atoms/ProofCard";
// styles
import "./index.scss";

const ReservesOwnership = ({ data }) => {
  const { t } = useTranslation();

  return (
    <div className="reserves-ownership">
      <div className="app__wrapper">
        <div className="reserves-ownership__grid">
          <div className="reserves-ownership__content">
            <h2 className="reserves-ownership__title">{data.title}</h2>

            <div
              className="reserves-ownership__text"
              dangerouslySetInnerHTML={{
                __html: data.description,
              }}
            />
            {data?.proofs?.data.length > 0 && (
              <div className="reserves-ownership__proofs">
                <div className="reserves-ownership__proofs-title">
                  {t("reserves.proofTitle")}
                </div>
                <ul className="reserves-ownership__proofs-list">
                  {data.proofs.data.map((item, index) => (
                    <li key={index}>
                      <ProofCard
                        img={item.icon}
                        title={item.title}
                        type={"btn"}
                        link={item.url}
                      />
                    </li>
                  ))}
                </ul>
              </div>
            )}
          </div>
          <div
            className="reserves-ownership__img"
            data-aos="fade-up"
            data-aos-delay="900"
          >
            <img src={data.image} alt={data.title} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default ReservesOwnership;
