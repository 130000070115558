// core
import React from "react";

// store

// components

// styles
import "./index.scss";

const WeldAction = ({ data }) => {
  return (
    <div className="weld-action">
      <div className="app__wrapper">
        <div className="weld-action__grid">
          <div className="weld-action__content">
            <div className="weld__title weld__title--blue">{data.title}</div>
            <div
              className="weld-action__text"
              dangerouslySetInnerHTML={{
                __html: data.description,
              }}
            />
          </div>
          <div className="weld-action__img">
            <img src={data.image} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default WeldAction;
