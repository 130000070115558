// core
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation, Pagination } from "swiper/modules";
import PhotoSwipeLightbox from "photoswipe/lightbox";

// utils

// components
import AppButton from "../../../components/atoms/Button";
import Audit from "../../../components/icons/Audit";

// styles
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "photoswipe/style.css";
import "./index.scss";

const ReservesAudit = ({ data }) => {
  const { t } = useTranslation();

  useEffect(() => {
    let lightbox = new PhotoSwipeLightbox({
      gallery: "#gallery",
      children: "a",
      pswpModule: () => import("photoswipe"),
      initialZoomLevel: "fit",
      secondaryZoomLevel: "fill",
      // showHideAnimationType: "none",
      // zoomAnimationDuration: false,
    });

    // lightbox.addFilter("itemData", (itemData, index) => {
    //   console.log("itemData", { itemData, index });
    //   // return {
    //   //   html: `<div class="reserves-audit__photoswipe"><img src=${itemData.src} alt="" /></div>`,
    //   // };
    //   return itemData;
    // });

    lightbox.init();

    return () => {
      lightbox.destroy();
      lightbox = null;
    };
  }, []);

  return (
    <div className="reserves-audit">
      <div className="app__wrapper">
        <div className="reserves-audit__grid">
          <div className="reserves-audit__content">
            <h2 className="reserves-audit__title">{data.title}</h2>

            <div
              className="reserves-audit__text"
              dangerouslySetInnerHTML={{
                __html: data.description,
              }}
            />

            {data.pdf && (
              <div className="reserves-audit__actions">
                <AppButton
                  className="app-button--fill app-button--big reserves-audit__btn"
                  href={data.pdf}
                  prepend={<Audit />}
                  target="_blank"
                >
                  {t("actions.downloadAudit")}
                </AppButton>
              </div>
            )}
          </div>
          {data.images?.data?.length > 0 && (
            <div
              className="reserves-audit__slider"
              data-aos="fade-up"
              data-aos-delay="900"
            >
              <Swiper
                id="gallery"
                modules={[Navigation, Pagination]}
                spaceBetween={16}
                slidesPerView={1}
                autoHeight={true}
                navigation={data.images.data.length > 1}
                loop={data.images.data.length > 1}
                pagination={{ clickable: data.images.data.length > 1 }}
              >
                {data.images.data.map((item) => (
                  <SwiperSlide key={item.image}>
                    <div className="reserves-audit__card">
                      <a
                        href={item.image}
                        key={"gallery-" + item.image}
                        target="_blank"
                        rel="noreferrer"
                        data-pswp-width="6000"
                        data-pswp-height="7000"
                      >
                        <img src={item.image} alt="" />
                      </a>
                    </div>
                  </SwiperSlide>
                ))}
              </Swiper>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default ReservesAudit;
