import { $apiClient } from "../../../utils/api-client";
import { createAsyncThunk } from "@reduxjs/toolkit";

export const getBlocks = createAsyncThunk(`blocks/getBlocks`, async () => {
  const url = "blocks";
  return await $apiClient.get(url);
});

export const getReserves = createAsyncThunk(`blocks/getReserves`, async () => {
  const url = "blocks/proof-of-reserve?include=proofs";
  return await $apiClient.get(url);
});

export const getRoadmap = createAsyncThunk(`blocks/getRoadmap`, async () => {
  const url = "roadmaps";
  return await $apiClient.get(url);
});
