const WeldRateArrowMob = (props) => (
  <svg
    width="31"
    height="69"
    viewBox="0 0 31 69"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M30.3536 65.3536C30.5488 65.1583 30.5488 64.8417 30.3536 64.6464L27.1716 61.4645C26.9763 61.2692 26.6597 61.2692 26.4645 61.4645C26.2692 61.6597 26.2692 61.9763 26.4645 62.1716L29.2929 65L26.4645 67.8284C26.2692 68.0237 26.2692 68.3403 26.4645 68.5355C26.6597 68.7308 26.9763 68.7308 27.1716 68.5355L30.3536 65.3536ZM0.5 3.74668e-08L0.499998 57L1.5 57L1.5 8.11782e-08L0.5 3.74668e-08ZM9 65.5L30 65.5L30 64.5L9 64.5L9 65.5ZM0.499998 57C0.499997 61.6944 4.30558 65.5 9 65.5L9 64.5C4.85786 64.5 1.5 61.1421 1.5 57L0.499998 57Z"
      fill="#E0E1E3"
    />
  </svg>
);

export default WeldRateArrowMob;
