// core
import React, { useEffect, useState, useRef } from "react";
import { useTranslation } from "react-i18next";
import { useWindowSize } from "react-use";
// utils

// components
import ArrowSmDown from "./../../../components/icons/ArrowSmDown";
import ChevronRight from "../../../components/icons/ChevronRight";
import AppButton from "../../../components/atoms/Button";
// styles
import "./index.scss";

const HomeIcons = ({ generalData }) => {
  const { t } = useTranslation();
  const { width } = useWindowSize();

  const [isExpanded, setExpand] = useState(false);
  const [isOverflow, setOverflow] = useState(false);
  const expanderRef = useRef(null);

  useEffect(() => {
    if (generalData) setOverflow(isOverflowContent(expanderRef));
  }, [generalData]);

  const isOverflowContent = (elem) => {
    let options = elem.current;
    let breakPoint = width > 768 ? 112 : 112;
    return options && options.clientHeight >= breakPoint;
  };

  const gotoAnchor = (anchor) => {
    let elem = document.getElementById(anchor);

    setTimeout(() => {
      if (elem) {
        window.scrollTo({ top: elem.offsetTop, behavior: "smooth" });
      }
    }, 0);
  };

  return (
    <div className="home-icons">
      <div className="app__wrapper">
        <ul className="home-icons__list">
          {generalData.map((item, index) => (
            <li key={item.id} className="home-icons__list-item">
              <div className="home-icons__item-icon">
                <img src={item.icon} alt={item.title} />
              </div>
              <div className="home-icons__item-title">{item.title}</div>
              <div
                className="home-icons__item-expander"
                ref={index === 2 ? expanderRef : null}
              >
                <div
                  className={
                    "home-icons__item-expander-content" +
                    (isExpanded || !isOverflow
                      ? " home-icons__item-expander-content--active"
                      : "")
                  }
                >
                  <div
                    className="home-icons__item-text"
                    dangerouslySetInnerHTML={{
                      __html: item.description,
                    }}
                  />
                </div>
                {isOverflow && index === 2 && (
                  <a
                    className="home-icons__item-btn"
                    onClick={() => {
                      setExpand(!isExpanded);
                    }}
                  >
                    {isExpanded ? t("actions.readLess") : t("actions.readMore")}
                    <ChevronRight />
                  </a>
                )}
              </div>

              {item.type === "anchor" && (
                <a
                  onClick={() => gotoAnchor("contracts")}
                  className="home-icons__item-link"
                  title={t("actions.toSmartContract")}
                >
                  {t("actions.toSmartContract")}
                  <ArrowSmDown />
                </a>
              )}
              {item.type === "popup" && (
                <>
                  <br />
                  <AppButton className="app-button--fill" to={"/reserves"}>
                    {t("actions.onReserves")}
                  </AppButton>
                </>
              )}
            </li>
          ))}
        </ul>
      </div>
    </div>
  );
};

export default HomeIcons;
