// core
import React from "react";
import { useTranslation } from "react-i18next";

// components
import AppButton from "../Button";
import ChevronRight from "../../icons/ChevronRight";
// styles
import "./index.scss";

const ProofCard = ({ type, img, title, link, linkTitle, className }) => {
  const { t } = useTranslation();
  return (
    <div className={"proof-card " + (className ? className : "")}>
      <div className="proof-card__content">
        <img src={img} alt={title} />
        <span>{title}</span>
      </div>
      {type && (
        <>
          {type === "link" ? (
            <div className="proof-card__link">
              <a href={link} title={linkTitle} target="_blank">
                {linkTitle}
              </a>
            </div>
          ) : (
            <AppButton
              className="app-button--fill proof-card__btn"
              href={link}
              target="_blank"
              append={<ChevronRight />}
            >
              {t("actions.go")}
            </AppButton>
          )}
        </>
      )}
    </div>
  );
};

export default ProofCard;
