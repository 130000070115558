import { createSlice } from "@reduxjs/toolkit";
import { initialState } from "./state";
import * as services from "./services";

// Creating slice with reducers
const slice = createSlice({
  name: "weld",
  initialState,
  extraReducers: {
    [services.getWeldBlocks.pending]: (state) => {
      state.blocks.isLoading = true;
      state.blocks.isSuccess = false;
      state.blocks.error = null;
    },
    [services.getWeldBlocks.rejected]: (state, action) => {
      state.blocks.isLoading = false;
      state.blocks.error = action.payload;
    },
    [services.getWeldBlocks.fulfilled]: (state, action) => {
      state.blocks.isLoading = false;
      state.blocks.isSuccess = true;
      state.blocks.data = action?.payload?.data?.data;
    },

    [services.getWeldContent.pending]: (state) => {
      state.content.isLoading = true;
      state.content.isSuccess = false;
      state.content.error = null;
    },
    [services.getWeldContent.rejected]: (state, action) => {
      state.content.isLoading = false;
      state.content.error = action.payload;
    },
    [services.getWeldContent.fulfilled]: (state, action) => {
      state.content.isLoading = false;
      state.content.isSuccess = true;
      state.content.data = action?.payload?.data?.data;
    },
  },
});

export default slice.reducer;
