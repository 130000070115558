// core
import React from "react";
import { useTranslation } from "react-i18next";

// components
import AppButton from "../Button";
import ChevronRight from "../../icons/ChevronRight";
// styles
import "./index.scss";
import { numberFormat } from "../../../utils/common";

const ProofExtendedCard = ({
  type,
  isDark,
  img,
  title,
  link,
  linkTitle,
  amount,
  className,
  isShowNote,
}) => {
  const { t } = useTranslation();
  return (
    <div
      className={
        "proof-extended-card " +
        (className ? className : "") +
        (isDark ? " proof-extended-card--dark" : "")
      }
    >
      <div className="proof-extended-card__head">
        <img src={img} alt={title} />
        <span>{title}</span>
        {amount && amount > 0 && (
          <span>
            <strong>
              {numberFormat(amount)} {isShowNote && "UAHg"}
            </strong>
          </span>
        )}
      </div>
      <div className="proof-extended-card__body">
        <div className="proof-extended-card__label">{t("label.address")}</div>
        {type && (
          <>
            {type === "link" ? (
              <a
                href={link}
                title={linkTitle}
                target="_blank"
                className="proof-extended-card__link"
              >
                {linkTitle}
              </a>
            ) : (
              <AppButton
                className="app-button--fill proof-extended-card__btn"
                href={link}
                target="_blank"
                append={<ChevronRight />}
              >
                {t("actions.go")}
              </AppButton>
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default ProofExtendedCard;
