const WeldBannerArrowDownMob = (props) => (
  <svg
    width="87"
    height="48"
    viewBox="0 0 87 48"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M83.3358 47.0318C83.5804 47.1598 83.8826 47.0653 84.0106 46.8206L86.0969 42.8334C86.2249 42.5888 86.1303 42.2866 85.8857 42.1586C85.641 42.0306 85.3389 42.1252 85.2108 42.3698L83.3564 45.914L79.8122 44.0595C79.5676 43.9314 79.2654 44.026 79.1374 44.2707C79.0094 44.5153 79.1039 44.8175 79.3486 44.9455L83.3358 47.0318ZM1.06533 2.21101C34 -2.12935 72.6756 13.4616 83.0904 46.7381L84.0447 46.4394C73.433 12.5337 34.1566 -3.15862 0.934672 1.21958L1.06533 2.21101Z"
      fill="#0790F1"
    />
  </svg>
);

export default WeldBannerArrowDownMob;
