// core
import React from "react";
import { useTranslation } from "react-i18next";
import { useWindowSize } from "react-use";
// store

// components
import AppButton from "../../../components/atoms/Button";
import WeldCTABg from "../../../components/icons/WeldCTABg";

// styles
import "./index.scss";

const WeldCTA = ({ data }) => {
  // store

  const { t } = useTranslation();
  const { width } = useWindowSize();

  return (
    <div className="weld-cta">
      <div className="app__wrapper">
        <div className="weld-cta__grid">
          <div className="weld-cta__content">
            <div className="weld__title weld__title--blue">{data.title}</div>
            {width > 768 && (
              <div className="weld-cta__actions">
                <AppButton
                  className="app-button--fill-3"
                  href={data.url}
                  target="_blank"
                >
                  {t("actions.installApp")}
                </AppButton>
              </div>
            )}
          </div>
          <div className="weld-cta__img">
            <WeldCTABg />
            <img src={data.image} alt="Rocket" />
          </div>
          {width <= 768 && (
            <div className="weld-cta__actions">
              <AppButton
                className="app-button--fill-3 weld__btn"
                href={data.url}
                target="_blank"
              >
                {t("actions.installApp")}
              </AppButton>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default WeldCTA;
