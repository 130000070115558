const WeldBannerArrowUpMob = (props) => (
  <svg
    width="92"
    height="35"
    viewBox="0 0 92 35"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M90.7696 26.9071C91.0331 26.9896 91.1799 27.2701 91.0974 27.5336L89.7533 31.8282C89.6708 32.0917 89.3903 32.2385 89.1268 32.156C88.8632 32.0735 88.7165 31.793 88.7989 31.5295L89.9937 27.7121L86.1763 26.5173C85.9128 26.4349 85.766 26.1544 85.8485 25.8908C85.9309 25.6273 86.2114 25.4805 86.475 25.563L90.7696 26.9071ZM0.902509 0.426208C21.1218 26.7837 59.4939 43.107 90.3884 26.9413L90.852 27.8273C59.3734 44.2986 20.5046 27.6222 0.109075 1.03486L0.902509 0.426208Z"
      fill="#0790F1"
    />
  </svg>
);

export default WeldBannerArrowUpMob;
