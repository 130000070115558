// core
import { useTranslation } from "react-i18next";

// utils

// components
import ArrowUpRight from "../../../components/icons/ArrowUpRight";

// styles
import "./index.scss";

const HomeServices = ({ generalData, variables }) => {
  const { t } = useTranslation();

  console.log("generalData", generalData);

  return (
    <div className="home-services">
      <div className="home-services__head">
        <div className="app__wrapper">
          <div className="home-services__head-inner">
            <div className="home-services__head-text">
              {variables.services_title.value}
            </div>
            <div className="home-services__head-area">
              <div className="home-services__head-grid">
                {generalData[0] && (
                  <div className="home-services__head-box home-services__head-box--1">
                    <div className="home-services__head-box-logo">
                      <img src={generalData[0].image} alt={""} />
                    </div>
                    <a
                      href={generalData[0].url}
                      className="home-services__head-box-link"
                      title={"Перейти"}
                      target="_blank"
                    >
                      {t("actions.go")} <ArrowUpRight />
                    </a>
                  </div>
                )}
                {generalData[1] && (
                  <div className="home-services__head-box home-services__head-box--2">
                    <div className="home-services__head-box-logo">
                      <img src={generalData[1].image} alt={""} />
                    </div>
                    <a
                      href={generalData[1].url}
                      className="home-services__head-box-link"
                      title={t("actions.go")}
                      target="_blank"
                    >
                      {t("actions.go")} <ArrowUpRight />
                    </a>
                  </div>
                )}
              </div>
              <div className="home-services__head-grid">
                {generalData[2] && (
                  <div className="home-services__head-box home-services__head-box--3">
                    <div className="home-services__head-box-logo">
                      <img src={generalData[2].image} alt={""} />
                    </div>
                    <a
                      href={generalData[2].url}
                      className="home-services__head-box-link"
                      title={t("actions.go")}
                      target="_blank"
                    >
                      {t("actions.go")} <ArrowUpRight />
                    </a>
                  </div>
                )}
                {generalData[3] && (
                  <div className="home-services__head-box home-services__head-box--4">
                    <div className="home-services__head-box-logo">
                      <img src={generalData[3].image} alt={""} />
                    </div>
                    <a
                      href={generalData[3].url}
                      className="home-services__head-box-link"
                      title={t("actions.go")}
                      target="_blank"
                    >
                      {t("actions.go")} <ArrowUpRight />
                    </a>
                  </div>
                )}
              </div>
              <div className="home-services__head-shadow home-services__head-shadow--1" />
              <div className="home-services__head-shadow home-services__head-shadow--2" />
              <div className="home-services__head-shadow home-services__head-shadow--3" />
              <div className="home-services__head-shadow home-services__head-shadow--4" />
              <div className="home-services__head-shadow home-services__head-shadow--5" />
            </div>
          </div>
        </div>
      </div>
      {/*<div className="home-services__footer">*/}
      {/*  <div className="app__wrapper">*/}
      {/*    <div className="home-services__footer-inner">*/}
      {/*      <svg*/}
      {/*        className="home-services__footer-polygon-1"*/}
      {/*        width="303"*/}
      {/*        height="292"*/}
      {/*        viewBox="0 0 303 292"*/}
      {/*        fill="none"*/}
      {/*        xmlns="http://www.w3.org/2000/svg"*/}
      {/*      >*/}
      {/*        <g opacity="0.5">*/}
      {/*          <path*/}
      {/*            fillRule="evenodd"*/}
      {/*            clipRule="evenodd"*/}
      {/*            d="M298.871 172.212L299.519 126.431C299.543 124.754 298.67 123.191 297.229 122.332L257.905 98.8826C256.465 98.0236 254.675 97.9984 253.211 98.8164L213.239 121.147C211.774 121.965 210.858 123.503 210.834 125.18L210.186 170.96C210.162 172.637 211.035 174.2 212.476 175.059L251.8 198.509C253.241 199.368 255.03 199.393 256.494 198.575L296.467 176.244C297.931 175.426 298.848 173.889 298.871 172.212ZM302.336 126.471C302.374 123.787 300.977 121.288 298.672 119.913L259.348 96.4633C257.043 95.0888 254.18 95.0484 251.837 96.3573L211.865 118.688C209.522 119.997 208.055 122.457 208.017 125.14L207.369 170.921C207.332 173.604 208.728 176.104 211.033 177.478L250.357 200.928C252.662 202.302 255.525 202.343 257.868 201.034L297.84 178.703C300.183 177.394 301.65 174.935 301.688 172.251L302.336 126.471Z"*/}
      {/*            fill="url(#paint0_radial_383_4196)"*/}
      {/*          />*/}
      {/*          <path*/}
      {/*            fillRule="evenodd"*/}
      {/*            clipRule="evenodd"*/}
      {/*            d="M143.942 258.765L144.59 212.984C144.613 211.307 143.741 209.745 142.3 208.886L102.976 185.436C101.535 184.577 99.7456 184.552 98.2813 185.37L58.3091 207.701C56.8448 208.519 55.9281 210.056 55.9044 211.733L55.2566 257.514C55.2329 259.191 56.1057 260.753 57.5463 261.612L96.8706 285.062C98.3112 285.921 100.101 285.946 101.565 285.128L141.537 262.797C143.001 261.979 143.918 260.442 143.942 258.765ZM147.406 213.024C147.444 210.341 146.048 207.841 143.743 206.467L104.418 183.017C102.114 181.642 99.2504 181.602 96.9075 182.911L56.9353 205.242C54.5924 206.551 53.1257 209.01 53.0878 211.693L52.44 257.474C52.402 260.157 53.7985 262.657 56.1034 264.032L95.4278 287.481C97.7327 288.856 100.596 288.896 102.939 287.587L142.911 265.256C145.254 263.947 146.721 261.488 146.758 258.805L147.406 213.024Z"*/}
      {/*            fill="url(#paint1_radial_383_4196)"*/}
      {/*          />*/}
      {/*          <path*/}
      {/*            fillRule="evenodd"*/}
      {/*            clipRule="evenodd"*/}
      {/*            d="M246.641 261.62L247.289 215.839C247.312 214.162 246.44 212.6 244.999 211.741L205.675 188.291C204.234 187.432 202.445 187.407 200.98 188.225L161.008 210.556C159.544 211.374 158.627 212.911 158.604 214.588L157.956 260.369C157.932 262.046 158.805 263.608 160.245 264.467L199.57 287.917C201.01 288.776 202.8 288.801 204.264 287.983L244.236 265.652C245.701 264.834 246.617 263.297 246.641 261.62ZM250.105 215.879C250.143 213.196 248.747 210.696 246.442 209.322L207.118 185.872C204.813 184.497 201.949 184.457 199.607 185.766L159.634 208.097C157.292 209.406 155.825 211.865 155.787 214.548L155.139 260.329C155.101 263.013 156.498 265.512 158.803 266.887L198.127 290.336C200.432 291.711 203.295 291.751 205.638 290.442L245.61 268.111C247.953 266.803 249.42 264.343 249.458 261.66L250.105 215.879Z"*/}
      {/*            fill="url(#paint2_radial_383_4196)"*/}
      {/*          />*/}
      {/*          <path*/}
      {/*            fillRule="evenodd"*/}
      {/*            clipRule="evenodd"*/}
      {/*            d="M143.606 77.1891L144.254 31.4084C144.278 29.7313 143.405 28.1689 141.964 27.3099L102.64 3.86016C101.199 3.00112 99.4098 2.97587 97.9455 3.79393L57.9733 26.1249C56.509 26.943 55.5924 28.48 55.5686 30.1571L54.9208 75.9379C54.8971 77.615 55.7699 79.1773 57.2105 80.0363L96.5348 103.486C97.9754 104.345 99.7649 104.37 101.229 103.552L141.201 81.2213C142.666 80.4033 143.582 78.8662 143.606 77.1891ZM147.07 31.4481C147.108 28.7647 145.712 26.265 143.407 24.8905L104.083 1.44082C101.778 0.0663496 98.9146 0.0259523 96.5717 1.33484L56.5995 23.6658C54.2567 24.9747 52.79 27.434 52.752 30.1174L52.1042 75.8981C52.0662 78.5815 53.4627 81.0812 55.7677 82.4557L95.092 105.905C97.3969 107.28 100.26 107.32 102.603 106.011L142.575 83.6804C144.918 82.3715 146.385 79.9122 146.423 77.2288L147.07 31.4481Z"*/}
      {/*            fill="url(#paint3_radial_383_4196)"*/}
      {/*          />*/}
      {/*          <path*/}
      {/*            fillRule="evenodd"*/}
      {/*            clipRule="evenodd"*/}
      {/*            d="M195.306 169.751L195.788 123.598C195.805 121.933 194.939 120.383 193.513 119.525L153.958 95.7388C152.515 94.8713 150.718 94.8426 149.248 95.6637L109.309 117.977C107.839 118.798 106.921 120.343 106.904 122.026L106.422 168.18C106.404 169.845 107.27 171.394 108.697 172.252L148.252 196.039C149.694 196.906 151.491 196.935 152.961 196.114L192.901 173.801C194.371 172.98 195.288 171.435 195.306 169.751ZM198.604 123.627C198.632 120.963 197.247 118.484 194.964 117.111L155.409 93.3249C153.101 91.9367 150.226 91.8908 147.875 93.2046L107.935 115.517C105.583 116.831 104.115 119.303 104.087 121.997L103.605 168.151C103.577 170.814 104.962 173.293 107.245 174.666L146.8 198.453C149.108 199.841 151.983 199.887 154.335 198.573L194.275 176.26C196.626 174.946 198.094 172.474 198.122 169.781L198.604 123.627Z"*/}
      {/*            fill="url(#paint4_radial_383_4196)"*/}
      {/*          />*/}
      {/*          <path*/}
      {/*            fillRule="evenodd"*/}
      {/*            clipRule="evenodd"*/}
      {/*            d="M247.125 79.5862L247.773 33.8055C247.796 32.1284 246.924 30.5661 245.483 29.707L206.159 6.25728C204.718 5.39824 202.929 5.37299 201.464 6.19105L161.492 28.522C160.028 29.3401 159.111 30.8772 159.087 32.5543L158.44 78.335C158.416 80.0121 159.289 81.5744 160.729 82.4334L200.054 105.883C201.494 106.742 203.284 106.767 204.748 105.949L244.72 83.6184C246.185 82.8004 247.101 81.2633 247.125 79.5862ZM250.589 33.8452C250.627 31.1619 249.231 28.6621 246.926 27.2877L207.602 3.83794C205.297 2.46347 202.433 2.42307 200.091 3.73197L160.118 26.0629C157.776 27.3718 156.309 29.8312 156.271 32.5145L155.623 78.2952C155.585 80.9786 156.982 83.4783 159.287 84.8528L198.611 108.303C200.916 109.677 203.779 109.717 206.122 108.409L246.094 86.0775C248.437 84.7686 249.904 82.3093 249.942 79.6259L250.589 33.8452Z"*/}
      {/*            fill="url(#paint5_radial_383_4196)"*/}
      {/*          />*/}
      {/*          <path*/}
      {/*            fillRule="evenodd"*/}
      {/*            clipRule="evenodd"*/}
      {/*            d="M92.1954 166.14L92.8432 120.359C92.8669 118.682 91.9941 117.12 90.5535 116.26L51.2292 92.8108C49.7886 91.9517 47.9991 91.9265 46.5348 92.7445L6.5627 115.076C5.09839 115.894 4.18171 117.431 4.15798 119.108L3.51018 164.888C3.48645 166.566 4.35926 168.128 5.79984 168.987L45.1242 192.437C46.5648 193.296 48.3543 193.321 49.8186 192.503L89.7907 170.172C91.255 169.354 92.1717 167.817 92.1954 166.14ZM95.6598 120.399C95.6978 117.715 94.3013 115.216 91.9964 113.841L52.672 90.3914C50.3671 89.0169 47.5039 88.9766 45.161 90.2854L5.1889 112.616C2.84601 113.925 1.37931 116.385 1.34134 119.068L0.693546 164.849C0.655576 167.532 2.05208 170.032 4.35701 171.406L43.6813 194.856C45.9863 196.23 48.8495 196.271 51.1924 194.962L91.1645 172.631C93.5074 171.322 94.9741 168.863 95.012 166.179L95.6598 120.399Z"*/}
      {/*            fill="url(#paint6_radial_383_4196)"*/}
      {/*          />*/}
      {/*        </g>*/}
      {/*        <defs>*/}
      {/*          <radialGradient*/}
      {/*            id="paint0_radial_383_4196"*/}
      {/*            cx="0"*/}
      {/*            cy="0"*/}
      {/*            r="1"*/}
      {/*            gradientUnits="userSpaceOnUse"*/}
      {/*            gradientTransform="translate(58.022 95.0455) rotate(36.4026) scale(220.419 342.428)"*/}
      {/*          >*/}
      {/*            <stop offset="0.445105" stopColor="#FDEAE2" />*/}
      {/*            <stop offset="0.726006" stopColor="#D7F6FB" />*/}
      {/*          </radialGradient>*/}
      {/*          <radialGradient*/}
      {/*            id="paint1_radial_383_4196"*/}
      {/*            cx="0"*/}
      {/*            cy="0"*/}
      {/*            r="1"*/}
      {/*            gradientUnits="userSpaceOnUse"*/}
      {/*            gradientTransform="translate(58.022 95.0455) rotate(36.4026) scale(220.419 342.428)"*/}
      {/*          >*/}
      {/*            <stop offset="0.445105" stopColor="#FDEAE2" />*/}
      {/*            <stop offset="0.726006" stopColor="#D7F6FB" />*/}
      {/*          </radialGradient>*/}
      {/*          <radialGradient*/}
      {/*            id="paint2_radial_383_4196"*/}
      {/*            cx="0"*/}
      {/*            cy="0"*/}
      {/*            r="1"*/}
      {/*            gradientUnits="userSpaceOnUse"*/}
      {/*            gradientTransform="translate(58.022 95.0455) rotate(36.4026) scale(220.419 342.428)"*/}
      {/*          >*/}
      {/*            <stop offset="0.445105" stopColor="#FDEAE2" />*/}
      {/*            <stop offset="0.726006" stopColor="#D7F6FB" />*/}
      {/*          </radialGradient>*/}
      {/*          <radialGradient*/}
      {/*            id="paint3_radial_383_4196"*/}
      {/*            cx="0"*/}
      {/*            cy="0"*/}
      {/*            r="1"*/}
      {/*            gradientUnits="userSpaceOnUse"*/}
      {/*            gradientTransform="translate(58.022 95.0455) rotate(36.4026) scale(220.419 342.428)"*/}
      {/*          >*/}
      {/*            <stop offset="0.445105" stopColor="#FDEAE2" />*/}
      {/*            <stop offset="0.726006" stopColor="#D7F6FB" />*/}
      {/*          </radialGradient>*/}
      {/*          <radialGradient*/}
      {/*            id="paint4_radial_383_4196"*/}
      {/*            cx="0"*/}
      {/*            cy="0"*/}
      {/*            r="1"*/}
      {/*            gradientUnits="userSpaceOnUse"*/}
      {/*            gradientTransform="translate(58.022 95.0455) rotate(36.4026) scale(220.419 342.428)"*/}
      {/*          >*/}
      {/*            <stop offset="0.445105" stopColor="#FDEAE2" />*/}
      {/*            <stop offset="0.726006" stopColor="#D7F6FB" />*/}
      {/*          </radialGradient>*/}
      {/*          <radialGradient*/}
      {/*            id="paint5_radial_383_4196"*/}
      {/*            cx="0"*/}
      {/*            cy="0"*/}
      {/*            r="1"*/}
      {/*            gradientUnits="userSpaceOnUse"*/}
      {/*            gradientTransform="translate(58.022 95.0455) rotate(36.4026) scale(220.419 342.428)"*/}
      {/*          >*/}
      {/*            <stop offset="0.445105" stopColor="#FDEAE2" />*/}
      {/*            <stop offset="0.726006" stopColor="#D7F6FB" />*/}
      {/*          </radialGradient>*/}
      {/*          <radialGradient*/}
      {/*            id="paint6_radial_383_4196"*/}
      {/*            cx="0"*/}
      {/*            cy="0"*/}
      {/*            r="1"*/}
      {/*            gradientUnits="userSpaceOnUse"*/}
      {/*            gradientTransform="translate(58.022 95.0455) rotate(36.4026) scale(220.419 342.428)"*/}
      {/*          >*/}
      {/*            <stop offset="0.445105" stopColor="#FDEAE2" />*/}
      {/*            <stop offset="0.726006" stopColor="#D7F6FB" />*/}
      {/*          </radialGradient>*/}
      {/*        </defs>*/}
      {/*      </svg>*/}

      {/*      <svg*/}
      {/*        className="home-services__footer-polygon-2"*/}
      {/*        width="340"*/}
      {/*        height="311"*/}
      {/*        viewBox="0 0 340 311"*/}
      {/*        fill="none"*/}
      {/*        xmlns="http://www.w3.org/2000/svg"*/}
      {/*      >*/}
      {/*        <path*/}
      {/*          d="M141.367 112.915C141.416 115.449 140.09 117.812 137.901 119.091L91.2993 146.327C89.1549 147.581 86.507 147.604 84.3411 146.388L36.5226 119.55C34.3567 118.334 32.9972 116.062 32.95 113.578L31.9245 59.611C31.8763 57.0763 33.2024 54.7137 35.3911 53.4345L81.9927 26.1983C84.137 24.945 86.785 24.9219 88.9509 26.1375L136.769 52.976C138.935 54.1917 140.295 56.464 140.342 58.9473L141.367 112.915Z"*/}
      {/*          stroke="url(#paint0_linear_383_3989)"*/}
      {/*          strokeWidth="2"*/}
      {/*        />*/}
      {/*        <path*/}
      {/*          d="M305.516 212.545C305.563 216.474 303.511 220.131 300.132 222.137L254.004 249.521C250.625 251.528 246.432 251.579 243.005 249.655L196.226 223.4C192.799 221.476 190.658 217.871 190.611 213.941L189.959 160.302C189.911 156.372 191.963 152.715 195.342 150.709L241.47 123.325C244.849 121.319 249.042 121.268 252.469 123.191L299.248 149.446C302.675 151.37 304.816 154.975 304.864 158.905L305.516 212.545Z"*/}
      {/*          stroke="#D7F6FB"*/}
      {/*          strokeWidth="2"*/}
      {/*        />*/}
      {/*        <path*/}
      {/*          d="M220.7 234.864C220.759 238.815 218.694 242.494 215.29 244.502L159.525 277.401C156.149 279.392 151.969 279.437 148.552 277.519L91.7056 245.614C88.288 243.695 86.1491 240.104 86.0907 236.185L85.1255 171.446C85.0666 167.494 87.1316 163.815 90.535 161.807L146.301 128.908C149.676 126.917 153.856 126.872 157.274 128.79L214.12 160.696C217.537 162.614 219.676 166.205 219.735 170.124L220.7 234.864Z"*/}
      {/*          stroke="#FCEFEB"*/}
      {/*          strokeWidth="2"*/}
      {/*        />*/}
      {/*        <defs>*/}
      {/*          <linearGradient*/}
      {/*            id="paint0_linear_383_3989"*/}
      {/*            x1="65.001"*/}
      {/*            y1="100"*/}
      {/*            x2="117.001"*/}
      {/*            y2="59.5002"*/}
      {/*            gradientUnits="userSpaceOnUse"*/}
      {/*          >*/}
      {/*            <stop offset="0.544793" stopColor="#FDEBE6" />*/}
      {/*            <stop offset="0.975336" stopColor="#FDEBE6" stopOpacity="0" />*/}
      {/*          </linearGradient>*/}
      {/*        </defs>*/}
      {/*      </svg>*/}
      {/*      <div*/}
      {/*        className="home-services__footer-text"*/}
      {/*        dangerouslySetInnerHTML={{*/}
      {/*          __html: data.description,*/}
      {/*        }}*/}
      {/*        data-aos="fade-up"*/}
      {/*      />*/}
      {/*      <div className="home-services__footer-img">*/}
      {/*        <img src={data.image} alt="" />*/}
      {/*      </div>*/}
      {/*    </div>*/}
      {/*  </div>*/}
      {/*</div>*/}
    </div>
  );
};

export default HomeServices;
