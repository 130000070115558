const Copy = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M8.4001 10.7996C8.4001 9.47413 9.47461 8.39961 10.8001 8.39961H18.0001C19.3256 8.39961 20.4001 9.47413 20.4001 10.7996V17.9996C20.4001 19.3251 19.3256 20.3996 18.0001 20.3996H10.8001C9.47461 20.3996 8.4001 19.3251 8.4001 17.9996V10.7996Z"
      fill="currentColor"
    />
    <path
      d="M6.0001 3.59961C4.67461 3.59961 3.6001 4.67413 3.6001 5.99961V13.1996C3.6001 14.5251 4.67461 15.5996 6.0001 15.5996L6.0001 5.99961H15.6001C15.6001 4.67413 14.5256 3.59961 13.2001 3.59961H6.0001Z"
      fill="currentColor"
    />
  </svg>
);

export default Copy;
