const WeldBannerArrowUp = (props) => (
  <svg
    width="131"
    height="34"
    viewBox="0 0 131 34"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M129.009 0.314944C129.283 0.280197 129.533 0.474108 129.568 0.748056L130.134 5.21229C130.169 5.48624 129.975 5.73649 129.701 5.77123C129.427 5.80598 129.177 5.61207 129.142 5.33812L128.639 1.36991L124.671 1.87321C124.397 1.90796 124.146 1.71405 124.112 1.4401C124.077 1.16615 124.271 0.915905 124.545 0.881158L129.009 0.314944ZM0.578857 18.7593C41.2518 40.2947 99.0465 38.743 128.677 0.50471L129.467 1.11723C99.4331 39.8766 41.0399 41.314 0.110924 19.643L0.578857 18.7593Z"
      fill="#0790F1"
    />
  </svg>
);

export default WeldBannerArrowUp;
