import { createSlice } from "@reduxjs/toolkit";
import { initialState } from "./state";
import * as services from "./services";

// Creating slice with reducers
const slice = createSlice({
  name: "ambassador",
  initialState,
  extraReducers: {
    [services.getAmbassador.pending]: (state) => {
      state.ambassador.isLoading = true;
      state.ambassador.isSuccess = false;
      state.ambassador.error = null;
    },
    [services.getAmbassador.rejected]: (state, action) => {
      state.ambassador.isLoading = false;
      state.ambassador.error = action.payload;
    },
    [services.getAmbassador.fulfilled]: (state, action) => {
      state.ambassador.isLoading = false;
      state.ambassador.isSuccess = true;
      state.ambassador.data = action?.payload?.data?.data;
    },

    [services.sendAmbassadorForm.pending]: (state) => {
      state.ambassadorForm.isLoading = true;
      state.ambassadorForm.isSuccess = false;
      state.ambassadorForm.error = null;
    },
    [services.sendAmbassadorForm.rejected]: (state, action) => {
      state.ambassadorForm.isLoading = false;
      state.ambassadorForm.error = action.payload;
    },
    [services.sendAmbassadorForm.fulfilled]: (state) => {
      state.ambassadorForm.isLoading = false;
      state.ambassadorForm.isSuccess = true;
    },
  },
});

export default slice.reducer;
