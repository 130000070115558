import { $apiClient } from "../../../utils/api-client";
import { createAsyncThunk } from "@reduxjs/toolkit";

export const getGeneralData = createAsyncThunk(
  `generalData/getGeneralData`,
  async () => {
    const url = "index";
    return await $apiClient.get(url);
  }
);
