import { createSelector } from "@reduxjs/toolkit";

import { initialState } from "./state";

const selectDomain = (state) => state.weld || initialState;

export const selectWeldBlocks = createSelector(
  [selectDomain],
  (weldState) => weldState.blocks
);
export const selectWeldContent = createSelector(
  [selectDomain],
  (weldState) => weldState.content
);
