import { $apiClient } from "../../../utils/api-client";
import { createAsyncThunk } from "@reduxjs/toolkit";

export const getWeldBlocks = createAsyncThunk(`blocks/getBlocks`, async () => {
  const url = "weld/blocks";
  return await $apiClient.get(url);
});

export const getWeldContent = createAsyncThunk(
  `blocks/getReserves`,
  async () => {
    const url = "weld?include=icons";
    return await $apiClient.get(url);
  }
);
