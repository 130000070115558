export const copyToClipboard = (data) => {
  var selected = false;
  var el = document.createElement("textarea");
  el.value = data;
  el.setAttribute("readonly", "");
  el.style.position = "absolute";
  el.style.left = "-9999px";
  document.body.appendChild(el);
  if (document.getSelection().rangeCount > 0) {
    selected = document.getSelection().getRangeAt(0);
  }
  el.select();
  document.execCommand("copy");
  document.body.removeChild(el);
  if (selected) {
    document.getSelection().removeAllRanges();
    document.getSelection().addRange(selected);
  }
};

export const numberFormat = (data) => {
  return new Intl.NumberFormat("ru", {
    style: "decimal",
    minimumFractionDigits: 0,
  }).format(data);
};
