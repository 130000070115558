import { $apiClient } from "../../../utils/api-client";
import { createAsyncThunk } from "@reduxjs/toolkit";
export const getAmbassador = createAsyncThunk(
  `ambassador/getAmbassador`,
  async () => {
    const url = "blocks/become-ambassador";
    return await $apiClient.get(url);
  }
);

export const sendAmbassadorForm = createAsyncThunk(
  `ambassador/sendAmbassadorForm`,
  async (payload) => {
    const url = "become-ambassador";
    return await $apiClient.post(url, payload);
  }
);
