export const initialState = {
  ambassador: {
    data: null,
    isLoading: false,
    isSuccess: false,
    error: null,
  },
  ambassadorForm: {
    isLoading: false,
    isSuccess: false,
    error: null,
  },
};
