import { createSlice } from "@reduxjs/toolkit";
import { initialState } from "./state";
import * as services from "./services";

// Creating slice with reducers
const slice = createSlice({
  name: "team",
  initialState,
  extraReducers: {
    [services.getTeam.pending]: (state) => {
      state.team.isLoading = true;
      state.team.isSuccess = false;
      state.team.error = null;
    },
    [services.getTeam.rejected]: (state, action) => {
      state.team.isLoading = false;
      state.team.error = action.payload;
    },
    [services.getTeam.fulfilled]: (state, action) => {
      state.team.isLoading = false;
      state.team.isSuccess = true;
      state.team.data = action?.payload?.data?.data;
    },
  },
});

export default slice.reducer;
